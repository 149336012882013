import { observable, action, reaction } from 'mobx'
import { AssetsToken } from '../tokenStore/type/AssetsToken'
import {
  BillCountryItem,
  CountryListItem,
  CurrencyCyptoItem,
  CurrencyItem,
  HistoryItem,
  OnrampListItem,
  OnrampToken,
  PriceRes,
  QuoteRes,
  QuoteSide,
  SupportCryptoItem,
  SupportPayWay
} from '@/api/ramp/type'

const loadSelectedToken = (): AssetsToken => {
  const str = localStorage.getItem('ramp_store_selected_token')
  return str ? JSON.parse(str) : ({} as any)
}

const saveSelectedToken = (token: AssetsToken): void => {
  const str = JSON.stringify(token)
  localStorage.setItem('ramp_store_selected_token', str)
}

const loadMoonpayToken = (): AssetsToken => {
  try {
    const str = localStorage.getItem('ramp_store_moonpay_token')
    return str ? JSON.parse(str) : ({} as any)
  } catch (error) {
    return {} as any
  }
}

const saveMoonpayToken = (token: AssetsToken): void => {
  const str = JSON.stringify(token)
  localStorage.setItem('ramp_store_moonpay_token', str)
}

export interface IRampStore {
  moonPaySupportCodes: string[]
  moonpayToken: AssetsToken | null
  selectedToken: AssetsToken
  selectedTab: QuoteSide
  isAlchemySupported: boolean
  supportCryptoList: OnrampToken[]
  buySelectedCurrency: CurrencyCyptoItem
  sellSelectedCurrency: CurrencyItem
  buyCurrencyList: CountryListItem[]
  sellCurrencyList: CurrencyItem[]
  selectPayWayCode: SupportPayWay
  buyAmount: string
  sellAmount: string
  alchemyUserAccountId: string
  buyQuote: PriceRes
  buyQuotes: PriceRes[]
  sellQuote: QuoteRes
  historyList: OnrampListItem[]
  billCountryList: BillCountryItem[]
  setMoonpayToken: (token: AssetsToken) => void
  setMoonPaySupportCodes: (codes: string[]) => void
  setSelectedToken: (token: AssetsToken) => void
  clearSelectedToken: () => void
  setSelectedTab: (tab: QuoteSide) => void
  clearSelectedTab: () => void
  setSupportCryptoList: (list: OnrampToken[]) => void
  clearSupportCryptoList: () => void
  setIsAlchemySupported: (isSupported: boolean) => void
  clearIsAlchemySupported: () => void
  setBuySelectedCurrency: (currency: CurrencyCyptoItem) => void
  clearBuySelectedCurrency: () => void
  setSellSelectedCurrency: (currency: CurrencyItem) => void
  clearSellSelectedCurrency: () => void
  setBuyCurrencyList: (currencies: CountryListItem[]) => void
  clearBuyCurrencyList: () => void
  setSellCurrencyList: (currencies: CurrencyItem[]) => void
  clearSellCurrencyList: () => void
  setSelectPayWayCode: (payWayCode: SupportPayWay) => void
  setAlchemyUserAccountId: (accountId: string) => void
  setBuyAmount: (amount: string) => void
  setSellAmount: (amount: string) => void
  setBuyQuote: (quotes: PriceRes[]) => void
  setSellQuote: (quote: QuoteRes) => void
  setHistoryList: (list: OnrampListItem[]) => void
  setBillCountryList: (list: BillCountryItem[]) => void
  clearAmount: () => void
}

const rampStore: IRampStore = observable({
  moonPaySupportCodes: [],
  moonpayToken: loadMoonpayToken(),
  selectedToken: loadSelectedToken(),
  selectedTab: QuoteSide.BUY,
  supportCryptoList: [] as OnrampToken[],
  isAlchemySupported: false,
  buySelectedCurrency: {} as CurrencyCyptoItem,
  sellSelectedCurrency: {} as CurrencyItem,
  buyCurrencyList: [] as CountryListItem[],
  sellCurrencyList: [] as CurrencyItem[],
  selectPayWayCode: '' as SupportPayWay,
  alchemyUserAccountId: '',
  buyAmount: '',
  sellAmount: '',
  buyQuote: {} as PriceRes,
  buyQuotes: [],
  sellQuote: {} as QuoteRes,
  historyList: [],
  billCountryList: [],
  setMoonPaySupportCodes: action((codes: string[]) => {
    rampStore.moonPaySupportCodes = codes
  }),
  setMoonpayToken: action((token: AssetsToken) => {
    rampStore.moonpayToken = token
    saveMoonpayToken(token)
  }),
  setHistoryList: action(function (list: OnrampListItem[]) {
    rampStore.historyList = list
  }),

  setSelectedToken: action(function (token: AssetsToken) {
    rampStore.selectedToken = token
    saveSelectedToken(token)
  }),

  clearSelectedToken: action(function () {
    rampStore.selectedToken = {} as AssetsToken
  }),

  setSelectedTab: action(function (tab: QuoteSide) {
    rampStore.selectedTab = tab
  }),

  clearSelectedTab: action(function () {
    rampStore.selectedTab = QuoteSide.BUY
  }),

  setSupportCryptoList: action(function (list: OnrampToken[]) {
    rampStore.supportCryptoList = list
  }),

  clearSupportCryptoList: action(function () {
    rampStore.supportCryptoList = []
  }),

  setIsAlchemySupported: action(function (isSupported: boolean) {
    rampStore.isAlchemySupported = isSupported
  }),

  clearIsAlchemySupported: action(function () {
    rampStore.isAlchemySupported = false
  }),

  setBuySelectedCurrency: action(function (currency: CurrencyCyptoItem) {
    rampStore.buySelectedCurrency = currency
  }),

  clearBuySelectedCurrency: action(function () {
    rampStore.buySelectedCurrency = {} as CurrencyCyptoItem
  }),

  setSellSelectedCurrency: action(function (currency: CurrencyItem) {
    rampStore.sellSelectedCurrency = currency
  }),

  clearSellSelectedCurrency: action(function () {
    rampStore.sellSelectedCurrency = {} as CurrencyItem
  }),

  setBuyCurrencyList: action(function (currencies: CountryListItem[]) {
    rampStore.buyCurrencyList = currencies
  }),

  clearBuyCurrencyList: action(function () {
    rampStore.buyCurrencyList = [] as CountryListItem[]
  }),

  setSellCurrencyList: action(function (currencies: CurrencyItem[]) {
    rampStore.sellCurrencyList = currencies
  }),

  clearSellCurrencyList: action(function () {
    rampStore.sellCurrencyList = [] as CurrencyItem[]
  }),
  setSelectPayWayCode: action(function (payWayCode: SupportPayWay) {
    rampStore.selectPayWayCode = payWayCode
  }),
  setAlchemyUserAccountId: action(function (accountId: string) {
    rampStore.alchemyUserAccountId = accountId
  }),
  setBuyAmount: action(function (amount: string) {
    rampStore.buyAmount = amount
  }),
  setSellAmount: action(function (amount: string) {
    rampStore.sellAmount = amount
  }),
  setBuyQuote: action(function (quotes: PriceRes[]) {
    rampStore.buyQuote = quotes[0]
    rampStore.buyQuotes = quotes
  }),
  setSellQuote: action(function (quote: QuoteRes) {
    rampStore.sellQuote = quote
  }),
  clearAmount: action(function () {
    rampStore.sellAmount = ''
    rampStore.buyAmount = ''
  }),
  setBillCountryList: action(function (list: BillCountryItem[]) {
    rampStore.billCountryList = list
  }),

  get hasSelectedToken() {
    return rampStore.selectedToken !== null
  }
})

export default rampStore
