import { TSkeleton as Skeleton } from '@/components/tmd'

const SkeletonAIFeed = () => {
  return (
    <div className="px-5">
      <Skeleton className="mt-6" style={{ '--height': '44px' }} />

      <Skeleton
        className="mt-3"
        style={{
          '--height': '18px',
          '--width': '128px'
        }}
      />
      <Skeleton className="mt-3" style={{ '--height': '48px' }} />
      <Skeleton className="mt-5" style={{ '--height': '303px' }} />
      <Skeleton className="mt-3" style={{ '--height': '32px' }} />
      <Skeleton className="mt-5" style={{ '--height': '303px' }} />
      <Skeleton className="mt-3" style={{ '--height': '32px' }} />
    </div>
  )
}

export default SkeletonAIFeed
