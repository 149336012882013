import { TSkeleton as Skeleton } from '@/components/tmd'

const SkeletonSwap = () => {
  return (
    <div className="px-5">
      <div className="mt-2 flex items-center justify-between">
        <Skeleton style={{ '--height': '45px', '--width': '97px' }} />
        <Skeleton style={{ '--height': '55px', '--width': '76px' }} />
      </div>
      <Skeleton className="mt-2" style={{ '--height': '250px' }} />

      <Skeleton className="mt-4" style={{ '--height': '173px' }} />
    </div>
  )
}

export default SkeletonSwap
