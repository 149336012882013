import { PublicKey, Ed25519Program, SystemProgram } from '@solana/web3.js'
import {
  getConnection,
  getConnection as getMainnetConnection,
  sendRawTransactionByStatus
} from 'config/sol'
import { AnchorProvider, Program } from '@coral-xyz/anchor'
import * as anchor from '@coral-xyz/anchor'
import { TomoGiftIDL } from './abis/GiftSolAbi'
import {
  ACCOUNT_SIZE,
  createCloseAccountInstruction,
  createInitializeAccount3Instruction,
  getAssociatedTokenAddress,
  getAssociatedTokenAddressSync,
  getMinimumBalanceForRentExemptAccount,
  NATIVE_MINT,
  TOKEN_PROGRAM_ID
} from '@solana/spl-token'
import { solSignRawTransaction } from 'api'
import { CreateGiftProps, RefundContractProps } from './type'
import { GIFT_CONTRACTS } from './config'
import BigNumber from 'bignumber.js'
import { TToast } from 'components/tmd'
import { GiftCreateTypeEnum } from 'api/gift/type'
import configChains from '@/proviers/web3Provider/chains'
import giftTradeSentry from './giftSentry'

type MFA2_Fn = (raw: string) => Promise<any>

// Config
// ------------------------------------------------------------------------------------------------
const CONFIGS = {
  associatedTokenProgram: new PublicKey(
    'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL'
  ),
  tokenProgram: TOKEN_PROGRAM_ID,
  systemProgram: new PublicKey('11111111111111111111111111111111'),
  rent: new PublicKey('SysvarRent111111111111111111111111111111111'),
  ixSysvar: new PublicKey('Sysvar1nstructions1111111111111111111111111'),
  feeAddr: new PublicKey('feeggNywo4VnXkb4KdZeo6do9G5dtgesfAVS6AeGrUk'),
  contract: new PublicKey(GIFT_CONTRACTS[configChains.solana.name])
}

const initGiftAccounts = async (
  _giftId: number,
  _account: string,
  _token: string
) => {
  const connection = getConnection()
  const account = new PublicKey(_account)
  const tokenMint = new PublicKey(_token || NATIVE_MINT)
  const giftId = new anchor.BN(_giftId)
  const provider = new AnchorProvider(
    connection,
    {
      signTransaction: () => Promise.reject(),
      signAllTransactions: () => Promise.reject(),
      publicKey: account
    },
    {
      preflightCommitment: 'processed'
    }
  )
  anchor.setProvider(provider)
  const program = new Program(TomoGiftIDL, CONFIGS.contract, provider)
  const [config] = PublicKey.findProgramAddressSync(
    [Buffer.from('config')],
    program.programId
  )
  const configData = await program.account.config.fetch(config)
  const [giftData] = PublicKey.findProgramAddressSync(
    [giftId.toBuffer('le', 16)],
    program.programId
  )

  const [vault] = PublicKey.findProgramAddressSync(
    [giftId.toBuffer('le', 16), Buffer.from('vault')],
    program.programId
  )

  const giftAta = getAssociatedTokenAddressSync(tokenMint, vault, true)

  return {
    program,
    giftId,
    connection,
    provider,
    config,
    giftData,
    vault,
    tokenMint,
    giftAta,
    configData,
    account
  }
}

const sendTransaction = async (
  transaction: anchor.web3.Transaction,
  getMFA2: MFA2_Fn,
  loadingText: string,
  fromAddress: string
) => {
  const unSignData = transaction
    .serialize({ requireAllSignatures: false, verifySignatures: false })
    .toString('hex')

  const mfa = await getMFA2(unSignData)

  if (!mfa) {
    throw new Error('need MFA2')
  }

  TToast.loading(loadingText)

  const hash = await sendRawTransactionByStatus({
    transaction: unSignData,
    fromAddress: fromAddress,
    type: 'Default'
  })
  // const signRes = await solSignRawTransaction({
  //   rawTransaction: unSignData ?? ''
  // })

  // const hash = await waitForTransactionConfirmationInSol({
  //   connection,
  //   sendRawTransaction: async () => {
  //     return await connection.sendRawTransaction(
  //       Buffer.from(signRes.result, 'hex')
  //     )
  //   }
  // })
  return hash
}

// Create action
// ------------------------------------------------------------------------------------------------
type CreateGiftInSolProps = {
  userSolAddress: string
} & CreateGiftProps

export const createGiftInSol = async (
  {
    giftId: _giftId,
    userSolAddress,
    message,
    signature,
    amount,
    totalAmount,
    count,
    timeout,
    type,
    isNative,
    token,
    shareID,
    perFee
  }: CreateGiftInSolProps,
  getMFA2: MFA2_Fn
) => {
  try {
    const {
      program,
      giftId,
      connection,
      config,
      giftData,
      vault,
      tokenMint,
      giftAta,
      account,
      configData
    } = await initGiftAccounts(_giftId, userSolAddress, token)

    const params = {
      amount: new anchor.BN(amount),
      count: new anchor.BN(count),
      timeout: new anchor.BN(timeout),
      perFee: new anchor.BN(perFee)
    }

    const ed25519_ix = Ed25519Program.createInstructionWithPublicKey({
      publicKey: Uint8Array.from(configData.signer),
      message: Uint8Array.from(Buffer.from(message, 'hex')),
      signature: Uint8Array.from(Buffer.from(signature, 'hex'))
    })

    const seedStr = `${Date.now()}_${timeout}`
    let userAta: PublicKey
    if (isNative) {
      userAta = await PublicKey.createWithSeed(
        account,
        seedStr,
        CONFIGS.tokenProgram
      )
    } else {
      userAta = await getAssociatedTokenAddress(tokenMint, account)
    }

    const getWrapperSol = async () => {
      const lamports = await getMinimumBalanceForRentExemptAccount(
        connection,
        'confirmed'
      )
      const ix0 = SystemProgram.createAccountWithSeed({
        fromPubkey: account,
        basePubkey: account,
        newAccountPubkey: userAta,
        seed: seedStr,
        lamports: lamports + Number(totalAmount),
        space: ACCOUNT_SIZE,
        programId: CONFIGS.tokenProgram
      })

      const ix1 = createInitializeAccount3Instruction(
        userAta,
        tokenMint,
        account
      )
      const unwrap_wsol_ix = createCloseAccountInstruction(
        userAta,
        account,
        account,
        [account]
      )
      return [ix0, ix1, unwrap_wsol_ix]
    }
    const builder = program.methods
      .sendWithFee({
        id: giftId,
        typ: type,
        amount: params.amount,
        count: params.count,
        timeout: params.timeout,
        signature: Buffer.from(signature, 'hex'),
        isSol: isNative,
        fee: params.perFee
      })
      .accounts({
        config,
        giftData,
        vault,
        tokenMint,
        giftAta,
        userAta,
        feeAddr: configData.feeAddr,
        payer: account,
        associatedTokenProgram: CONFIGS.associatedTokenProgram,
        tokenProgram: CONFIGS.tokenProgram,
        systemProgram: CONFIGS.systemProgram,
        rent: CONFIGS.rent,
        ixSysvar: CONFIGS.ixSysvar
      })

    if (isNative) {
      const [ix0, ix1, unwrap_wsol_ix] = await getWrapperSol()
      builder
        .preInstructions([ix0, ix1, ed25519_ix])
        .postInstructions([unwrap_wsol_ix])
    } else {
      builder.preInstructions([ed25519_ix])
    }
    const transaction = await builder.transaction()
    transaction.feePayer = account

    transaction.recentBlockhash = (
      await connection.getLatestBlockhash()
    ).blockhash

    /**
     *  1 mfa
     *   2.  Transaction or VersionedTransaction
     * */
    const hash = await sendTransaction(
      transaction,
      getMFA2,
      'Creating your Gift... ',
      userSolAddress
    )

    console.log('createGiftInSol Success:', shareID, hash)
    return { shareID, hash }
  } catch (err: any) {
    giftTradeSentry(
      'Create',
      {
        giftId: _giftId,
        userSolAddress,
        message,
        signature,
        amount,
        totalAmount,
        count,
        timeout,
        type,
        isNative,
        token,
        shareID
      },
      err
    )
    console.warn('createGiftInSol error:', {
      message: err?.message,
      err
    })
    return Promise.reject(err)
  } finally {
    TToast.clear()
  }
}

// Redeem action
// ------------------------------------------------------------------------------------------------
type RedeemGiftInSolProps = {
  userSolAddress: string
  token: string
  giftId: number
  isNative: boolean
} & RefundContractProps

export const redeemGiftInSol = async (
  {
    isNative,
    userSolAddress,
    token,
    giftId: _giftId,
    sign,
    amount,
    message
  }: RedeemGiftInSolProps,
  getMFA2: MFA2_Fn
) => {
  try {
    const {
      program,
      giftId,
      connection,
      config,
      giftData,
      vault,
      tokenMint,
      giftAta,
      account,
      configData
    } = await initGiftAccounts(_giftId, userSolAddress, token)

    console.log('refund sol giftId:', _giftId)
    const ed25519_ix = Ed25519Program.createInstructionWithPublicKey({
      publicKey: Uint8Array.from(configData.signer),
      message: Uint8Array.from(Buffer.from(message!, 'hex')),
      signature: Uint8Array.from(Buffer.from(sign, 'hex'))
    })
    const seedStr = `${Date.now()}_${_giftId}`
    let userAta: PublicKey
    if (isNative) {
      userAta = await PublicKey.createWithSeed(
        account,
        seedStr,
        CONFIGS.tokenProgram
      )
    } else {
      userAta = await getAssociatedTokenAddress(tokenMint, account)
    }

    const getWrapperSol = async () => {
      const lamports = await getMinimumBalanceForRentExemptAccount(
        connection,
        'confirmed'
      )
      const userAta = await PublicKey.createWithSeed(
        account,
        seedStr,
        CONFIGS.tokenProgram
      )

      const ix0 = SystemProgram.createAccountWithSeed({
        fromPubkey: account,
        basePubkey: account,
        newAccountPubkey: userAta,
        seed: seedStr,
        lamports,
        space: ACCOUNT_SIZE,
        programId: CONFIGS.tokenProgram
      })

      const ix1 = createInitializeAccount3Instruction(
        userAta,
        tokenMint,
        account
      )
      const unwrap_wsol_ix = createCloseAccountInstruction(
        userAta,
        account,
        account,
        [account]
      )
      return [ix0, ix1, unwrap_wsol_ix]
    }

    console.log(amount, Uint8Array.from(Buffer.from(sign, 'hex')))
    const builder = await program.methods
      .refund({
        id: giftId,
        amount: new anchor.BN(amount),
        signature: Buffer.from(sign, 'hex')
      })
      .accounts({
        config,
        giftData,
        vault,
        tokenMint,
        giftAta,
        userAta,
        feeAddr: configData.feeAddr,
        payer: account,
        associatedTokenProgram: CONFIGS.associatedTokenProgram,
        tokenProgram: CONFIGS.tokenProgram,
        systemProgram: CONFIGS.systemProgram,
        rent: CONFIGS.rent,
        ixSysvar: CONFIGS.ixSysvar
      })

    if (isNative) {
      const [ix0, ix1, unwrap_wsol_ix] = await getWrapperSol()
      builder
        .preInstructions([ix0, ix1, ed25519_ix])
        .postInstructions([unwrap_wsol_ix])
    } else {
      builder.preInstructions([ed25519_ix])
    }

    const transaction = await builder.transaction()
    transaction.feePayer = account
    transaction.recentBlockhash = (
      await connection.getLatestBlockhash()
    ).blockhash

    const hash = await sendTransaction(
      transaction,
      getMFA2,
      'Refunding your Gift...',
      userSolAddress
    )
    console.log('redeemGiftInSol Success::', hash)
    return hash
  } catch (err) {
    giftTradeSentry(
      'Refund',
      {
        isNative,
        userSolAddress,
        token,
        giftId: _giftId,
        sign,
        amount,
        message
      },
      err
    )
    console.log('redeemGiftInSol Error::', err)
    return Promise.reject(err)
  } finally {
    TToast.clear()
  }
}

// Get create gas fee
// ------------------------------------------------------------------------------------------------
export const getCreateFeeInSol = async ({
  token,
  userSolAddress,
  type
}: {
  token: string
  userSolAddress: string
  type: GiftCreateTypeEnum
}) => {
  const { configData } = await initGiftAccounts(0, userSolAddress, token)
  const fee = configData.feeArr[type][0]
  return new BigNumber(fee.toNumber()).shiftedBy(-9)
}

export const waitForTransactionConfirmationInSol = async ({
  connection,
  retryCount,
  sendRawTransaction
}: {
  connection: anchor.web3.Connection
  retryCount?: number
  sendRawTransaction: () => Promise<string>
}): Promise<string> => {
  return new Promise((resolve, reject) => {
    const _retryCount = retryCount || 20
    let count = 0
    const loop = async () => {
      const signature = await sendRawTransaction()
      if (count > _retryCount) {
        return reject('timeout')
      }
      const status = await connection.getSignatureStatus(signature)
      //  'confirmed' || 'finalized'
      if (status?.value?.confirmationStatus === 'confirmed') {
        return resolve(signature)
      }

      count++
      setTimeout(loop, 3000)
    }
    loop()
  })
}
