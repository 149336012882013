import configChains from "@/proviers/web3Provider/chains";

export enum NativeTokenSymbol {
  ETH = "ETH",
  TON = "TON",
}

export const nativeTokenFilter = ({
  isNative,
  symbol,
  chainId,
}: {
  isNative: boolean;
  symbol: string;
  chainId: number;
}) => {
  if (isNative) {
    if (symbol?.toLocaleUpperCase()?.includes(NativeTokenSymbol.ETH)) {
      return NativeTokenSymbol.ETH;
    }
    if (
      symbol?.toLocaleUpperCase()?.includes(NativeTokenSymbol.TON) &&
      chainId !== configChains.ton.id
    ) {
      return NativeTokenSymbol.TON;
    }
  }
};
