import { TSkeleton as Skeleton } from '@/components/tmd'

const SkeletonTask = () => {
  return (
    <div className="px-5">
      <Skeleton
        className="mx-auto"
        style={{
          '--height': '120px',
          '--width': '200px'
        }}
      />

      <Skeleton
        className="mx-auto mt-2"
        style={{
          '--height': '66px',
          '--width': '146px'
        }}
      />

      <Skeleton
        className="mt-6"
        style={{
          '--height': '49px'
        }}
      />
      <Skeleton
        className="mt-5"
        style={{
          '--height': '52px'
        }}
      />

      <Skeleton className="mt-4" style={{ '--height': '34px' }} />

      <Skeleton
        className="mt-13"
        style={{ '--height': '28px', '--width': '169px' }}
      />

      <Skeleton
        className="mt-4"
        style={{
          '--height': '72px'
        }}
      />

      <Skeleton className="mt-2" style={{ '--height': '72px' }} />
    </div>
  )
}

export default SkeletonTask
