import { CollectEventConfig } from "@/types/byteSdk";
const useBytePlusSdk = () => {
  const initBytePlusSdk = () => {
    if (!window?.collectEvent) return;
    window.collectEvent("init", {
      app_id: 717811, //  Must be replaced by the "app_id" you have applied for. Note that the data type is number.
      channel: "sg",
      log: true, // Enable debugging log.\
      autotrack: true,
    });
  };

  const setBytePlusSdk = (config: CollectEventConfig) => {
    if (!window?.collectEvent) return;
    window.collectEvent("config", config);
  };

  const eventOpen = ({ eventName, params }: { eventName: string; params?: any }) => {
    if (!window?.collectEvent) return;
    window.collectEvent(eventName, params);
  };

  const startBytePlusSdk = () => {
    if (!window?.collectEvent) return;
    window.collectEvent("start"); // Now you can send an event.
  };

  return {
    initBytePlusSdk,
    setBytePlusSdk,
    startBytePlusSdk,
    eventOpen,
  };
};

export default useBytePlusSdk;
