export type TomoGift = {
  version: '0.1.0'
  name: 'tomo_gift'
  instructions: [
    {
      name: 'configure'
      accounts: [
        {
          name: 'config'
          isMut: true
          isSigner: false
          docs: ['Account to store configure']
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['The payer.']
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
          docs: ['The [System] program.']
        },
        {
          name: 'rent'
          isMut: false
          isSigner: false
          docs: ['The [Rent] program.']
        }
      ]
      args: [
        {
          name: 'arg'
          type: {
            defined: 'ConfigureArg'
          }
        }
      ]
    },
    {
      name: 'send'
      accounts: [
        {
          name: 'config'
          isMut: true
          isSigner: false
          docs: ['Account to store configure']
        },
        {
          name: 'giftData'
          isMut: true
          isSigner: false
          docs: ['Gift data']
        },
        {
          name: 'vault'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenMint'
          isMut: false
          isSigner: false
          docs: ['Token mint']
        },
        {
          name: 'giftAta'
          isMut: true
          isSigner: false
          docs: ["Program's ata of token mint"]
        },
        {
          name: 'userAta'
          isMut: true
          isSigner: false
          docs: ['User wallet of token mint']
        },
        {
          name: 'feeAddr'
          isMut: true
          isSigner: false
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['The payer.']
        },
        {
          name: 'associatedTokenProgram'
          isMut: false
          isSigner: false
          docs: ['Associated [Token] program.']
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
          docs: ['SPL [Token] program.']
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
          docs: ['The [System] program.']
        },
        {
          name: 'rent'
          isMut: false
          isSigner: false
          docs: ['The [Rent] program.']
        },
        {
          name: 'ixSysvar'
          isMut: false
          isSigner: false
          docs: [
            'the supplied Sysvar could be anything else.',
            'The Instruction Sysvar has not been implemented',
            'in the Anchor framework yet, so this is the safe approach.'
          ]
        }
      ]
      args: [
        {
          name: 'arg'
          type: {
            defined: 'SendArg'
          }
        }
      ]
    },
    {
      name: 'sendWithFee'
      accounts: [
        {
          name: 'config'
          isMut: true
          isSigner: false
          docs: ['Account to store configure']
        },
        {
          name: 'giftData'
          isMut: true
          isSigner: false
          docs: ['Gift data']
        },
        {
          name: 'vault'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenMint'
          isMut: false
          isSigner: false
          docs: ['Token mint']
        },
        {
          name: 'giftAta'
          isMut: true
          isSigner: false
          docs: ["Program's ata of token mint"]
        },
        {
          name: 'userAta'
          isMut: true
          isSigner: false
          docs: ['User wallet of token mint']
        },
        {
          name: 'feeAddr'
          isMut: true
          isSigner: false
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['The payer.']
        },
        {
          name: 'associatedTokenProgram'
          isMut: false
          isSigner: false
          docs: ['Associated [Token] program.']
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
          docs: ['SPL [Token] program.']
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
          docs: ['The [System] program.']
        },
        {
          name: 'rent'
          isMut: false
          isSigner: false
          docs: ['The [Rent] program.']
        },
        {
          name: 'ixSysvar'
          isMut: false
          isSigner: false
          docs: [
            'the supplied Sysvar could be anything else.',
            'The Instruction Sysvar has not been implemented',
            'in the Anchor framework yet, so this is the safe approach.'
          ]
        }
      ]
      args: [
        {
          name: 'arg'
          type: {
            defined: 'SendWithFeeArg'
          }
        }
      ]
    },
    {
      name: 'recv'
      accounts: [
        {
          name: 'config'
          isMut: true
          isSigner: false
          docs: ['Account to store configure']
        },
        {
          name: 'giftData'
          isMut: true
          isSigner: false
          docs: ['Gift data']
        },
        {
          name: 'recvStat'
          isMut: true
          isSigner: false
          docs: ['User recv stat']
        },
        {
          name: 'vault'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenMint'
          isMut: false
          isSigner: false
          docs: ['Token mint']
        },
        {
          name: 'giftAta'
          isMut: true
          isSigner: false
          docs: ["Program's ata of token mint"]
        },
        {
          name: 'userAta'
          isMut: true
          isSigner: false
          docs: ['User wallet of token mint']
        },
        {
          name: 'userAddr'
          isMut: true
          isSigner: false
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['The payer.']
        },
        {
          name: 'associatedTokenProgram'
          isMut: false
          isSigner: false
          docs: ['Associated [Token] program.']
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
          docs: ['SPL [Token] program.']
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
          docs: ['The [System] program.']
        },
        {
          name: 'rent'
          isMut: false
          isSigner: false
          docs: ['The [Rent] program.']
        },
        {
          name: 'ixSysvar'
          isMut: false
          isSigner: false
          docs: [
            'the supplied Sysvar could be anything else.',
            'The Instruction Sysvar has not been implemented',
            'in the Anchor framework yet, so this is the safe approach.'
          ]
        }
      ]
      args: [
        {
          name: 'arg'
          type: {
            defined: 'RecvArg'
          }
        }
      ]
    },
    {
      name: 'recvBatch'
      accounts: [
        {
          name: 'config'
          isMut: true
          isSigner: false
          docs: ['Account to store configure']
        },
        {
          name: 'giftData'
          isMut: true
          isSigner: false
          docs: ['Gift data']
        },
        {
          name: 'vault'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenMint'
          isMut: false
          isSigner: false
          docs: ['Token mint']
        },
        {
          name: 'giftAta'
          isMut: true
          isSigner: false
          docs: ["Program's ata of token mint"]
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['User wallet of token mint', 'The payer.']
        },
        {
          name: 'associatedTokenProgram'
          isMut: false
          isSigner: false
          docs: ['Associated [Token] program.']
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
          docs: ['SPL [Token] program.']
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
          docs: ['The [System] program.']
        },
        {
          name: 'rent'
          isMut: false
          isSigner: false
          docs: ['The [Rent] program.']
        },
        {
          name: 'ixSysvar'
          isMut: false
          isSigner: false
          docs: [
            'the supplied Sysvar could be anything else.',
            'The Instruction Sysvar has not been implemented',
            'in the Anchor framework yet, so this is the safe approach.'
          ]
        }
      ]
      args: [
        {
          name: 'arg'
          type: {
            defined: 'RecvBatchArg'
          }
        }
      ]
    },
    {
      name: 'refund'
      accounts: [
        {
          name: 'config'
          isMut: true
          isSigner: false
          docs: ['Account to store configure']
        },
        {
          name: 'giftData'
          isMut: true
          isSigner: false
          docs: ['Gift data']
        },
        {
          name: 'vault'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenMint'
          isMut: false
          isSigner: false
          docs: ['Token mint']
        },
        {
          name: 'giftAta'
          isMut: true
          isSigner: false
          docs: ["Program's ata of token mint"]
        },
        {
          name: 'userAta'
          isMut: true
          isSigner: false
          docs: ['User wallet of token mint']
        },
        {
          name: 'feeAddr'
          isMut: true
          isSigner: false
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['The payer.']
        },
        {
          name: 'associatedTokenProgram'
          isMut: false
          isSigner: false
          docs: ['Associated [Token] program.']
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
          docs: ['SPL [Token] program.']
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
          docs: ['The [System] program.']
        },
        {
          name: 'rent'
          isMut: false
          isSigner: false
          docs: ['The [Rent] program.']
        },
        {
          name: 'ixSysvar'
          isMut: false
          isSigner: false
          docs: [
            'the supplied Sysvar could be anything else.',
            'The Instruction Sysvar has not been implemented',
            'in the Anchor framework yet, so this is the safe approach.'
          ]
        }
      ]
      args: [
        {
          name: 'arg'
          type: {
            defined: 'RefundArg'
          }
        }
      ]
    }
  ]
  accounts: [
    {
      name: 'config'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'authority'
            docs: ['The authority to set program']
            type: 'publicKey'
          },
          {
            name: 'isEnable'
            docs: ['Is enable to run program']
            type: 'bool'
          },
          {
            name: 'signer'
            docs: ["Signer's eth addr, ed25519 pubkey"]
            type: {
              array: ['u8', 32]
            }
          },
          {
            name: 'expire'
            docs: ['Expire seconds']
            type: 'u64'
          },
          {
            name: 'feeAddr'
            docs: ['Charge fee address']
            type: 'publicKey'
          },
          {
            name: 'feeArr'
            docs: ['Charge fee SOL']
            type: {
              array: [
                {
                  array: ['u64', 2]
                },
                3
              ]
            }
          }
        ]
      }
    },
    {
      name: 'giftData'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'id'
            docs: ['Gift id']
            type: 'u128'
          },
          {
            name: 'typ'
            docs: ['Gift Type']
            type: 'u8'
          },
          {
            name: 'beginTs'
            docs: ['Begin timestamp']
            type: 'u64'
          },
          {
            name: 'endTs'
            docs: ['End timestamp']
            type: 'u64'
          },
          {
            name: 'creator'
            docs: ['Gift creator']
            type: 'publicKey'
          },
          {
            name: 'token'
            docs: ['Token mint']
            type: 'publicKey'
          },
          {
            name: 'isSol'
            docs: ['Is sol token']
            type: 'bool'
          },
          {
            name: 'amount'
            docs: ['Gift amount']
            type: 'u64'
          },
          {
            name: 'count'
            docs: ['Max count to be claimed']
            type: 'u64'
          },
          {
            name: 'claimedAmt'
            docs: ['Claimed amount']
            type: 'u64'
          },
          {
            name: 'claimedCnt'
            docs: ['Claimed count']
            type: 'u64'
          },
          {
            name: 'refundAmt'
            docs: ['refund amount']
            type: 'u64'
          }
        ]
      }
    },
    {
      name: 'recvStat'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'id'
            docs: ['Gift id']
            type: 'u128'
          },
          {
            name: 'timestamp'
            docs: ['Claimed timestamp']
            type: 'u64'
          },
          {
            name: 'user'
            docs: ['User']
            type: 'publicKey'
          },
          {
            name: 'amount'
            docs: ['Token amount']
            type: 'u64'
          }
        ]
      }
    }
  ]
  types: [
    {
      name: 'ConfigureArg'
      docs: ['Configure arguments']
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'authority'
            docs: ['The authority to set program']
            type: {
              option: 'publicKey'
            }
          },
          {
            name: 'isEnable'
            docs: ['Is enable to run program']
            type: {
              option: 'bool'
            }
          },
          {
            name: 'signer'
            docs: ["Signer's ed25519 pubkey"]
            type: {
              option: {
                array: ['u8', 32]
              }
            }
          },
          {
            name: 'expire'
            docs: ['Expire seconds']
            type: {
              option: 'u64'
            }
          },
          {
            name: 'feeAddr'
            docs: ['Charge fee address']
            type: {
              option: 'publicKey'
            }
          },
          {
            name: 'feeArr'
            docs: ['Charge fee SOL']
            type: {
              option: {
                array: [
                  {
                    array: ['u64', 2]
                  },
                  3
                ]
              }
            }
          }
        ]
      }
    },
    {
      name: 'RecvBatchArg'
      docs: ['Mint arguments']
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'id'
            docs: ['Gift id']
            type: 'u128'
          },
          {
            name: 'signature'
            docs: ['Signature of signer, 64 bytes']
            type: 'bytes'
          },
          {
            name: 'recvs'
            docs: ['User id']
            type: {
              vec: {
                defined: 'RecvOne'
              }
            }
          }
        ]
      }
    },
    {
      name: 'RecvOne'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'userId'
            docs: ['User id']
            type: 'u64'
          },
          {
            name: 'amount'
            docs: ['Token amount']
            type: 'u64'
          }
        ]
      }
    },
    {
      name: 'RecvArg'
      docs: ['Mint arguments']
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'id'
            docs: ['Gift id']
            type: 'u128'
          },
          {
            name: 'signature'
            docs: ['Signature of signer, 64 bytes']
            type: 'bytes'
          },
          {
            name: 'userId'
            docs: ['User id']
            type: 'u64'
          }
        ]
      }
    },
    {
      name: 'RefundArg'
      docs: ['Mint arguments']
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'id'
            docs: ['Gift id']
            type: 'u128'
          },
          {
            name: 'amount'
            docs: ['Refund amount']
            type: 'u64'
          },
          {
            name: 'signature'
            type: 'bytes'
          }
        ]
      }
    },
    {
      name: 'SendArg'
      docs: ['Mint arguments']
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'id'
            docs: ['Gift id']
            type: 'u128'
          },
          {
            name: 'typ'
            docs: ['Gift Type']
            type: 'u8'
          },
          {
            name: 'amount'
            docs: ['Token amount']
            type: 'u64'
          },
          {
            name: 'count'
            docs: ['Max count to be claimed']
            type: 'u64'
          },
          {
            name: 'timeout'
            docs: ['Signature timeout timestamp']
            type: 'u64'
          },
          {
            name: 'signature'
            docs: ['Signature of signer, 64 bytes']
            type: 'bytes'
          },
          {
            name: 'isSol'
            docs: ['Is sol token']
            type: 'bool'
          }
        ]
      }
    },
    {
      name: 'SendWithFeeArg'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'id'
            docs: ['Gift id']
            type: 'u128'
          },
          {
            name: 'typ'
            docs: ['Gift Type']
            type: 'u8'
          },
          {
            name: 'amount'
            docs: ['Token amount']
            type: 'u64'
          },
          {
            name: 'count'
            docs: ['Max count to be claimed']
            type: 'u64'
          },
          {
            name: 'timeout'
            docs: ['Signature timeout timestamp']
            type: 'u64'
          },
          {
            name: 'signature'
            docs: ['Signature of signer, 64 bytes']
            type: 'bytes'
          },
          {
            name: 'isSol'
            docs: ['Is sol token']
            type: 'bool'
          },
          {
            name: 'fee'
            type: 'u64'
          }
        ]
      }
    },
    {
      name: 'Action'
      type: {
        kind: 'enum'
        variants: [
          {
            name: 'Send'
          },
          {
            name: 'Recv'
          }
        ]
      }
    },
    {
      name: 'GiftType'
      type: {
        kind: 'enum'
        variants: [
          {
            name: 'Fixed1'
          },
          {
            name: 'FixedN'
          },
          {
            name: 'Random'
          }
        ]
      }
    }
  ]
  events: [
    {
      name: 'SendEvt'
      fields: [
        {
          name: 'id'
          type: 'u128'
          index: false
        },
        {
          name: 'typ'
          type: 'u8'
          index: false
        },
        {
          name: 'creator'
          type: 'publicKey'
          index: false
        },
        {
          name: 'token'
          type: 'publicKey'
          index: false
        },
        {
          name: 'amount'
          type: 'u64'
          index: false
        },
        {
          name: 'count'
          type: 'u64'
          index: false
        },
        {
          name: 'beginTs'
          type: 'u64'
          index: false
        },
        {
          name: 'endTs'
          type: 'u64'
          index: false
        }
      ]
    },
    {
      name: 'RecvEvt'
      fields: [
        {
          name: 'id'
          type: 'u128'
          index: false
        },
        {
          name: 'user'
          type: 'publicKey'
          index: false
        },
        {
          name: 'amount'
          type: 'u64'
          index: false
        },
        {
          name: 'userId'
          type: 'u64'
          index: false
        }
      ]
    },
    {
      name: 'RefundEvt'
      fields: [
        {
          name: 'id'
          type: 'u128'
          index: false
        },
        {
          name: 'creator'
          type: 'publicKey'
          index: false
        },
        {
          name: 'amount'
          type: 'u64'
          index: false
        }
      ]
    }
  ]
  errors: [
    {
      code: 6000
      name: 'IncorrectOwner'
      msg: 'Account does not have correct owner!'
    },
    {
      code: 6001
      name: 'InvalidAuthrioty'
      msg: 'Invalid authrioty!'
    },
    {
      code: 6002
      name: 'OverflowClaimed'
      msg: 'Overflow claimed!'
    },
    {
      code: 6003
      name: 'NotAllowed'
      msg: 'Not allowed!'
    },
    {
      code: 6004
      name: 'InternalError'
      msg: 'Internal error!'
    },
    {
      code: 6005
      name: 'ProtocolDisable'
      msg: 'Protocol disable!'
    },
    {
      code: 6006
      name: 'InvalidSignatureHead'
      msg: 'Invalid signature head!'
    },
    {
      code: 6007
      name: 'InvalidSignatureData'
      msg: 'Invalid signature data!'
    },
    {
      code: 6008
      name: 'InvalidSignature'
      msg: 'Invalid signature!'
    },
    {
      code: 6009
      name: 'InvalidArgument'
      msg: 'Invalid argument!'
    },
    {
      code: 6010
      name: 'InvalidDuration'
      msg: 'Invalid duration!'
    }
  ]
}

export const TomoGiftIDL: TomoGift = {
  version: '0.1.0',
  name: 'tomo_gift',
  instructions: [
    {
      name: 'configure',
      accounts: [
        {
          name: 'config',
          isMut: true,
          isSigner: false,
          docs: ['Account to store configure']
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['The payer.']
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
          docs: ['The [System] program.']
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
          docs: ['The [Rent] program.']
        }
      ],
      args: [
        {
          name: 'arg',
          type: {
            defined: 'ConfigureArg'
          }
        }
      ]
    },
    {
      name: 'send',
      accounts: [
        {
          name: 'config',
          isMut: true,
          isSigner: false,
          docs: ['Account to store configure']
        },
        {
          name: 'giftData',
          isMut: true,
          isSigner: false,
          docs: ['Gift data']
        },
        {
          name: 'vault',
          isMut: true,
          isSigner: false
        },
        {
          name: 'tokenMint',
          isMut: false,
          isSigner: false,
          docs: ['Token mint']
        },
        {
          name: 'giftAta',
          isMut: true,
          isSigner: false,
          docs: ["Program's ata of token mint"]
        },
        {
          name: 'userAta',
          isMut: true,
          isSigner: false,
          docs: ['User wallet of token mint']
        },
        {
          name: 'feeAddr',
          isMut: true,
          isSigner: false
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['The payer.']
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['Associated [Token] program.']
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['SPL [Token] program.']
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
          docs: ['The [System] program.']
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
          docs: ['The [Rent] program.']
        },
        {
          name: 'ixSysvar',
          isMut: false,
          isSigner: false,
          docs: [
            'the supplied Sysvar could be anything else.',
            'The Instruction Sysvar has not been implemented',
            'in the Anchor framework yet, so this is the safe approach.'
          ]
        }
      ],
      args: [
        {
          name: 'arg',
          type: {
            defined: 'SendArg'
          }
        }
      ]
    },
    {
      name: 'sendWithFee',
      accounts: [
        {
          name: 'config',
          isMut: true,
          isSigner: false,
          docs: ['Account to store configure']
        },
        {
          name: 'giftData',
          isMut: true,
          isSigner: false,
          docs: ['Gift data']
        },
        {
          name: 'vault',
          isMut: true,
          isSigner: false
        },
        {
          name: 'tokenMint',
          isMut: false,
          isSigner: false,
          docs: ['Token mint']
        },
        {
          name: 'giftAta',
          isMut: true,
          isSigner: false,
          docs: ["Program's ata of token mint"]
        },
        {
          name: 'userAta',
          isMut: true,
          isSigner: false,
          docs: ['User wallet of token mint']
        },
        {
          name: 'feeAddr',
          isMut: true,
          isSigner: false
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['The payer.']
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['Associated [Token] program.']
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['SPL [Token] program.']
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
          docs: ['The [System] program.']
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
          docs: ['The [Rent] program.']
        },
        {
          name: 'ixSysvar',
          isMut: false,
          isSigner: false,
          docs: [
            'the supplied Sysvar could be anything else.',
            'The Instruction Sysvar has not been implemented',
            'in the Anchor framework yet, so this is the safe approach.'
          ]
        }
      ],
      args: [
        {
          name: 'arg',
          type: {
            defined: 'SendWithFeeArg'
          }
        }
      ]
    },
    {
      name: 'recv',
      accounts: [
        {
          name: 'config',
          isMut: true,
          isSigner: false,
          docs: ['Account to store configure']
        },
        {
          name: 'giftData',
          isMut: true,
          isSigner: false,
          docs: ['Gift data']
        },
        {
          name: 'recvStat',
          isMut: true,
          isSigner: false,
          docs: ['User recv stat']
        },
        {
          name: 'vault',
          isMut: true,
          isSigner: false
        },
        {
          name: 'tokenMint',
          isMut: false,
          isSigner: false,
          docs: ['Token mint']
        },
        {
          name: 'giftAta',
          isMut: true,
          isSigner: false,
          docs: ["Program's ata of token mint"]
        },
        {
          name: 'userAta',
          isMut: true,
          isSigner: false,
          docs: ['User wallet of token mint']
        },
        {
          name: 'userAddr',
          isMut: true,
          isSigner: false
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['The payer.']
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['Associated [Token] program.']
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['SPL [Token] program.']
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
          docs: ['The [System] program.']
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
          docs: ['The [Rent] program.']
        },
        {
          name: 'ixSysvar',
          isMut: false,
          isSigner: false,
          docs: [
            'the supplied Sysvar could be anything else.',
            'The Instruction Sysvar has not been implemented',
            'in the Anchor framework yet, so this is the safe approach.'
          ]
        }
      ],
      args: [
        {
          name: 'arg',
          type: {
            defined: 'RecvArg'
          }
        }
      ]
    },
    {
      name: 'recvBatch',
      accounts: [
        {
          name: 'config',
          isMut: true,
          isSigner: false,
          docs: ['Account to store configure']
        },
        {
          name: 'giftData',
          isMut: true,
          isSigner: false,
          docs: ['Gift data']
        },
        {
          name: 'vault',
          isMut: true,
          isSigner: false
        },
        {
          name: 'tokenMint',
          isMut: false,
          isSigner: false,
          docs: ['Token mint']
        },
        {
          name: 'giftAta',
          isMut: true,
          isSigner: false,
          docs: ["Program's ata of token mint"]
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['User wallet of token mint', 'The payer.']
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['Associated [Token] program.']
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['SPL [Token] program.']
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
          docs: ['The [System] program.']
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
          docs: ['The [Rent] program.']
        },
        {
          name: 'ixSysvar',
          isMut: false,
          isSigner: false,
          docs: [
            'the supplied Sysvar could be anything else.',
            'The Instruction Sysvar has not been implemented',
            'in the Anchor framework yet, so this is the safe approach.'
          ]
        }
      ],
      args: [
        {
          name: 'arg',
          type: {
            defined: 'RecvBatchArg'
          }
        }
      ]
    },
    {
      name: 'refund',
      accounts: [
        {
          name: 'config',
          isMut: true,
          isSigner: false,
          docs: ['Account to store configure']
        },
        {
          name: 'giftData',
          isMut: true,
          isSigner: false,
          docs: ['Gift data']
        },
        {
          name: 'vault',
          isMut: true,
          isSigner: false
        },
        {
          name: 'tokenMint',
          isMut: false,
          isSigner: false,
          docs: ['Token mint']
        },
        {
          name: 'giftAta',
          isMut: true,
          isSigner: false,
          docs: ["Program's ata of token mint"]
        },
        {
          name: 'userAta',
          isMut: true,
          isSigner: false,
          docs: ['User wallet of token mint']
        },
        {
          name: 'feeAddr',
          isMut: true,
          isSigner: false
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['The payer.']
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['Associated [Token] program.']
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['SPL [Token] program.']
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
          docs: ['The [System] program.']
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
          docs: ['The [Rent] program.']
        },
        {
          name: 'ixSysvar',
          isMut: false,
          isSigner: false,
          docs: [
            'the supplied Sysvar could be anything else.',
            'The Instruction Sysvar has not been implemented',
            'in the Anchor framework yet, so this is the safe approach.'
          ]
        }
      ],
      args: [
        {
          name: 'arg',
          type: {
            defined: 'RefundArg'
          }
        }
      ]
    }
  ],
  accounts: [
    {
      name: 'config',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'authority',
            docs: ['The authority to set program'],
            type: 'publicKey'
          },
          {
            name: 'isEnable',
            docs: ['Is enable to run program'],
            type: 'bool'
          },
          {
            name: 'signer',
            docs: ["Signer's eth addr, ed25519 pubkey"],
            type: {
              array: ['u8', 32]
            }
          },
          {
            name: 'expire',
            docs: ['Expire seconds'],
            type: 'u64'
          },
          {
            name: 'feeAddr',
            docs: ['Charge fee address'],
            type: 'publicKey'
          },
          {
            name: 'feeArr',
            docs: ['Charge fee SOL'],
            type: {
              array: [
                {
                  array: ['u64', 2]
                },
                3
              ]
            }
          }
        ]
      }
    },
    {
      name: 'giftData',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'id',
            docs: ['Gift id'],
            type: 'u128'
          },
          {
            name: 'typ',
            docs: ['Gift Type'],
            type: 'u8'
          },
          {
            name: 'beginTs',
            docs: ['Begin timestamp'],
            type: 'u64'
          },
          {
            name: 'endTs',
            docs: ['End timestamp'],
            type: 'u64'
          },
          {
            name: 'creator',
            docs: ['Gift creator'],
            type: 'publicKey'
          },
          {
            name: 'token',
            docs: ['Token mint'],
            type: 'publicKey'
          },
          {
            name: 'isSol',
            docs: ['Is sol token'],
            type: 'bool'
          },
          {
            name: 'amount',
            docs: ['Gift amount'],
            type: 'u64'
          },
          {
            name: 'count',
            docs: ['Max count to be claimed'],
            type: 'u64'
          },
          {
            name: 'claimedAmt',
            docs: ['Claimed amount'],
            type: 'u64'
          },
          {
            name: 'claimedCnt',
            docs: ['Claimed count'],
            type: 'u64'
          },
          {
            name: 'refundAmt',
            docs: ['refund amount'],
            type: 'u64'
          }
        ]
      }
    },
    {
      name: 'recvStat',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'id',
            docs: ['Gift id'],
            type: 'u128'
          },
          {
            name: 'timestamp',
            docs: ['Claimed timestamp'],
            type: 'u64'
          },
          {
            name: 'user',
            docs: ['User'],
            type: 'publicKey'
          },
          {
            name: 'amount',
            docs: ['Token amount'],
            type: 'u64'
          }
        ]
      }
    }
  ],
  types: [
    {
      name: 'ConfigureArg',
      docs: ['Configure arguments'],
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'authority',
            docs: ['The authority to set program'],
            type: {
              option: 'publicKey'
            }
          },
          {
            name: 'isEnable',
            docs: ['Is enable to run program'],
            type: {
              option: 'bool'
            }
          },
          {
            name: 'signer',
            docs: ["Signer's ed25519 pubkey"],
            type: {
              option: {
                array: ['u8', 32]
              }
            }
          },
          {
            name: 'expire',
            docs: ['Expire seconds'],
            type: {
              option: 'u64'
            }
          },
          {
            name: 'feeAddr',
            docs: ['Charge fee address'],
            type: {
              option: 'publicKey'
            }
          },
          {
            name: 'feeArr',
            docs: ['Charge fee SOL'],
            type: {
              option: {
                array: [
                  {
                    array: ['u64', 2]
                  },
                  3
                ]
              }
            }
          }
        ]
      }
    },
    {
      name: 'RecvBatchArg',
      docs: ['Mint arguments'],
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'id',
            docs: ['Gift id'],
            type: 'u128'
          },
          {
            name: 'signature',
            docs: ['Signature of signer, 64 bytes'],
            type: 'bytes'
          },
          {
            name: 'recvs',
            docs: ['User id'],
            type: {
              vec: {
                defined: 'RecvOne'
              }
            }
          }
        ]
      }
    },
    {
      name: 'RecvOne',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'userId',
            docs: ['User id'],
            type: 'u64'
          },
          {
            name: 'amount',
            docs: ['Token amount'],
            type: 'u64'
          }
        ]
      }
    },
    {
      name: 'RecvArg',
      docs: ['Mint arguments'],
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'id',
            docs: ['Gift id'],
            type: 'u128'
          },
          {
            name: 'signature',
            docs: ['Signature of signer, 64 bytes'],
            type: 'bytes'
          },
          {
            name: 'userId',
            docs: ['User id'],
            type: 'u64'
          }
        ]
      }
    },
    {
      name: 'RefundArg',
      docs: ['Mint arguments'],
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'id',
            docs: ['Gift id'],
            type: 'u128'
          },
          {
            name: 'amount',
            docs: ['Refund amount'],
            type: 'u64'
          },
          {
            name: 'signature',
            type: 'bytes'
          }
        ]
      }
    },
    {
      name: 'SendArg',
      docs: ['Mint arguments'],
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'id',
            docs: ['Gift id'],
            type: 'u128'
          },
          {
            name: 'typ',
            docs: ['Gift Type'],
            type: 'u8'
          },
          {
            name: 'amount',
            docs: ['Token amount'],
            type: 'u64'
          },
          {
            name: 'count',
            docs: ['Max count to be claimed'],
            type: 'u64'
          },
          {
            name: 'timeout',
            docs: ['Signature timeout timestamp'],
            type: 'u64'
          },
          {
            name: 'signature',
            docs: ['Signature of signer, 64 bytes'],
            type: 'bytes'
          },
          {
            name: 'isSol',
            docs: ['Is sol token'],
            type: 'bool'
          }
        ]
      }
    },
    {
      name: 'SendWithFeeArg',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'id',
            docs: ['Gift id'],
            type: 'u128'
          },
          {
            name: 'typ',
            docs: ['Gift Type'],
            type: 'u8'
          },
          {
            name: 'amount',
            docs: ['Token amount'],
            type: 'u64'
          },
          {
            name: 'count',
            docs: ['Max count to be claimed'],
            type: 'u64'
          },
          {
            name: 'timeout',
            docs: ['Signature timeout timestamp'],
            type: 'u64'
          },
          {
            name: 'signature',
            docs: ['Signature of signer, 64 bytes'],
            type: 'bytes'
          },
          {
            name: 'isSol',
            docs: ['Is sol token'],
            type: 'bool'
          },
          {
            name: 'fee',
            type: 'u64'
          }
        ]
      }
    },
    {
      name: 'Action',
      type: {
        kind: 'enum',
        variants: [
          {
            name: 'Send'
          },
          {
            name: 'Recv'
          }
        ]
      }
    },
    {
      name: 'GiftType',
      type: {
        kind: 'enum',
        variants: [
          {
            name: 'Fixed1'
          },
          {
            name: 'FixedN'
          },
          {
            name: 'Random'
          }
        ]
      }
    }
  ],
  events: [
    {
      name: 'SendEvt',
      fields: [
        {
          name: 'id',
          type: 'u128',
          index: false
        },
        {
          name: 'typ',
          type: 'u8',
          index: false
        },
        {
          name: 'creator',
          type: 'publicKey',
          index: false
        },
        {
          name: 'token',
          type: 'publicKey',
          index: false
        },
        {
          name: 'amount',
          type: 'u64',
          index: false
        },
        {
          name: 'count',
          type: 'u64',
          index: false
        },
        {
          name: 'beginTs',
          type: 'u64',
          index: false
        },
        {
          name: 'endTs',
          type: 'u64',
          index: false
        }
      ]
    },
    {
      name: 'RecvEvt',
      fields: [
        {
          name: 'id',
          type: 'u128',
          index: false
        },
        {
          name: 'user',
          type: 'publicKey',
          index: false
        },
        {
          name: 'amount',
          type: 'u64',
          index: false
        },
        {
          name: 'userId',
          type: 'u64',
          index: false
        }
      ]
    },
    {
      name: 'RefundEvt',
      fields: [
        {
          name: 'id',
          type: 'u128',
          index: false
        },
        {
          name: 'creator',
          type: 'publicKey',
          index: false
        },
        {
          name: 'amount',
          type: 'u64',
          index: false
        }
      ]
    }
  ],
  errors: [
    {
      code: 6000,
      name: 'IncorrectOwner',
      msg: 'Account does not have correct owner!'
    },
    {
      code: 6001,
      name: 'InvalidAuthrioty',
      msg: 'Invalid authrioty!'
    },
    {
      code: 6002,
      name: 'OverflowClaimed',
      msg: 'Overflow claimed!'
    },
    {
      code: 6003,
      name: 'NotAllowed',
      msg: 'Not allowed!'
    },
    {
      code: 6004,
      name: 'InternalError',
      msg: 'Internal error!'
    },
    {
      code: 6005,
      name: 'ProtocolDisable',
      msg: 'Protocol disable!'
    },
    {
      code: 6006,
      name: 'InvalidSignatureHead',
      msg: 'Invalid signature head!'
    },
    {
      code: 6007,
      name: 'InvalidSignatureData',
      msg: 'Invalid signature data!'
    },
    {
      code: 6008,
      name: 'InvalidSignature',
      msg: 'Invalid signature!'
    },
    {
      code: 6009,
      name: 'InvalidArgument',
      msg: 'Invalid argument!'
    },
    {
      code: 6010,
      name: 'InvalidDuration',
      msg: 'Invalid duration!'
    }
  ]
}
