import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import classNames from "classnames";
import { t } from "i18next";
import { useImperativeHandle, useState } from "react";
import SlideButton from "react-slide-button";
import { TIcon } from "../icon";
import LoadingSvg from "../svgs/loading.svg?react";
import { mergeProps } from "../utils/get-default-props";
import { StatusButtonProps } from "./PropsType";

const defaultProps = {
  type: "confirm",
  text: t("tg_wallet.continue"),
  disabled: false,
};

export function StatusButton(p: StatusButtonProps) {
  const props = mergeProps(p, defaultProps);
  const { type, className, text, disabled, onConfirm, customCaretWidth, buttonRef, ...restProps } =
    props;

  const [reset, setReset] = useState(0);

  useImperativeHandle(
    buttonRef,
    () => ({
      reset,
      setReset,
    }),
    [reset],
  );

  const baseStyles = "flex-none w-full flex items-center justify-center";
  const sizeStyles = "h-13 w-full rounded-lg";
  const themeStyles = {
    "bg-green": type == "success",
    "bg-red border border-l1": type == "loading",
    "border-none bg-bg3": type == "confirm",
  };
  const disabledStyles = "bg-bg3 text-t4 text-base font-medium";

  const [handleImpact] = useHapticFeedback();

  if (type == "success") {
    return (
      <div
        role="button"
        className={classNames(baseStyles, sizeStyles, themeStyles, className, "slide-track")}
      >
        <TIcon name="tg_wallet_finalize-facetiousness" fontSize="24" className="text-white" />
      </div>
    );
  }
  if (type == "loading") {
    return (
      <div
        role="button"
        className={classNames(baseStyles, sizeStyles, themeStyles, className, "slide-track")}
        onClick={onConfirm}
      >
        <div className={"flex items-center justify-center gap-1"}>
          <LoadingSvg className="size-6 text-white" />
          {text && (
            <div className={"flex items-center justify-center text-base font-medium text-white"}>
              {text}
            </div>
          )}
        </div>
      </div>
    );
  }

  if (type == "swapLoading") {
    return (
      <div
        role="button"
        className={classNames(
          "cursor-not-allowed relative",
          "slide-track",
          baseStyles,
          sizeStyles,
          disabledStyles,
        )}
      >
        <div
          className={`button-slider size-13 bg-t4 absolute left-0 top-0 flex items-center justify-center rounded-[8px]`}
        >
          <LoadingSvg className="size-6 text-white" />
        </div>
        <div className={"flex items-center justify-center"}>{text}</div>
      </div>
    );
  }

  if (disabled) {
    return (
      <div
        role="button"
        className={classNames(
          "cursor-not-allowed relative",
          "slide-track",
          baseStyles,
          sizeStyles,
          disabledStyles,
        )}
      >
        <div
          className={`button-slider size-13 bg-t4 absolute left-0 top-0 flex items-center justify-center rounded-[8px]`}
        >
          <TIcon name="tg_wallet_slider" fontSize="24" className="text-white" />
        </div>
        <div className={"flex items-center justify-center"}>{text}</div>
      </div>
    );
  }
  return (
    <SlideButton
      classList={classNames("tmd-slide-btn", baseStyles, sizeStyles, themeStyles)}
      customCaretWidth={customCaretWidth || 52}
      caretClassList={"rounded-lg !bg-red"}
      overlayClassList={`rounded-lg !bg-red !transition-none !min-w-[52px] max-w-full`}
      minSlideWidth={0.6}
      // delta={1}
      mainText={text}
      caret={<TIcon name="tg_wallet_slider" fontSize="24" className="text-white" />}
      onSlideDone={() => {
        try {
          if (navigator && "vibrate" in navigator) {
            navigator.vibrate(50);
          } else {
            handleImpact("light");
          }
          onConfirm?.();
        } catch (error) {
          // console.error('Error:', error)
          setReset((prev) => prev + 1);
        }
      }}
      reset={reset}
    />
  );
}

/**
 * SuccessButton
 * @param params
 * @constructor
 */
export function SuccessButton(params: Omit<StatusButtonProps, "type">) {
  return <StatusButton type="success" {...params} />;
}

/**
 * ConfirmButton
 * @param params
 * @constructor
 */
export function ConfirmButton(params: Omit<StatusButtonProps, "type">) {
  return <StatusButton type="confirm" {...params} />;
}

/**
 * LoadingButton
 * @param params
 * @constructor
 */
export function LoadingButton(params: Omit<StatusButtonProps, "type">) {
  return <StatusButton type="loading" {...params} />;
}
