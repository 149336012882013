import { chainStdOrderList, ZERO_ADDRESS } from '@/proviers/web3Provider/chains'
import { sortChains } from '@/proviers/web3Provider/chains/utils'
import allChain from '@/proviers/web3Provider/chains/wagmiConfig/allChain'
import { IWeb3ChainType } from '@/proviers/web3Provider/type'

interface ReceiveLinkType {
  chainId: number | string
  address: string
  wtype?: string
  hideBuyButton?: boolean
}
export const receiveLink = ({
  chainId,
  address,
  wtype,
  hideBuyButton
}: ReceiveLinkType) => {
  return `/token/${chainId}/${address || ZERO_ADDRESS}/receive${
    wtype ? `/${wtype}` : ''
  }${hideBuyButton ? `?hideBuyButton=${true}` : ''}`
}

export const sortChainsForIndexBar = (
  chains: IWeb3ChainType[],
  searchText: string
): Array<{ index: string; items: IWeb3ChainType[] }> => {
  const noGroupIds = chainStdOrderList.map((chain: IWeb3ChainType) => chain.id)
  const popularChainIds = noGroupIds.slice(1)

  // 热门排序 + 字母排序
  let sortedChains = sortChains(chains)

  // 存在搜索关键词时，复用 all 的 UI 给出去，因为 all 的 group-title 会被隐藏
  if (searchText) {
    sortedChains = sortedChains.filter((v) => {
      return (
        v.name.toLocaleUpperCase().includes(searchText.toLocaleUpperCase()) ||
        v.type.toLocaleUpperCase() === searchText.toLocaleUpperCase()
      )
    })
    const targetIndex = sortedChains.findIndex(
      (v) => v.name.toLocaleUpperCase() === searchText.toLocaleUpperCase()
    )
    if (targetIndex > -1) {
      const target = sortedChains.splice(targetIndex, 1)[0]
      sortedChains.unshift(target)
    }
    return [{ index: 'all', items: sortedChains }]
  }

  // 非热门 token 少于 15 个时，不展示 ABCD... 分组
  const nonPopularChains = sortedChains.filter(
    (v) => !noGroupIds.includes(v.id)
  )
  if (nonPopularChains.length < 15) {
    return [{ index: 'all', items: sortedChains }]
  }

  const newPopularChains: IWeb3ChainType[] = []

  const grouped = sortedChains.reduce(
    (acc: { [x: string]: IWeb3ChainType[] }, chain) => {
      if (noGroupIds.includes(chain.id)) {
        if (popularChainIds.includes(chain.id)) newPopularChains.push(chain)
        return acc
      }

      const firstLetter = chain.name[0].toUpperCase()
      if (!acc[firstLetter]) {
        acc[firstLetter] = []
      }
      acc[firstLetter].push(chain)
      return acc
    },
    {}
  )

  const result = Object.keys(grouped)
    .sort()
    .map((letter) => ({
      index: letter,
      items: grouped[letter]
    }))

  const allItems = sortedChains.find((v) => v.id === allChain.id)
    ? [{ index: 'all', items: [allChain] }]
    : []

  const popularChainsItems =
    newPopularChains.length > 0
      ? [{ index: 'popular', items: newPopularChains }]
      : []

  return [...allItems, ...popularChainsItems, ...result]
}
