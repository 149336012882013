import { FeeMode } from "@/components/FeeSelect";
import { IChainType, IWeb3ChainType } from "@/proviers/web3Provider/type";
import { BTCAddressType } from "@/utils";
import { Config } from "@wagmi/core";
import { SolanaTransaction, Transfer, TronTransaction } from "rango-sdk";
import { TypeEVMPermit } from "@/stores/swapStore/type";

export interface ITomoToken {
  chain?: string;
  chainId: number;
  chainType?: IWeb3ChainType["type"];
  chainInfo?: IWeb3ChainType;
  address: string;
  name: string;
  symbol?: string;
  decimals: number;
  logoURI: string;
  price: string;
  fourMemeToken: boolean | null;
  riseTokenSymbol: string | null;
  riseTokenAddress: string | null;
}

export interface ITomoNetwork {
  nickname?: string;
  chainId?: number;
  platform: PlatformType;
}

export enum PlatformType {
  EVM = "evm",
  TON = "ton",
  TRON = "tron",
  BITCOIN = "bitcoin",
  SOLANA = "solana",
  SUI = "sui",
}

export enum Aggregator {
  RANGO = "Rango",
  OKX = "Okx",
  JUPITER = "Jupiter",
  STONFI = "Ston.fi",
  ONE_INCH = "1Inch",
  TOMOSWAP = "Tomo",
  FOURMEME = "Four.meme",
  KODIAK = "Kodiak",
  SUNSWAP = "SunSwap",
}

export enum DexTag {
  FAST = "fast",
  MAX = "max",
  BEST = "best",
}

export interface DexRouteInfo {
  estimateGasFee?: string;
  estimateGasValue?: string;
  estimateTime: number;
  minimumReceived: string;
  aggregatorType: Aggregator;
  aggregatorLogo?: string;
  originRoute?: string;
  swapData?: any;
  rangoRequestId?: string;
  swapperLogo: string;
  swapperTitle: string;
  dexRouterList?: DexRouter[];
  minReceived?: string;
  gasLimit?: string | undefined;
  priorityPrice?: number;
  extraString?: string;
  dexType?: ApiSwapDexNameType;
  tag?: DexTag[];
  executeContract?: string;
  DexType?: number;
  fee?: RouteFeeInfo;
  extraInfo?: any;
}

export enum ApiSwapDexNameType {
  UN_SET = 0,
  OKX_SWAP = 1,
  OKX_CROSS = 2,
  RANGO_BASIC = 3,
  RANGO_MAIN = 4,
  JUPITER = 5,
  OKX_CROSS_SORT1 = 6,
  OKX_CROSS_SORT2 = 7,
  SUN_SWAP = 9,
  TOMO_SWAP = 10,
  KODIAK_SWAP = 11,
  ONE_INCH = 13,
}

export interface DexTransaction {
  fromToken: ITomoToken;
  toToken: ITomoToken;
  fromAddress: string;
  targetAddress?: string; // send target wallet
  toAddress: string;
  fromWalletAddress?: Transfer["fromWalletAddress"];
  recipientAddress?: Transfer["recipientAddress"];
  addressType?: BTCAddressType;
  networkType?: string;
  approveTo?: string | null;
  approveData?: string | null;
  approveDexContractAddress?: string;
  value?: number | string;
  gasFee?: number | string;
  maxGas?: number | null;
  gasPrice?: string | null;
  maxFeePerGas?: string | null;
  maxPriorityFeePerGas?: string | null;
  data?: any;
  memo?: string | null;
  instructions?: any;
  recentBlockhash?: string | null;
  signatures?: any;
  serializedMessage?: any;
  txType?: string;
  needApprove?: boolean;
  originTron?: TronTransaction;
  params?: CreateTxParams;
  rangoSolData?: SolanaTransaction;
  rangoRequestId?: string;
  randomKeyAccount?: string[];
  jupiterSolData?: IJupiterSolData;
  blockhash?: string;
  baseFee?: string;
  fees?:
    | {
        [key in FeeMode]:
          | {
              gasLimit: string;
              gasPrice: string;
            }
          | undefined;
      }
    | undefined;
  signatureDataFunc?: string;
  extJson?: string;
  solanaCallData?: string;
  isGasInfo?: boolean;
}

export interface IExtraInfo {
  solAddress?: string;
}

export interface IJupiterSolData {
  swapTransaction: string;
  [objKey: string]: any;
}

export type Asset = {
  blockchain: string;
  address: string | null;
  symbol: string;
};
export interface SwapQuoteReq {
  from: Asset;
  to: Asset;
  amount: string;
  slippage?: number;
}

export interface SwapRequest {
  from: Asset;
  to: Asset;
  amount: string;
  fromAddress: string;
  toAddress: string;
  disableEstimate: boolean;
  enableCentralizedSwappers: boolean;
  slippage?: number;
  contractCall?: boolean;
}

export interface OkxApproveData {
  data: string;
  dexContractAddress: string;
  gasLimit: string;
  gasPrice: string;
}

export interface CreateTxParams {
  fromToken: ITomoToken;
  toToken: ITomoToken;
  fromNetwork: ITomoNetwork;
  toNetwork: ITomoNetwork;
  amount: string;
  fromAddress: string;
  toAddress: string;
  routeInfo: DexRouteInfo;
  slippage?: number;
  contractCall?: boolean;
  rangoApiKey: string;
  fromChain: IChainType | undefined;
  toChain: IChainType | undefined;
  extraInfo: IExtraInfo;
  feeMode: FeeMode;
  computeUnitPrice?: string;
  computeUnitLimit?: string;
  referrerInfo: {
    referrerFee: number;
    referrerAddress: string;
  }[];
  evmPermit?: TypeEVMPermit | undefined;
  chains?: IWeb3ChainType[] | undefined;
  config?: Config<any, any> | undefined;
}

export type RouteFeeInfo = {
  fee: string;
  formatted: string | undefined;
  formattedUsd: string | undefined;
  totalFeeInfos?:
    | {
        [key in FeeMode]: {
          fee: string;
          formatted: string;
          formattedUsd: string;
        };
      }
    | undefined;
  gasPrice: string;
  gasLimit: number;
};
