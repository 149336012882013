import { Popup } from 'antd-mobile'
import React, { useState, useEffect } from 'react'
import { usePrevious } from 'ahooks'
import { CloseRoundSvg, backSvg } from 'assets'

interface IPagePool {
  name: string
  title?: string | React.ReactNode
  content: React.ReactNode
  showCloseButton?: boolean
  onPageClose?: () => void
}

interface IProgressPopupProps {
  pagePool: IPagePool[]
  firstPageName: string
  onPopupClose: () => void
}

const useProgressPopup = ({ pagePool, firstPageName, onPopupClose }: IProgressPopupProps) => {
  const [open, setOpen] = useState(false)
  const lastOpen = usePrevious(open, () => true)
  const [pageStack, setPageStack] = useState([firstPageName])

  useEffect(() => {
    if (!open && lastOpen) {
      typeof onPopupClose === 'function' && onPopupClose()
      resetData()
    }
  }, [open])

  const nextPage = (pageName: string) => {
    setPageStack([ ...pageStack, pageName ])
  }

  const resetData = () => {
    setPageStack([firstPageName])
  }

  const onBack = () => {
    const curPageName = pageStack.pop()
    const { onPageClose } = pagePool.find(({ name }) => name === curPageName) || {}

    if (typeof onPageClose === 'function') {
      onPageClose()
    }

    if (!pageStack.length) {
      setOpen(false)
    } else {
      setPageStack([ ...pageStack ])
    }
  }

  const pageBack = () => {
    onBack()
  }

  const curPageName = pageStack[pageStack.length - 1]
  const currentPage = pagePool.find((page) => page.name === curPageName)
  const { title, content, showCloseButton } = currentPage || {}

  return {
    open,
    setOpen,
    nextPage,
    pageBack,
    component: (
      <Popup
        closeIcon={null}
        visible={open}
        showCloseButton={showCloseButton}
        onMaskClick={() => {
          setOpen(false)
        }}
        onClose={() => {
          setOpen(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: '24px',
          borderTopRightRadius: '24px'
        }}
      >
        <div className="flex size-full h-[calc(100vh-42px)] flex-col pt-0">
          {title ? (
            <div
              className={`flex flex-[0_0_60px] items-center justify-between text-xl font-bold px-[20px]`}
            >
              <img
                src={backSvg}
                className={`cursor-pointer h-[24px]`}
                onClick={onBack}
              />
              <div className="w-full text-center pr-[24px]">
                {title}
              </div>
            </div>
          ) : (
            <div
              className={`flex flex-[0_0_60px] items-center justify-end text-xl font-bold px-[20px]`}
            >
              <img
                src={CloseRoundSvg}
                className={`cursor-pointer`}
                onClick={() => {
                  setOpen(false)
                }}
              />
            </div>
          )}
          <div className="mb-[20px] flex w-full flex-1 flex-col pt-[10px] overflow-auto">
            {open && content}
          </div>
        </div>
      </Popup>
    )
  }
}

interface IPurePopup {
  content: React.ReactNode
  onClose?: () => void
  enableMaskClick?: boolean
  classNames?: string
}

export const usePurePopup = ({ content, onClose, enableMaskClick, classNames }: IPurePopup) => {
  const [open, setOpen] = useState(false)
  return {
    open,
    setOpen,
    component: (
      <Popup
        closeIcon={null}
        visible={open}
        showCloseButton={false}
        onMaskClick={() => {
          if (enableMaskClick) {
            setOpen(false)
            onClose && onClose()
          }
        }}
        onClose={() => {
          setOpen(false)
          onClose && onClose()
        }}
        bodyStyle={{
          borderTopLeftRadius: '24px',
          borderTopRightRadius: '24px'
        }}
      >
        <div className={`flex size-full flex-col p-[20px] pt-0 ${classNames || ''}`}>
          <div className="flex w-full flex-1 flex-col items-center justify-center pt-[20px]">
            {open && content}
          </div>
        </div>
      </Popup>
    )
  }
}

export default useProgressPopup
