// Gift APis
// ---------------------------------------------------------------
// 0 = single,1 = multiple average,2 = multiple random
export enum GiftCreateTypeEnum {
  Single = 0,
  Identical = 1,
  Lottery = 2
}

export type GiftCreateParams = {
  avgAmount: string // long int
  chainID: number
  decimals: number
  description: string
  price: number
  quantity: number
  token: string
  totalAmount: string // long int
  type: GiftCreateTypeEnum
  native: boolean
  userAddress: string
  symbol: string
  icon: string
}
export type GiftCreateResult = {
  id: number
  deadline: number
  chainID: number
  sign: string
  shareID: string
  hash: string
  perFee: string
}

export enum GiftStatus {
  CreatePending = 0,
  Created = 1,
  Claimed = 2,
  Expired = 3,
  Refunded = 4
}

export enum GiftReceiveStatus {
  UnClaim = 0,
  Claiming = 1,
  Claimed = 2
}

export enum ClaimStrategyEnum {
  ONLY_NEW_USER = 'only_new_user'
}

export type GiftType = {
  id: number
  userID: number
  quantity: number
  remainingQuantity: number
  chainID: number
  chain: string
  token: string
  type: number
  deadline: number
  totalAmount: string
  description: string
  shareID: string
  createdAt: string
  status: GiftStatus
  creator: boolean
  native: boolean
  decimals: number
  receiveStatus: GiftReceiveStatus
  receiveAmount: string
  refundAmount: string
  symbol: string
  price: number
  receiveTx: string
  username: string
  nickname: string
  avatar: string
  claim_strategy: ClaimStrategyEnum | ''
  createTx: string
}

export type GiftSupportTokenResult = {
  chainID: 501
  contract: string
}

export enum GiftEventType {
  Sent = 1,
  Received = 2,
  Refunded = 3
}
export type GiftEvent = {
  amount: string
  chain: string
  chainID: number
  createdAt: string
  shareID: string
  id: number
  token: string
  tx: string
  icon: string
  type: GiftEventType
  userID: number
  price: number
  decimals: number
  symbol: string
  remainingAmount: string
}

export type GiftClaimedInfo = {
  amount: string
  created_time: number
  tx: string
}

export type BotGiftCommandParams = {
  share_id: string
  cover_url?: string
  sender_id?: string
  sender_name?: string
  remark?: string
}

export type GiftClaimedRecordRes = {
  records: {
    amount: string
    avatar: string
    createdAt: string
    nickname: string
    username: string
  }[]
  total: number
}
