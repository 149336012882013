import { shortenAddress } from '@/utils/helper'
import { NumberFormatter } from './NumberFormatter'
import { useMemo } from 'react'
import { btcTypeMaps } from '@/utils'
import { useNavigate } from 'react-router-dom'
import useLoginInfo from '@/hooks/useLoginInfo'
import tokenStore from '@/stores/tokenStore'
import configChains from '@/proviers/web3Provider/chains'

interface BtcAddressTypeList {
  onClick: (val: any) => void
}
export default function BtcAddressTypeList({ onClick }: BtcAddressTypeList) {
  const { btcAddress } = useLoginInfo()
  const tokens = tokenStore.tokenList
  const token =
    tokens?.find((item) => item.chainId === configChains.btc.id) || ({} as any)

  const btcWallets = useMemo(() => {
    const price = token?.price || 0
    return Object.keys(btcTypeMaps).map((walletKey) => {
      const item = (token?.balanceItem || []).find(
        (it: any) => it.type === walletKey
      )

      return {
        ...item,
        address: btcAddress[`${walletKey}Address`],
        image: token?.image,
        price,
        typeName: btcTypeMaps[walletKey],
        type: walletKey
      }
    })
  }, [token, btcAddress])
  return (
    <>
      <div className="flex w-full flex-col items-center gap-[30px] text-xl leading-6">
        {btcWallets.map((item: any) => (
          <div
            key={item?.address}
            className="flex w-full items-center gap-[10px]"
            onClick={() => onClick(item)}
          >
            <img src={item.image} alt="" className="size-[36px]" />

            <div className="flex flex-1 flex-col">
              <div className="flex items-center justify-between gap-[10px] text-xl font-medium leading-[21px] text-t1">
                <span className="truncate text-base ">{item.typeName}</span>
                <span>{item.formatted || 0}</span>
              </div>
              <div className="flex items-center justify-between gap-[10px] text-xs leading-[17px] text-t2">
                <span>{shortenAddress(item.address)}</span>
                <span>
                  $
                  <NumberFormatter
                    value={
                      item.formatted && item.price
                        ? item.price * item.formatted
                        : 0
                    }
                  />
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
