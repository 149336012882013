import { useMemo } from 'react'
import { shortenAddress } from 'utils/helper'
import walletStore from '@/stores/walletStore'
import AdaptiveNumber, { NumberType } from '@/components/AdaptiveNumber'
import { mockBtcEvmChainId } from '@/config/btc'
import { btcTypeMaps } from '@/utils'
import useLoginInfo from '@/hooks/useLoginInfo'
import usePopup from '@/hooks/popup/usePopup'
import TokenImg from '@/pages/wallet/components/TokenImg'

interface IProps {
  onAddressChange?: (val: any) => void
}

const useBTCAddressType2 = (params?: IProps) => {
  const { onAddressChange } = params || {}
  const { btcAddress }: Record<string, any> = useLoginInfo()

  const tokens = walletStore.walletTokens
  const token = (tokens.find((token) => token.chainId === mockBtcEvmChainId) ||
    []) as any

  const btcWallets = useMemo(() => {
    return Object.keys(btcTypeMaps).map((walletKey) => {
      const item = (token?.balanceItem || []).find(
        (it: any) => it.type === walletKey
      )
      return {
        ...item,
        address: btcAddress[`${walletKey}Address`],
        image: token?.image,
        price: token?.price || 0,
        // @ts-ignore
        typeName: btcTypeMaps[walletKey],
        type: walletKey
      }
    })
  }, [token, btcAddress])

  const { setOpen, component } = usePopup({
    title: 'BTC address type',
    showCloseButton: false,
    content: (
      <div className="flex w-full flex-col items-center gap-3 text-base leading-6 ">
        {btcWallets.map((item: any) => (
          <div
            key={item?.address}
            className="flex w-full items-center gap-[10px]"
            onClick={() => {
              walletStore.btcWalletTypeActions(item.type)
              if (typeof onAddressChange === 'function') {
                onAddressChange(item)
              }
              setOpen(false)
            }}
          >
            <TokenImg
              symbol="BTC"
              image={item.image}
              chainId={0}
              isNative={true}
            />

            <div className="flex flex-1 flex-col">
              <div className="flex items-center justify-between gap-[8px]">
                <span className="mb-[2px] text-base font-medium text-t1">
                  {item.typeName}
                </span>
                <span className="mb-[2px] text-end text-base font-medium text-t1">
                  {item.formatted || 0}
                </span>
              </div>
              <div className="flex items-center justify-between gap-[8px] text-xs">
                <span className="opacity-60">
                  {shortenAddress(item.address)}
                </span>
                <span className="text-right text-xs font-normal text-t2">
                  $
                  <AdaptiveNumber
                    type={NumberType.BALANCE}
                    value={
                      item.formatted && item.price
                        ? item.price * item.formatted
                        : 0
                    }
                    decimalSubLen={4}
                  />
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  })

  return { setOpen, component }
}

export default useBTCAddressType2
