import { TokenImageProps } from "./PropsType";
import { mergeProps } from "../utils/get-default-props";
import classNames from "classnames";
import { addUnit } from "../utils/format/unit";
import { Image } from "antd-mobile";
import LoadingImgLight from "@/assets/icons/loading-img-light.svg?react";
import LoadingImgDark from "@/assets/icons/loading-img-dark.svg?react";
import { useEffect, useMemo, useState } from "react";
import { defaultInitials, getRandomColor } from "../utils/avatar/avatar";
import useTheme from "@/stores/userStore/hooks/useTheme";

const defaultProps = {
  size: 36,
  chainSize: 16,
  lazy: false,
  symbol: "*",
};

export function TokenImage(p: TokenImageProps) {
  const props = mergeProps(p, defaultProps);
  const { image, size, chainImage, chainSize, chainClassName, className, symbol, lazy, ...restProps } = props;

  const { theme } = useTheme();
  console.log("theme", theme);
  const LoadingImg = theme === "dark" ? LoadingImgDark : LoadingImgLight;

  const imageStyles = "rounded-full text-base text-white";
  const chainStyles = "absolute -right-1 -bottom-[3px] rounded-full border border-bg1";

  const imgSize = addUnit(size);
  const imgStyles = {
    width: imgSize,
    height: imgSize,
  };

  const chainImgSize = addUnit(chainSize);

  const [showImg, setShowImg] = useState(true);
  const [showChainImg, setShowChainImg] = useState(true);
  const [isLoading, setIsLoading] = useState(!!image); // 如果有图片URL，初始状态为加载中

  const text = useMemo(() => {
    return defaultInitials(symbol || "*", { maxInitials: 1 });
  }, [symbol]);

  const bgColor = useMemo(() => {
    return getRandomColor(symbol || "*");
  }, [symbol]);

  useEffect(() => {
    if (image) {
      setShowImg(true);
      setIsLoading(true); // 图片URL变化时重置加载状态
    }
  }, [image]);

  // 准备加载占位图
  const renderPlaceholder = () => (
    <div className={classNames("flex justify-center items-center", imageStyles)} style={imgStyles}>
      <LoadingImg width={size} height={size} />
    </div>
  );

  return (
    <div className={classNames("relative flex-none rounded-full", className)} style={imgStyles} {...restProps}>
      {showImg ? (
        <Image
          src={image}
          onError={() => setShowImg(false)}
          onLoad={() => setIsLoading(false)} // 图片加载完成
          width={imgSize}
          height={imgSize}
          lazy={lazy}
          className={classNames(imageStyles)}
          placeholder={isLoading ? renderPlaceholder() : undefined} // 添加加载占位图
        />
      ) : (
        <div
          className={classNames("flex justify-center items-center uppercase", imageStyles)}
          style={{ ...imgStyles, backgroundColor: bgColor }}
        >
          {text}
        </div>
      )}

      {chainImage && showChainImg && (
        <Image
          src={chainImage}
          onError={() => setShowChainImg(false)}
          width={chainImgSize}
          height={chainImgSize}
          lazy={lazy}
          className={classNames(chainStyles, chainClassName)}
        />
      )}
    </div>
  );
}
