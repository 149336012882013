import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { story } from '@wagmi/core/chains'

const icon = chainsSvgs.storyTestnetSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: story,
  id: story.id,
  type: 'EVM',
  name: 'Story Mainnet', //Story.name,
  icon: icon,
  networkType
}

export default chainInfo
