import useLoginInfo from "../useLoginInfo";
// import usePopup from './usePopup'
import usePopup from "@/hooks/popup/usePopup";
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import useBackup from "@/pages/login/hooks/useBackup";
import { TIcon, TButton, TToast as toast, TCopy as Copy } from "@/components/tmd";
import { useMemo } from "react";
import { t } from "i18next";
import { renderStringWithComponents } from "@/utils/dom";

export const usePopupAddressQRCode = ({
  address,
  qrcodeIcon,
  copyable,
  fullscreen,
  symbol,
  chainType,
  btcWalletType,
}: {
  address: string | undefined;
  qrcodeIcon?: string | undefined;
  copyable?: boolean;
  fullscreen?: boolean;
  symbol?: string | undefined;
  chainType?: string | undefined;
  btcWalletType?: string | undefined;
}) => {
  const { user } = useLoginInfo();
  const onCopy = () => {
    return toast.success(t("tg_receive_share.copied_toast_text"));
  };
  const { setOpen, component } = useBackup({
    onSkip: () => {
      setOpen(false);
    },
  });

  const receiveTips = useMemo(() => {
    const desc = t("tg_receive_share.receive_tips");
    const renderArr = renderStringWithComponents(desc, {
      $1: () => <span className="text-medium text-t1 text-sm">{symbol}</span>,
    });
    return renderArr;
  }, [symbol, t]);

  return usePopup({
    showCloseButton: true,
    maskGesture: true,
    closeIcon: <TIcon name="tg_wallet_disable" className="text-t1" fontSize="20" />,
    className: "email-popup",
    // fullscreen: true,
    contentClassName: "flex flex-col justify-between",
    content: (
      <>
        {component}
        <div className={`flex size-full flex-col items-center`}>
          <div className={`relative mb-[6px] mt-[8px] flex items-center justify-center bg-white `}>
            {address && <QRCode value={address} className={`size-[224px]`} level="H" />}
            <div
              className="absolute left-1/2 top-1/2 flex size-[54px] -translate-x-1/2 -translate-y-1/2 items-center justify-center overflow-hidden rounded-lg"
              style={{
                backgroundColor: "var(--background-BG1)",
              }}
            >
              {qrcodeIcon && <img src={qrcodeIcon} className={`size-[42px] rounded-full`} />}
            </div>
          </div>
          <div className="text-t3 mb-6 mt-1 w-[310px] text-center text-xs leading-[16px]">
            {receiveTips}
          </div>

          <div className="flex w-full flex-1 flex-col justify-end">
            <div className="mb-[30px] flex flex-col">
              <div className="flex items-center gap-[8px]">
                <span className="text-t1 text-xl font-bold">{symbol}</span>
                {!!btcWalletType && (
                  <span className="bg-bg3 text-t2  rounded-[5px] px-[4px] py-[3px] text-xs">
                    {btcWalletType}
                  </span>
                )}
              </div>
              <div className={`mt-2 flex w-full items-start gap-[8px]`}>
                <div className="text-t3 min-w-0 flex-1 break-all text-sm">{address}</div>
                {copyable && address && (
                  <Copy
                    text={address}
                    onCopy={onCopy}
                    className="border-l1 text-t2 ml-[18px] rounded-full border-[0.5px] px-[18px] py-[8px]"
                  />
                )}
              </div>
            </div>
            {copyable && (
              <div className={`w-full`}>
                {address && (
                  <div className="flex w-full">
                    <CopyToClipboard text={address} onCopy={onCopy}>
                      <TButton
                        className="w-full"
                        size="large"
                        onClick={() => () => {
                          if (!user?.email) {
                            setOpen(true);
                          }
                        }}
                      >
                        Copy Address
                      </TButton>
                    </CopyToClipboard>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    ),
  });
};
