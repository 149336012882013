import { TToast } from '@/components/tmd'
import { Icon } from '@/components/tmd/icon/Icon'
import { cssTransition } from 'react-toastify'

export interface SendSuccessType {
  amount: string
  symbol: string
}

export interface SwapSuccessType {
  fromAmount: string
  fromSymbol: string
  toAmount: string
  toSymbol: string
  scanTxUrl?: string
}

export const sendSuccessToast = ({ amount, symbol }: SendSuccessType) => {
  TToast.success(`${amount} ${symbol} has been sent`)
}

export const sendFaildToast = ({ amount, symbol }: SendSuccessType) => {
  TToast.error(
    `Sending ${amount} ${symbol} failed. Please check the network or increase the gas fee and try again`
  )
}

export const swapSuccessToast = ({
  fromAmount,
  fromSymbol,
  toAmount,
  toSymbol,
  scanTxUrl
}: SwapSuccessType) => {
  TToast.clear()
  TToast.success(
    <div>
      <div>{`Successfully traded ${fromAmount} ${fromSymbol} for ${toAmount} ${toSymbol}`}</div>
      {!!scanTxUrl && (
        <div
          className="mt-0.5 flex items-center text-xs text-blue3"
          onClick={() => {
            const webApp = window.Telegram?.WebApp
            webApp.openLink(scanTxUrl)
          }}
        >
          <div className="mr-0.5">Check on Exploer</div>
          <Icon name="tg_wallet_rises" fontSize="16" />
        </div>
      )}
    </div>,
    {
      autoClose: 5_000,
      transition: cssTransition({
        enter: 'tmd-toast-animated__enter',
        exit: 'tmd-toast-animated__leave_top'
      }),
      draggableDirection: 'y',
      draggablePercent: 20
    }
  )
}

export const swapFaildToast = ({
  fromAmount,
  fromSymbol,
  toAmount,
  toSymbol
}: SwapSuccessType) => {
  TToast.error(
    `Failed to trade ${fromAmount} ${fromSymbol} for ${toAmount} ${toSymbol}. Please check the network or increase the gas fee and try again`,
    { autoClose: 5000 }
  )
}