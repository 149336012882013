import IText from "@/components/IText";
import { TToast } from "@/components/tmd";
import { ActionType } from "@/hooks/tg/useStartApp";
import useShareUrl from "@/hooks/useShareUrl";
import useBackup from "@/pages/login/hooks/useBackup";
import TokenImg from "@/pages/wallet/components/TokenImg";
import useChainsStore from "@/stores/chainsStore/hooks/useChainsStore";
import useChains from "@/stores/tokenStore/hooks/useChains";
import { AssetsStableToken } from "@/stores/tokenStore/type/AssetsStableToken";
import { AssetsToken } from "@/stores/tokenStore/type/AssetsToken";
import { WalletType } from "@/stores/tokenStore/type/BTCToken";
import walletStore from "@/stores/walletStore";
import { getChainByChainIdV2 } from "@/stores/walletStore/utils";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { TCopy as Copy, Loading, TButton, TContainer, TIcon, useTranslation } from "components/tmd";
import { useTokenSearch } from "hooks/api/useMarketApis";
import useLoginInfo from "hooks/useLoginInfo";
import usePageSetting from "hooks/usePageSetting";
import { useCallback, useEffect, useMemo, useRef } from "react";
import QRCode from "react-qr-code";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Id as RId } from "react-toastify";
import { btcTypeMaps } from "utils";
import { zeroAddress } from "viem";
import ReceiveTabButtons from "../components/ReceiveTabButtons";
import { renderStringWithComponents } from "@/utils/dom";

export function ReceivePageComponent(props: {
  chain?: string;
  address?: string;
  wtype?: string;
  image?: string;
  tokenSymbol?: string;
  symbol?: string;
  onBack?: () => void;
}) {
  console.log(props);
  const [searchParams] = useSearchParams();
  // from home need props
  const { chain: chainIdStr, address: tokenAddress, wtype: paramWalletType } = props;

  const navigate = useNavigate();
  const { user } = useLoginInfo();
  // from market and other page need params
  const chainId = chainIdStr || "-1";
  const tokens = walletStore.walletTokens;
  // const { data: selectedTokensQuery } = useSelectedTokens()
  // const { data: trendingTokensQuery } = useTrendingTokens()

  const { getChainNameById } = useChains();
  const { chains } = useChainsStore();
  const { data, isLoading } = useTokenSearch(
    tokenAddress,
    chainId ? getChainNameById(Number(chainId)) || "" : "",
  );
  const { setOpen, component } = useBackup({
    onSkip: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (!user?.email) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (paramWalletType && btcTypeMaps[paramWalletType]) {
      walletStore.btcWalletTypeActions(paramWalletType as WalletType);
    }
  }, [paramWalletType]);

  const trendingTokens: AssetsToken[] | undefined = data?.map((item) => {
    const assets: AssetsToken = {
      isNative: !item.address,
      isToken: !!item.address,
      chainId: Number(chainId),
      decimals: item.decimals,
      symbol: item.symbol,
      name: item.symbol,
      address: item.address,
      balance: "0",
      price: Number(item.priceUsd),
      image: item.imageUrl,
      source: "market",
      id: item.groupId,
      formatted: "-",
      priceChangeH24: item?.priceChangeH24,
      isRiskToken: false,
    };

    return assets;
  });

  const { getAddressAsChainId } = useLoginInfo();

  const tokensCalc = [...tokens, ...(trendingTokens ?? [])];

  const token = tokensCalc?.find((token) => {
    if (tokenAddress !== zeroAddress) {
      return (
        token.address?.toLocaleUpperCase() === tokenAddress?.toLocaleUpperCase() &&
        String(token.chainId) == chainId
      );
    }
    return String(token.chainId) == chainId && !token.address;
  });
  console.log(token);

  const address = getAddressAsChainId({ chainId: Number(chainId) });
  const toastId = useRef<RId | null>(null);

  const onCopy = () => {
    if (toastId.current) {
      TToast.clear();
    } else {
      toastId.current = TToast.success(t("tg_receive_share.copied_toast_text"), {
        onClose: () => {
          toastId.current = null;
        },
      });
    }
  };

  const chainName =
    getChainByChainIdV2({
      chainId: Number(chainId),
      chains,
    })?.name || "";
  const toSelectToken = () => {
    navigate("/receive/select-token");
  };

  const tagName = useMemo(() => {
    let tagName = chainName;
    if (chainId == "0" && token?.symbol === "BTC") {
      tagName = btcTypeMaps?.[paramWalletType as keyof typeof btcTypeMaps] || "";
    }
    return tagName;
  }, [chainId, chainName, paramWalletType, token?.symbol]);

  const { t } = useTranslation();
  const { shareURL } = useShareUrl();
  const onShare = useCallback(() => {
    let btcAdrType = "";
    if (chainId == "0" && token?.symbol === "BTC" && paramWalletType) {
      btcAdrType = paramWalletType;
    }
    const botUrl =
      window.location.origin.indexOf("tomotg-pre.tomo.inc") === -1
        ? import.meta.env.VITE_BOT_LINK
        : "https://t.me/tomoWalletPreviewBot/tomoWalletPreview";
    const shareAddress = (tokenAddress === zeroAddress ? "" : tokenAddress) || "";
    const shareChainId = token?.chainId;
    const shareToAddres = address;
    const shareBtcAdrType = btcAdrType;
    const secondQuery = `${shareAddress}_${shareChainId}_${shareToAddres}_${shareBtcAdrType}`;
    const shareUrl = `${botUrl}?startapp=${ActionType.SEND}_${secondQuery}`;
    const text = t("tg_receive_share.share_text", {
      $1: `${token?.symbol.toUpperCase()}(${tagName})`,
    });
    shareURL(shareUrl, text);
  }, [
    address,
    chainId,
    paramWalletType,
    shareURL,
    t,
    tagName,
    token?.chainId,
    token?.symbol,
    tokenAddress,
  ]);

  const receiveTips = useMemo(() => {
    const desc = t("tg_receive_share.receive_tips");
    const renderArr = renderStringWithComponents(desc, {
      $1: () => <span className="text-medium text-t1 text-sm">{chainName}</span>,
    });
    return renderArr;
  }, [chainName, t]);

  return (
    <TContainer className="flex flex-col !px-0">
      <BackButton onClick={() => navigate(-1)} />
      {component}
      {isLoading ? (
        <div className="flex size-full items-center justify-center px-5">
          <Loading />
        </div>
      ) : (
        <>
          <div className={`flex w-full flex-1 flex-col items-center gap-[24px]`}>
            <div className="mt-[14px] flex w-full flex-1 flex-col items-center">
              <div
                className="bg-bg3 flex items-center rounded-full p-[8px] "
                onClick={toSelectToken}
              >
                <TokenImg
                  symbol={token?.symbol ?? ""}
                  image={token?.image}
                  chainId={token?.chainId ?? -1}
                  isNative={token?.isNative || (token as AssetsStableToken).stable}
                  symbolSize={18}
                  chainSize={8}
                  chainClassName="!-right-0.5 !-bottom-0.5"
                />

                <span className="text-t1 ml-[4px] mr-[8px] max-w-[160px] truncate text-sm">
                  {token?.symbol?.toUpperCase() || ""}
                </span>
                <TIcon name="tg_wallet_pull-down2" fontSize="20px" className="text-t1 mt-[-2px]" />
              </div>
              <div className="text-t1 mt-5 px-5 text-xl font-bold leading-7">
                <IText
                  value="tg_receive_share.receive"
                  params={{
                    $1: token?.symbol?.toUpperCase() || "",
                  }}
                />
              </div>
              <div className="mt-1 px-5">
                <span className="text-t3">
                  <IText value="tg_receive_share.network" />:{" "}
                </span>
                <span className="text-t1">{tagName}</span>
              </div>
              <div className="flex w-full flex-col items-center px-5">
                <div className="relative mb-[6px] mt-[26px] flex size-[248px] items-center justify-center bg-white">
                  <QRCode value={address || ""} className="size-[224px]" level="H" />
                  <div
                    className="absolute left-1/2 top-1/2 flex size-[54px] -translate-x-1/2 -translate-y-1/2 items-center justify-center overflow-hidden rounded-lg"
                    style={{
                      backgroundColor: "var(--background-BG1)",
                    }}
                  >
                    <TokenImg
                      symbol={token?.symbol ?? ""}
                      image={token?.image}
                      chainId={token?.chainId ?? -1}
                      isNative={token?.isNative || (token as AssetsStableToken).stable}
                      symbolSize={42}
                      chainSize={16}
                    />
                  </div>
                </div>
                <div className="text-t3 mt-1 w-[310px] text-center text-xs leading-[16px]">
                  {receiveTips}
                </div>
                <div className="mt-6 flex w-4/5 justify-center gap-[8px]">
                  {!searchParams.get("hideBuyButton") && <ReceiveTabButtons token={token} />}
                </div>
              </div>

              <div className="flex w-full flex-1 flex-col justify-end">
                <div className="w-full rounded-lg px-5">
                  <div className={`flex w-full flex-col gap-[8px]`}>
                    {
                      <div className="flex items-center">
                        <div className={`flex items-center gap-[4px]`}>
                          {token && (
                            <div
                              className={`text-t1 max-w-[160px] truncate text-xl font-medium leading-[21px]`}
                            >
                              {token.symbol.toLocaleUpperCase()}
                            </div>
                          )}
                          {!!tagName && (
                            <div className="bg-bg3  text-t2 rounded-[5px] px-[4px] py-[3px] text-xs">
                              {tagName}
                            </div>
                          )}
                        </div>
                      </div>
                    }

                    <div className={`flex w-full items-center gap-[4px] text-lg leading-[20px]`}>
                      <div className="text-t3 min-w-0 flex-1 break-all text-sm">{address}</div>

                      <Copy
                        onCopy={onCopy}
                        text={address || ""}
                        className="border-l1 text-t2 ml-[18px] rounded-full border-[0.5px] px-[18px] py-[8px]"
                      />
                    </div>
                  </div>
                </div>

                <div className={`mt-8 flex w-full items-center gap-3 px-5`}>
                  <TButton
                    block
                    size="large"
                    onClick={onShare}
                    className="bg-primary rounded-[8px] font-normal leading-[1.3] text-white"
                  >
                    <IText value="tg_receive_share.share_btn" />
                  </TButton>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </TContainer>
  );
}

export default function Receive() {
  const { chain: chainIdStr, address: tokenAddress, wtype: paramWalletType } = useParams();

  const navigate = useNavigate();

  usePageSetting({
    navBar: {
      show: false,
      // title: `Receive ${symbol.toLocaleUpperCase()}`,
      back: true,
      onBack: () => navigate(-1),
    },
  });

  return <ReceivePageComponent chain={chainIdStr} address={tokenAddress} wtype={paramWalletType} />;
}
