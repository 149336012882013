import { TweetToken } from "@/pages/aiFeed/types";
import { fromHex } from "@/utils/string";

export interface ToSendParamsType {
  chainId: string;
  toAddress: string;
  address: string;
  btcAdrType: string;
  amount: string;
}

export const Routers = {
  index: "/",
  login: "/login",
  swap: "/swap",
  invite: "/invite",
  history: {
    detail: (hash: string) => `/history/detail?hash=${encodeURIComponent(fromHex(hash))}`,
    // _${encodeURIComponent(hash)}
  },
  gift: {
    create: (step?: number) => `/gift?step=${step || 0}`,
    manage: "/gift/manage",
    claim: (shareID: string) => `/gift/claim/${shareID}`,
    share: (shareID: string) => `/gift/share/${shareID}`,
    shareQrCode: (shareID: string) => `/gift/share-qrcode/${shareID}`,
  },
  market: {
    root: "/market",
    tokenDetail: (chain: string, token: string) => `/market/token/${chain}/${token}`,
  },
  task: "/task",
  token: {
    send: () => `/send/select-token`,
    sendInputAmount: (params: Omit<ToSendParamsType, "amount">) => {
      const searchUrl = new URLSearchParams(params).toString();
      return `/send/input-amount?${searchUrl}`;
    },
    sendConfirm: (params: ToSendParamsType) => {
      // @ts-expect-error any
      const searchUrl = new URLSearchParams(params).toString();
      return `/send/confirm-send?${searchUrl}`;
    },
    receive: (chain: number, token: string) => `/token/${chain}/${token}/receive`,
  },
  ramp: {
    trade: (token?: string) => `/ramp/trade`,
    currency: () => `/ramp/currency`,
    cardDetails: `/ramp/card-details`,
    confirmPayment: (lastFourDigits: string, cardType: string) =>
      `/ramp/confirm-payment/${lastFourDigits}/${cardType}`,
    orderStatus: "/ramp/order-status",
    verificationCode: "/ramp/verification-code",
    choosePaymentMethod: "/ramp/choose-payment-method",
    paymentStatus: (merchantOrderNo: string) => `/ramp/payment-status/${merchantOrderNo}`,
    history: "/ramp/history",
    iframePage: (webUrl: string) => `/ramp/iframe-page/${webUrl}`,
    moonpay: () => "/ramp/moonpay",
  },
  star: {
    swap: (params?: { chainId?: string; amount?: string; to?: string }) => {
      return `/star/swap${params ? `?${new URLSearchParams(params).toString()}` : ""}`;
    },
    history: () => `/star/history`,
  },
  send: {
    selectToken: "/send/select-token",
    inputAddress: (search: string) => {
      return `/send/input-address?${search}`;
    },
  },
  aiFeed: {
    root: "/aiFeed",
    home: (tab: string, params?: { keyword?: string }, filter?: string) => {
      return `/aiFeed/home/${tab}${filter ? `/${filter}` : ""}${
        params ? `?${new URLSearchParams(params).toString()}` : ""
      }`;
    },
    token: (tweetId?: string, params?: Partial<TweetToken>) => {
      return `/aiFeed/token${tweetId ? `/${tweetId}` : ""}${
        params ? `?${new URLSearchParams(params).toString()}` : ""
      }`;
    },
    training: (tweetId: string, from: string) => {
      return `/aiFeed/training/${tweetId}/${from}`;
    },
    user: (id: string, username?: string) => {
      return `/aiFeed/user/${id}${username ? `?${new URLSearchParams({ n: username }).toString()}` : ""}`;
    },
    trainingSubmit: (tweetId: string, option: string) => {
      return `/aiFeed/training-submit/${tweetId}/${option}`;
    },
  },
  mnemonic: {
    request: "/mnemonic/request",
    countdown: "/mnemonic/countdown",
    received: "/mnemonic/received",
    phrase: "/mnemonic/phrase",
  },
  gauth: {
    result: "/account/gauth/result",
    main: "/account/gauth/main",
  },
  recovery: {
    main: "/recovery/main",
    emailCode: "/recovery/email_code",
    walletList: "/recovery/wallet_list",
    noResult: "/recovery/no-result",
    phraseCountDown: "/recovery/phrase_count_down",
    phraseRequest: "/recovery/phrase_request",
    phraseReceived: "/recovery/phrase_received",
    phrase: "/recovery/phrase",
    gaInput: "/recovery/ga_input",
    gaMain: "/recovery/ga_main",
    gaBind: "/recovery/ga_bind",
    gaEmailCode: "/recovery/ga_email_code",
    gaBindResult: "/recovery/ga_bind_result",
    paypin: "/recovery/paypin",
  },
  joinCommunity: {
    joinCommunity: "/join_community/index",
  },
};
