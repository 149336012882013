import { lazy, PropsWithChildren } from "react";
import { createBrowserRouter, RouteObject, useNavigate, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import GuardRoute from "./GuardRoute";
import ErrorBoundary from "./ErrorBoundary";
import LaunchPage from "@/pages/login/Launch";
import Oauth from "@/pages/oauth/index";
import walletStore from "@/stores/walletStore";
import { PageLoading } from "@/components/PageLoading";
import {
  // CardDetails,
  ChoosePaymentMethod,
  ConfirmPayment,
  HistoryPage,
  OrderStatus,
  // PaymentStatus,
  SelectCurrency,
  // TradeView,
} from "@/pages/ramp";
import Union from "@/pages/union";
import useUserStore from "@/stores/userStore/hooks/useUserStore";
import useApp from "@/hooks/oauth/useApp";
import RouteErrorBoundary from "./RouteErrorBoundary";
import { Routers } from "./config";

const SignTonTx = lazy(() => import("@/pages/ton-connect/TonTransfer"));
const MarketSearchToken = lazy(() => import("@/pages/market/MarketSearchToken"));
// 使用 lazy 加载需要懒加载的组件
const HomeLayout = lazy(() => import("layout/HomeLayout"));
const Home = lazy(() => import("@/pages/Home"));
const RecoveryEmail = lazy(() => import("@/pages/login/RecoveryEmail"));
const Test = lazy(() => import("pages/Test"));
const MarketToken = lazy(() => import("pages/market/Detail"));
const WalletToken = lazy(() => import("pages/market/WalletDetail"));
const Market = lazy(() => import("pages/market/Market"));
const SwapPage = lazy(() => import("pages/SwapPage"));
const Task = lazy(() => import("pages/task/Task"));
const UserOptions = lazy(() => import("pages/wallet/UserOptions"));
const SelectSendToken = lazy(() => import("@/pages/token/sendToken/SelectSendToken"));
const SelectBtcAddressType = lazy(() => import("@/pages/token/selectBtcAddressType"));
const InputAddress = lazy(() => import("@/pages/token/sendToken/InputAddress"));
const InputAmount = lazy(() => import("@/pages/token/sendToken/InputAmount"));
const AccountAddress = lazy(() => import("pages/wallet/AccountAddress"));
const ConfirmSendInfo = lazy(() => import("pages/token/sendToken/ConfirmSendInfo"));
const SendResult = lazy(() => import("pages/token/sendToken/SendResult"));
const ManageToken = lazy(() => import("@/pages/token/manageToken/index"));
const AddToken = lazy(() => import("@/pages/token/manageToken/AddToken"));

const GiftManageView = lazy(() => import("pages/gift/GiftManageView"));
const GiftDetailView = lazy(() => import("pages/gift/GiftDetailView"));
const GiftCreateView = lazy(() => import("pages/gift/GiftCreateView"));
const GiftHistoryDetailView = lazy(() => import("pages/gift/GiftHistoryDetailView"));

const ForgetPage = lazy(() => import("@/pages/login/Forget"));
const ChangePage = lazy(() => import("@/pages/login/Change"));
const VerifyPage = lazy(() => import("@/pages/login/Verify"));
const EmailPage = lazy(() => import("@/pages/login/EmailCurrent"));
const SettingPage = lazy(() => import("@/pages/login/Setting"));
const ThemePage = lazy(() => import("@/pages/login/Theme"));
const LanguagePage = lazy(() => import("@/pages/login/Language"));
const Freeze = lazy(() => import("@/pages/login/Freeze"));
const BackupPage = lazy(() => import("@/pages/login/components/BackupPage"));

const Layout = lazy(() => import("layout"));
const TokenDetail = lazy(() => import("pages/token/detail"));
const TokenReceive = lazy(() => import("pages/token/detail/Receive"));
const Manage = lazy(() => import("pages/Manage"));
const SelectReceiveToken = lazy(() => import("pages/token/detail/SelectReceiveToken"));
const InviteNavigate = lazy(() => import("../pages/InviteNavigate"));
const ReferralPage = lazy(() => import("pages/referral"));
const SetPage = lazy(() => import("@/pages/login/Set"));
const BioPage = lazy(() => import("@/pages/login/Bio"));
const FriendsInvited = lazy(() => import("@/pages/friendsInvited"));
const SwapSelectTokenPage = lazy(() => import("@/pages/swap/SwapSelectTokenPage"));
const History = lazy(() => import("@/pages/history"));
const HistoryDetail = lazy(() => import("@/pages/history/Detail"));
const SelectCardToken = lazy(() => import("pages/token/card/SelectCardToken"));

const MnemonicIndex = lazy(() => import("pages/manages/mnemonic/index"));
const MnemonicRequest = lazy(() => import("@/pages/manages/mnemonic/request"));
const MnemonicCountdown = lazy(() => import("pages/manages/mnemonic/countdown"));
const MnemonicReceived = lazy(() => import("pages/manages/mnemonic/received"));
const MnemonicPhrase = lazy(() => import("pages/manages/mnemonic/phrase"));

const GAuthIndex = lazy(() => import("pages/login/gauth/index"));
const GAuthMain = lazy(() => import("pages/login/gauth/GAuthMain"));
const GAuthEmail = lazy(() => import("pages/login/gauth/GAuthEmail"));
const GAuthBinding = lazy(() => import("pages/login/gauth/GAuthBinding"));
const GAuthCodeVerify = lazy(() => import("pages/login/gauth/GAuthCodeVerify"));
const GAuthResult = lazy(() => import("pages/login/gauth/GAuthResult"));
const GAuthCodeInput = lazy(() => import("pages/login/gauth/GAuthCodeInput"));

const TradeView = lazy(() => import("pages/ramp/TradeView"));
const CardDetails = lazy(() => import("pages/ramp/CardDetails"));
const PaymentStatus = lazy(() => import("pages/ramp/PaymentStatus"));

const StarSwap = lazy(() => import("@/pages/starForGas/StarSwap"));
const StarSwapHistory = lazy(() => import("@/pages/starForGas/StarSwapHistory"));

const NetworkFeePage = lazy(() => import("@/pages/oauth/NetworkFee"));
const RawDataPage = lazy(() => import("@/pages/oauth/RawData"));
const Expired = lazy(() => import("@/pages/oauth/Expired"));

const AiFeedHome = lazy(() => import("@/pages/aiFeed/Home"));
const AIFeedToken = lazy(() => import("@/pages/aiFeed/Token"));
const AiFeedTraining = lazy(() => import("@/pages/aiFeed/Training"));
const AiFeedSearch = lazy(() => import("@/pages/aiFeed/Search"));
const AIFeedKolInfo = lazy(() => import("@/pages/aiFeed/KolInfo"));
const AIFeedTrainingSubmit = lazy(() => import("@/pages/aiFeed/TrainingSubmit"));

// ramp
const MoonPayIndex = lazy(() => import("@/pages/ramp/MoonPay/MoonPay"));

// recovery
const RecoveryRoot = lazy(() => import("@/pages/recovery/RecoveryRoot"));
const RecoveryMain = lazy(() => import("@/pages/recovery/Recovery"));
const RecoveryEmailCode = lazy(() => import("@/pages/recovery/RecoveryEmailCode"));
const RecoveryWalletList = lazy(() => import("@/pages/recovery/RecoveryWalletList"));
const RecoveryNoResult = lazy(() => import("@/pages/recovery/RecoveryNoResult"));
const RecoveryPhraseRequest = lazy(() => import("@/pages/recovery/PhraseRequest"));
const RecoveryPhraseCountDown = lazy(() => import("@/pages/recovery/PhraseCountDown"));
const RecoveryPhraseReceived = lazy(() => import("@/pages/recovery/PhraseReceived"));
const RecoveryPhrase = lazy(() => import("@/pages/recovery/Phrase"));
const RecoveryGaInput = lazy(() => import("@/pages/recovery/GaInput"));
const RecoveryGaMain = lazy(() => import("@/pages/recovery/GaMain"));
const RecoveryGaBind = lazy(() => import("@/pages/recovery/GaBind"));
const RecoveryGaEmailCode = lazy(() => import("@/pages/recovery/GaEmailCode"));
const RecoveryGaBindResult = lazy(() => import("@/pages/recovery/GaBindResult"));
const RecoveryPaypin = lazy(() => import("@/pages/recovery/Paypin"));

// join_community
const JoinCommunity = lazy(() => import("@/pages/joinCommunity/JoinCommunity"));
const JoinCommunityIndex = lazy(() => import("@/pages/joinCommunity/Index"));

export type RouteConfig = RouteObject & {
  auth?: boolean;
  children?: RouteConfig[];
};

// 创建新路由，记得添加<ErrorBoundary />或者<RouteErrorBoundary/>
// 两者效果相同，只是使用<RouteErrorBoundary />无需包裹children，刷新按钮则是刷新整个路由
// 值得注意<Layout />当中存在许多side effect，不能当<outlet />来使用
export const routes: RouteConfig[] = [
  {
    path: "/",
    element: (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    ),
    auth: true,
    children: [
      {
        path: "/",
        element: <HomeLayout />,
        children: [
          {
            index: true,
            element: <Home store={walletStore} />,
            auth: true,
          },
          {
            path: "/task",
            element: <Task />,
            auth: true,
          },
          {
            path: "/swap",
            auth: false,
            children: [
              {
                path: "/swap/select-token",
                element: <SwapSelectTokenPage />,
              },
              {
                index: true,
                element: <SwapPage />,
              },
            ],
          },
          {
            path: "/history",
            children: [
              {
                path: "/history/detail",
                auth: true,
                element: <HistoryDetail />,
              },
              {
                index: true,
                auth: true,
                element: <History />,
              },
            ],
          },
          {
            path: "/market",

            auth: true,
            children: [
              {
                index: true,
                auth: true,
                element: <Market />,
              },
              {
                path: "/market/search-token",
                element: <MarketSearchToken />,
                auth: true,
              },
              {
                path: "/market/token/:chain/:token",
                element: <MarketToken />,
                auth: true,
              },
            ],
          },
          {
            path: "/wallet/token/:chain/:token",
            element: <WalletToken />,
            auth: true,
          },
          {
            path: "/send",
            auth: false,
            children: [
              {
                path: "/send/select-token",
                element: <SelectSendToken />,
                auth: true,
              },
              {
                path: "/send/input-address",
                element: <InputAddress />,
                auth: true,
              },
              {
                path: "/send/input-amount",
                element: <InputAmount store={walletStore} />,
                auth: true,
              },
              {
                path: "/send/confirm-send",
                element: <ConfirmSendInfo />,
                auth: true,
              },
            ],
          },
          {
            path: "/receive",
            auth: false,
            children: [
              {
                path: "/receive/select-token",
                auth: true,
                element: <SelectReceiveToken />,
              },
            ],
          },
          {
            path: "/token/:chain/:address/receive/:wtype?",
            auth: true,
            element: <TokenReceive />,
          },
          {
            path: "/card",
            auth: false,
            children: [
              {
                path: "/card/select-token",
                auth: true,
                element: <SelectCardToken />,
              },
            ],
          },
          {
            path: "/sdk/sign-ton",
            auth: true,
            element: <SignTonTx />,
          },
          // {
          //   path: '/star',
          //   auth: false,
          //   children: [
          //     {
          //       path: '/star/swap',
          //       auth: true,
          //       element: <StarSwap />
          //     },
          //     {
          //       path: '/star/history',
          //       auth: true,
          //       element: <StarSwapHistory />
          //     }
          //   ]
          // },
          {
            path: "/:from/result",
            element: <SendResult />,
            auth: true,
          },
          {
            path: "/aiFeed",
            auth: true,
            children: [
              {
                index: true,
                element: <AiFeedHome />,
              },
              {
                path: "user/:uid",
                element: <AIFeedKolInfo />,
              },
              {
                path: "token/:tweetId?",
                element: <AIFeedToken />,
              },
              {
                path: "home/:type/:filter?",
                element: <AiFeedHome />,
              },
              {
                path: "search",
                element: <AiFeedSearch />,
              },
              {
                path: "training/:tweetId/:from",
                element: <AiFeedTraining />,
              },
              {
                path: "training-submit/:tweetId/:option",
                element: <AIFeedTrainingSubmit />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        index: true,
        element: <LaunchPage />,
      },
      {
        path: "/login/verify",
        auth: false,
        element: <VerifyPage />,
      },
      {
        path: "/login/email",
        auth: false,
        element: <EmailPage />,
      },
      {
        path: "/login/forget",
        auth: false,
        element: <ForgetPage />,
      },
      {
        path: "/login/set",
        auth: false,
        element: <SetPage />,
      },
      {
        path: "/login/freeze",
        auth: false,
        element: <Freeze />,
      },
      {
        path: "/login/backup",
        auth: false,
        element: <BackupPage />,
      },
    ],
  },
  {
    path: "/oauth",
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        index: true,
        element: <Oauth />,
        auth: false,
      },
      {
        path: "/oauth/network-fee",
        element: <NetworkFeePage />,
        auth: false,
      },
      {
        path: "/oauth/raw-data",
        element: <RawDataPage />,
        auth: false,
      },
      {
        path: "/oauth/expired",
        element: <Expired />,
        auth: false,
      },
    ],
  },
  {
    path: "/account",
    element: (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <UserOptions />,
        auth: true,
      },
      {
        path: "/account/manage-token",
        element: <ManageToken />,
        auth: true,
      },
      {
        path: "/account/add-token",
        element: <AddToken />,
        auth: true,
      },
      {
        path: "/account/address",
        element: <AccountAddress />,
        auth: true,
      },
      {
        path: "/account/email",
        element: <EmailPage />,
        auth: false,
      },
      {
        path: "/account/settings",
        auth: true,
        element: <SettingPage />,
      },
      {
        path: "/account/language",
        auth: true,
        element: <LanguagePage />,
      },
      {
        path: "/account/theme",
        auth: true,
        element: <ThemePage />,
      },
      {
        path: "/account/recovery-email",
        auth: false,
        element: <RecoveryEmail />,
      },
      {
        path: "/account/change",
        auth: true,
        element: <ChangePage />,
      },
      {
        path: "/account/bio",
        auth: true,
        element: <BioPage />,
      },
      {
        path: "/account/manage",
        element: <Manage />,
        auth: true,
      },
      {
        path: "/account/gauth",
        auth: true,
        element: <GAuthIndex />,
        children: [
          {
            path: "/account/gauth/main",
            element: <GAuthMain />,
          },
          {
            path: "/account/gauth/email",
            element: <GAuthEmail />,
          },
          {
            path: "/account/gauth/binding",
            element: <GAuthBinding />,
          },
          {
            path: "/account/gauth/verify",
            element: <GAuthCodeVerify />,
          },
          {
            path: "/account/gauth/result",
            element: <GAuthResult />,
          },
          {
            path: "/account/gauth/input",
            element: <GAuthCodeInput />,
          },
        ],
      },
    ],
  },
  {
    path: "/friends-invited",
    element: <FriendsInvited />,
    errorElement: <RouteErrorBoundary />,
    auth: true,
  },
  {
    path: "/test",
    element: <Test />,
    errorElement: <RouteErrorBoundary />,
  },
  {
    path: "/l/:inviteCode",
    element: <InviteNavigate />,
    errorElement: <RouteErrorBoundary />,
  },
  {
    path: "/mnemonic",
    auth: true,
    element: <MnemonicIndex />,
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        path: "/mnemonic/request",
        element: <MnemonicRequest />,
      },
      {
        path: "/mnemonic/countdown",
        element: <MnemonicCountdown />,
      },
      {
        path: "/mnemonic/received",
        element: <MnemonicReceived />,
      },
      {
        path: "/mnemonic/phrase",
        element: <MnemonicPhrase />,
      },
    ],
  },

  // {
  //   path: '/invite',
  //   element: <Invite />
  // },

  {
    path: "/referral",
    element: <ReferralPage />,
    errorElement: <RouteErrorBoundary />,
    auth: true,
  },
  {
    path: "/token/:chain/:address",
    errorElement: <RouteErrorBoundary />,
    auth: true,
    children: [
      // {
      //   path: '/token/:chain/:address/send/:wtype?',
      //   element: <SendToken />
      // },
      {
        index: true,
        element: <TokenDetail />,
      },
    ],
  },
  {
    path: "/:from/select-btc-type",
    element: <SelectBtcAddressType />,
    errorElement: <RouteErrorBoundary />,
    auth: true,
  },
  {
    path: "/gift",
    auth: false,
    // element: (
    //   <ErrorBoundary>
    //     <Layout />
    //   </ErrorBoundary>
    // ),
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        path: "/gift/manage",
        element: <GiftManageView />,
      },
      {
        path: "/gift/:type/:id",
        element: <GiftDetailView />,
      },
      {
        path: "/gift/history/:id/:type",
        element: <GiftHistoryDetailView />,
      },
      {
        index: true,
        element: <GiftCreateView />,
      },
    ],
  },
  {
    path: "/ramp",
    auth: true,
    element: (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: "/ramp/trade",
        element: <TradeView />,
      },
      {
        path: "/ramp/currency",
        element: <SelectCurrency />,
      },
      {
        path: "/ramp/card-details",
        element: <CardDetails />,
      },
      {
        path: "/ramp/confirm-payment/:lastFourDigits/:cardType",
        element: <ConfirmPayment />,
      },
      {
        path: "/ramp/order-status",
        element: <OrderStatus />,
      },
      {
        path: "/ramp/choose-payment-method",
        element: <ChoosePaymentMethod />,
      },
      {
        path: "/ramp/payment-status/:merchantOrderNo",
        element: <PaymentStatus />,
      },
      {
        path: "/ramp/history",
        element: <HistoryPage />,
      },
      {
        path: "/ramp/moonpay",
        auth: true,
        element: <MoonPayIndex />,
      },
    ],
  },
  {
    path: "/ramp/union",
    element: <Union />,
    errorElement: <RouteErrorBoundary />,
  },
  {
    path: "/recovery",
    auth: false,
    element: (
      <ErrorBoundary>
        <RecoveryRoot />
      </ErrorBoundary>
    ),
    children: [
      {
        path: Routers.recovery.main,
        element: <RecoveryMain />,
      },
      {
        path: Routers.recovery.emailCode,
        element: <RecoveryEmailCode />,
      },
      {
        path: Routers.recovery.walletList,
        element: <RecoveryWalletList />,
      },
      {
        path: Routers.recovery.noResult,
        element: <RecoveryNoResult />,
      },
      {
        path: Routers.recovery.phraseRequest,
        element: <RecoveryPhraseRequest />,
      },
      {
        path: Routers.recovery.phraseCountDown,
        element: <RecoveryPhraseCountDown />,
      },
      {
        path: Routers.recovery.phraseReceived,
        element: <RecoveryPhraseReceived />,
      },
      {
        path: Routers.recovery.phrase,
        element: <RecoveryPhrase />,
      },
      {
        path: Routers.recovery.gaInput,
        element: <RecoveryGaInput />,
      },
      {
        path: Routers.recovery.gaMain,
        element: <RecoveryGaMain />,
      },
      {
        path: Routers.recovery.gaEmailCode,
        element: <RecoveryGaEmailCode />,
      },
      {
        path: Routers.recovery.gaBind,
        element: <RecoveryGaBind />,
      },
      {
        path: Routers.recovery.gaBindResult,
        element: <RecoveryGaBindResult />,
      },
      {
        path: Routers.recovery.paypin,
        element: <RecoveryPaypin />,
      },
    ],
  },
  {
    path: "/join_community",
    auth: false,
    element: (
      <ErrorBoundary>
        <JoinCommunityIndex />
      </ErrorBoundary>
    ),
    children: [
      {
        path: Routers.joinCommunity.joinCommunity,
        element: <JoinCommunity />,
      },
    ],
  },
  {
    path: "/*",
    element: <PageLoading />,
    errorElement: <RouteErrorBoundary />,
  },
];

function NotFound() {
  return <h1>Not Found</h1>;
}

const JumpToOauthWrapper = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isValidActions, loading } = useApp();
  const { userState } = useUserStore();

  const currentPath = location.pathname;

  // if is in recovery email then don't need redirect
  const isRecoveryEmail = currentPath.includes("recovery-email");
  // if already in oauth then don't need redirect
  const isOauth = currentPath.includes("oauth");
  // check is user onboarding
  const needOnboarding = userState.newUser || !userState.tgId || !userState.setTradePassword;

  // is verifying
  const needVerify = currentPath === "/login/verify";

  if (isValidActions && !isRecoveryEmail && !isOauth && !needOnboarding && !needVerify)
    navigate("/oauth");

  return loading ? <PageLoading /> : <>{children}</>;
};

const wrapWithGuard = (route: RouteConfig): RouteConfig => {
  const comp = <JumpToOauthWrapper>{route.element}</JumpToOauthWrapper>;
  if (route.auth) {
    return {
      ...route,
      element: <GuardRoute>{comp}</GuardRoute>,
    };
  }

  if (route.children) {
    return {
      ...route,
      children: route.children.map(wrapWithGuard),
    };
  }

  return {
    ...route,
    element: comp,
  };
};

const guardedRoutes = routes.map(wrapWithGuard);

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const routers = sentryCreateBrowserRouter(guardedRoutes);
