import TomoSvg from "@/assets/imgs/tomo.svg";

export const LoadingIcon = ({
  isLoading,
  size = 24,
  textSize = 10,
  marginTop = 0,
}: {
  isLoading: boolean;
  size?: number;
  textSize?: number;
  marginTop?: number;
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <img src={TomoSvg} alt="" /> */}
      <img width={size} height={size} className={isLoading ? "animate-bounce" : ""} src={TomoSvg}></img>
      {/* <TomoSvg width={size} className={isLoading ? 'animate-bounce' : ''} /> */}
      <span style={{ fontSize: textSize, color: "#f21f7f", marginTop: marginTop }}>Tomo.inc</span>
    </div>
  );
};
