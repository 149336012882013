import { mockTronOKXChainId } from "@/config/tron";
import ailayer from "./wagmiConfig/ailayer";
import allChain from "./wagmiConfig/allChain";
import arbitrum from "./wagmiConfig/arbitrum";
import arbitrumSepolia from "./wagmiConfig/arbitrumSepolia";
import avalanche from "./wagmiConfig/avalanche";
import b2network from "./wagmiConfig/b2network";
import b3 from "./wagmiConfig/b3";
import base from "./wagmiConfig/base";
import baseSepolia from "./wagmiConfig/baseSepolia";
import berachain_bArtioTestnet from "./wagmiConfig/berachain_bArtioTestnet";
import berachain_main from "./wagmiConfig/berachain_main";
import bitlayer from "./wagmiConfig/bitlayer";
import blast from "./wagmiConfig/blast";
import blastSepolia from "./wagmiConfig/blastSepolia";
import bob from "./wagmiConfig/bob";
import botanixTestnet from "./wagmiConfig/botanixTestnet";
import bsc from "./wagmiConfig/bsc";
import bscTestnet from "./wagmiConfig/bscTestnet";
import btc from "./wagmiConfig/btc";
import corn from "./wagmiConfig/corn";
import cosmos from "./wagmiConfig/cosmos";
import doge from "./wagmiConfig/doge";
import duckChain from "./wagmiConfig/duckChain";
import duckChainTest from "./wagmiConfig/duckChainTest";
import ethereum from "./wagmiConfig/ethereum";
import gravity from "./wagmiConfig/gravity";
import linea from "./wagmiConfig/linea";
import lineaSepolia from "./wagmiConfig/lineaSepolia";
import lorenzo from "./wagmiConfig/lorenzo";
import mantle from "./wagmiConfig/mantle";
import mantleSepoliaTestnet from "./wagmiConfig/mantleSepoliaTestnet";
import merlin from "./wagmiConfig/merlin";
import mevmTestnet from "./wagmiConfig/mevmTestnet";
import movement from "./wagmiConfig/movement";
import neox from "./wagmiConfig/neox";
import opbnb from "./wagmiConfig/opbnb";
import optimism from "./wagmiConfig/optimism";
import optimismSepolia from "./wagmiConfig/optimismSepolia";
import polygon from "./wagmiConfig/polygon";
import promTestnet from "./wagmiConfig/promTestnet";
import scroll from "./wagmiConfig/scroll";
import sei from "./wagmiConfig/sei";
import solana from "./wagmiConfig/solana";
import sui from "./wagmiConfig/sui";
import tacTestnet from "./wagmiConfig/tacTestnet";
import ton from "./wagmiConfig/ton";
import tonTestnet from "./wagmiConfig/tonTestnet";
import tron from "./wagmiConfig/tron";
import worldchain from "./wagmiConfig/world";
import zkSync from "./wagmiConfig/zkSync";
import storyOdyssey from "./wagmiConfig/storyOdyssey";
import story from "./wagmiConfig/story";

export const prodEvmChains = [
  ethereum,
  bsc,
  arbitrum,
  optimism,
  linea,
  zkSync,
  polygon,
  base,
  blast,
  avalanche,
  bob,
  scroll,
  opbnb,
  b3,
  worldchain,
  duckChain,
  ailayer,
  bitlayer,
  merlin,
  corn,
  b2network,
  gravity,
  neox,
  sei,
  lorenzo,
  promTestnet,
  duckChainTest,
  berachain_bArtioTestnet,
  botanixTestnet,
  // mevmTestnet,
  tacTestnet,
];

//?? b3,ailayer,duckChainTest,duckChain
export const okxChains = [
  ethereum,
  bsc,
  arbitrum,
  optimism,
  linea,
  polygon,
  base,
  blast,
  avalanche,
  scroll,
  zkSync,
  // opbnb,
  bitlayer,
  merlin,
  { ...tron, id: mockTronOKXChainId },
  sui,
  solana,
];

const chains = {
  allChain,
  solana,
  arbitrum,
  arbitrumSepolia,
  avalanche,
  base,
  opbnb,
  baseSepolia,
  blast,
  blastSepolia,
  bsc,
  bscTestnet,
  ethereum,
  linea,
  lineaSepolia,
  mantle,
  mantleSepoliaTestnet,
  optimism,
  optimismSepolia,
  polygon,
  btc,
  corn,
  scroll,
  b3,
  tron,
  ton,
  duckChain,
  duckChainTest,
  ailayer,
  bitlayer,
  worldchain,
  merlin,
  sui,
  sei,
  lorenzo,
  berachain_bArtioTestnet,
  berachain_main,
  botanixTestnet,
  tonTestnet,
  tacTestnet,
  zkSync,
  b2network,
  gravity,
  mevmTestnet,
  promTestnet,
  neox,
  bob,
  cosmos,
  doge,
  movement,
  storyOdyssey,
  story,
};

// zkSync
export const onRampChain = {
  [chains.btc.id]: {
    chain: "BTC",
    token: "bitcoin",
  },
  [chains.ethereum.id]: {
    chain: "ETH",
    token: "ethereum",
  },
  [chains.solana.id]: {
    chain: "SOL",
    token: "solana",
  },
  [chains.ton.id]: {
    chain: "TON",
    token: "ton",
  },
  [chains.tron.id]: {
    chain: "TRX",
    token: "tron",
  },
  [chains.bsc.id]: {
    chain: "BSC",
    token: "bnb",
  },
  [chains.arbitrum.id]: {
    chain: "ARBITRUM",
    token: "arb_eth",
  },
  // [chains.base.id]: {
  //   chain: 'BASE',
  //   token: 'base_eth'
  // },
  // [chains.blast.id]: {
  //   chain: 'BLAST',
  //   token: 'blast'
  // },
  [chains.avalanche.id]: {
    chain: "AVAX",
    token: "avax",
  },
  [chains.polygon.id]: {
    chain: "MATIC",
    token: "matic",
  },
  // [chains.scroll.id]: {
  //   chain: 'SCROLL',
  //   token: 'scroll_eth'
  // },
  [chains.optimism.id]: {
    chain: "OPTIMISM",
    token: "op_eth",
  },
  // [chains.linea.id]: {
  //   chain: 'LINEA',
  //   token: 'linea_eth'
  // },
  // [chains.b3.id]: {
  //   chain: 'B3',
  //   token: 'ethereum'
  // },
  // [chains.duckChainTest.id]: {
  //   chain: 'DuckChainTest',
  //   token: 'ton'
  // },
  // [chains.duckChain.id]: {
  //   chain: 'DuckChain',
  //   token: 'ton'
  // },
  // [chains.ailayer.id]: {
  //   chain: 'AILayer',
  //   token: 'btc'
  // },
  // [chains.bitlayer.id]: {
  //   chain: 'BitLayer',
  //   token: 'btc'
  // },
  // [chains.merlin.id]: {
  //   chain: 'Merlin',
  //   token: 'btc'
  // },
  // [chains.sui.id]: {
  //   chain: 'SUI',
  //   token: 'sui'
  // }
  // [chains.sepolia.id]: {
  //   chain: 'SEPOLIA',
  //   token: 'ethereum'
  // }
};

// 热门链排序标准，其它链按照字母排序：A,B,C,...
// 参考：https://qsg07xytt12z.sg.larksuite.com/wiki/V5g3w5FWYi8BXpk7qQLllnCJgHK
export const hotChainsOrderList = [ethereum, solana, btc, bsc, base, ton, tron, sui];

// 排序标准，包含 All Network
export const chainStdOrderList = [allChain, ...hotChainsOrderList];

// market 榜单链筛选
export const marketFilterChains = [allChain, ethereum, solana, base, bsc, arbitrum, sui, ton];

// 市场搜索筛选链
export const marketSearchFilterChains = [
  allChain,
  ...hotChainsOrderList,
  arbitrum,
  avalanche,
  blast,
  optimism,
  polygon,
];

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
const configChains = chains;
export default configChains;

export const popularChains = [btc, ethereum, solana, bsc];
