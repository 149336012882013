// https://qsg07xytt12z.sg.larksuite.com/wiki/Ydpgw98wii1sbMkerxJlIWE7g2b
export function transformMoonPayCodeFromSymbol(symbol: string, chain: string) {
  const lowerSymbol = symbol.toLowerCase();
  const lowerChain = chain.toLowerCase();

  switch (lowerChain) {
    case "bnb chain":
      return `${lowerSymbol}_bsc`;
    case "ethereum": {
      const withChainSymbolList = [
        "1INCH",
        "ARKM",
        "BLUR",
        "CRV",
        "EIGEN",
        "ENA",
        "FET",
        "GMT",
        "JASMY",
        "LDO",
        "LPT",
        "MEME",
        "NEIRO",
        "ONDO",
        "QNT",
        "STRK",
        "TRB",
      ].map((item) => item.toLowerCase());
      if (withChainSymbolList.includes(lowerSymbol)) {
        return `${lowerSymbol}_eth`;
      }
      return lowerSymbol;
    }
    case "cardano":
    case "algorand":
    case "aptos":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "arbitrum one":
      if (lowerSymbol === "arb") {
        return "arb_arb";
      } else {
        return `${lowerSymbol}_arbitrum`;
      }
    case "cosmos hub":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "avalanche c":
      return `${lowerSymbol}_cchain`;
    case "bitcoin_cash":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "solana":
      if (["pyth", "sol"].includes(lowerSymbol)) {
        return lowerSymbol;
      }
      return `${lowerSymbol}_sol`;
    case "bitcoin":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "tron":
      if (["usdt", "usdc"].includes(lowerSymbol)) {
        return `${lowerSymbol}_trx`;
      }
      return lowerSymbol;
    case "ton":
      if ("ton" === lowerSymbol) {
        return "ton";
      }
      return `${lowerSymbol}_${lowerChain}`;
    case "celo":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "core":
      return lowerSymbol;
    case "polygon":
      if ("crv" === lowerSymbol) {
        return "crv_pol";
      }
      return `${lowerSymbol}_${lowerChain}`;
    case "zksync era":
      return `${lowerSymbol}_zksync`;
    case "base":
      return `${lowerSymbol}_${lowerChain}`;
    case "digibyte":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "dogecoin":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "polkadot":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "dydx":
      return `${lowerSymbol}_dydx`;
    case "multiversx":
    case "eosio":
    case "ethereum_classic":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "immutable":
      return `${lowerSymbol}_${lowerChain}`;
    case "linea":
      return `${lowerSymbol}_${lowerChain}`;
    case "optimism":
      return `${lowerSymbol}_${lowerChain}`;
    case "sorare":
      return `${lowerSymbol}_${lowerChain}`;
    case "flow":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "flow_evm":
      return `${lowerSymbol}_${lowerChain}`;
    case "hedera":
      if ("USDT".includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "icp":
      return `${lowerSymbol}_${lowerChain}`;
    case "injective":
      return `${lowerSymbol}_inj`;
    case "lisk":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "litecoin":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "mina":
      return `${lowerSymbol}_${lowerChain}`;
    case "iota":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "mantle":
      return `${lowerSymbol}_${lowerChain}`;
    case "near":
      if ("near" === lowerSymbol) {
        return lowerSymbol;
      }
      return `${lowerSymbol}_${lowerChain}`;
    case "okc":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "osmosis":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "ronin":
      if ("ronin" === lowerSymbol) {
        return lowerSymbol;
      } else if ("PIXEL" === lowerSymbol) {
        return "pixel_ron";
      }
      return `${lowerSymbol}_${lowerChain}`;
    case "qtum":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "ripple":
      if ("xrp" === lowerSymbol) {
        return "xrp";
      }
      return `${lowerSymbol}_xrp`;
    case "rune":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "sei evm":
      return `${lowerSymbol}_sei`;
    case "sonic":
      return `${lowerSymbol}_${lowerChain}`;
    case "starknet":
      return `${lowerSymbol}_strk`;
    case "stacks":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "sui":
      if (["usdc", "usdt", "eth"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "theta":
      return `${lowerSymbol}_${lowerChain}`;
    case "celestia":
      return `${lowerSymbol}_tia`;
    case "noble":
      return `${lowerSymbol}_${lowerChain}`;
    case "stellar":
      if ("xlm" === lowerSymbol) {
        return "xlm";
      }
      return `${lowerSymbol}_xlm`;
    case "vendom":
      return `${lowerSymbol}_${lowerChain}`;
    case "vechain":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "wax":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "worldchain":
      return `${lowerSymbol}_wld`;
    case "nano":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "tezos":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    case "zilliqa":
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return lowerSymbol;
    default:
      if (["usdc", "usdt"].includes(lowerSymbol)) {
        return `${lowerSymbol}_${lowerChain}`;
      }
      return `${lowerSymbol}_${lowerChain}`;
  }
}
