import { getMovementPublicKey, singMovementTx } from "@/api";
import type { Aptos, SimpleTransaction } from "@aptos-labs/ts-sdk";

export const movementScanUrl = "https://explorer.movementnetwork.xyz/txn/";
export const movementScanAddressUrl = "https://explorer.movementnetwork.xyz/account/";
export const mockMovementChainId = 126;
const rpcUrl: string = "https://mainnet.movementnetwork.xyz/v1";

// const rpcUrl: string = 'https://rpc.sentio.xyz/movement/v1'

const APTOS_COIN = "0x1::aptos_coin::AptosCoin"
const COIN_STORE = `0x1::coin::CoinStore<${APTOS_COIN}>`
const DEFAULT_GAS_USED = 1200
const GAS_PRICE = 100

export const getAptosClient = (() => {
  let client: Aptos | null = null;
  return async () => {
    if (!client) {
      // Setup the client
      const { AptosConfig, Network, Aptos: AptosClient } = await import('@aptos-labs/ts-sdk');
      const config = new AptosConfig({
        network: Network.CUSTOM,
        fullnode: rpcUrl,
      });
      client = new AptosClient(config);
    }
    return client;
  };
})();

/** get movement balance */
export const getMovementBalabceByAddress = async (address: string) => {
  const client = await getAptosClient();
  try {
    const accountResources = await client.getAccountResource<{
      coin: { value: string };
    }>({
      accountAddress: address,
      resourceType: COIN_STORE,
    });
    if (accountResources) {
      const balance = accountResources.coin?.value ?? "0";
      const formatted = +balance / 1e8 + "";
      return { balance, formatted };
    } else {
      return null;
    }
  } catch (error) {
    console.error("获取move余额失败:", error);
  }
};

export const buildMovementTransaction = async ({
  fromAddress,
  toAddress,
  value,
}: {
  fromAddress: string;
  toAddress: string;
  value: string;
}) => {
  const client = await getAptosClient();
  const { AccountAddress } = await import('@aptos-labs/ts-sdk');
  const txn = await client.transaction.build.simple({
    sender: AccountAddress.fromString(fromAddress),
    data: {
      functionArguments: [AccountAddress.fromString(toAddress), +value],
      function: "0x1::aptos_account::transfer",
    },
  });

  return txn;
};

export const getRawTransactionFromTxn = async (txn: SimpleTransaction) => {
  const { Serializer } = await import('@aptos-labs/ts-sdk');
  const serializer = new Serializer();
  txn.serialize(serializer);
  const serializedData = serializer.toUint8Array();
  const data = Buffer.from(serializedData).toString("hex");
  return data;
};

/** send tx */
export const sendMovementTx = async ({
  txn,
  signRes,
}: {
  txn: SimpleTransaction;
  signRes: {
    publicKey: string;
    sig: string;
  };
}) => {
  const client = await getAptosClient();
  const { Ed25519Signature, Ed25519PublicKey, AccountAuthenticatorEd25519 } = await import('@aptos-labs/ts-sdk');
  const signature = new Ed25519Signature(signRes.sig);
  const publicKey = new Ed25519PublicKey(signRes.publicKey);
  const senderAuthenticator = new AccountAuthenticatorEd25519(
    publicKey,
    signature
  );
  const committedTxn = await client.transaction.submit.simple({
    transaction: txn,
    senderAuthenticator,
  });
  return committedTxn;
};

/** sing tx */
export const signMoveTransaction = async (params: {
  rawTransaction: SimpleTransaction;
}) => {
  return await singMovementTx(params);
};

/** get tx status */
export const getMovementTransactionStatus = async (hash: string) => {
  const client = await getAptosClient();
  const executedTransaction = await client.waitForTransaction({
    transactionHash: hash,
  });
  return executedTransaction;
};

export const getMovementEstimateGasFee = async ({
  fromAddress,
  toAddress,
  value,
}: {
  fromAddress: string;
  toAddress: string;
  value: string;
}) => {
  const client = await getAptosClient();
  try {
    const transaction = await buildMovementTransaction({
      fromAddress,
      toAddress,
      value: "" + value,
    });
    const signerPublicKey = await getPublicKey();
    const [userTransactionResponse] = await client.transaction.simulate.simple({
      signerPublicKey,
      transaction,
    });
    if (userTransactionResponse) {
      const { gas_used, gas_unit_price } = userTransactionResponse
      return ((Number(gas_used) || DEFAULT_GAS_USED) * (Number(gas_unit_price) || GAS_PRICE)) / 1e8
    }
    const gas = await client.getGasPriceEstimation()
    return ((gas?.gas_estimate ?? GAS_PRICE) * DEFAULT_GAS_USED) / 1e8
  } catch (error) {
    const gas = await client.getGasPriceEstimation()
    return ((gas?.gas_estimate ?? GAS_PRICE) * DEFAULT_GAS_USED) / 1e8
  }
};

const getPublicKey = async () => {
  const key = await getMovementPublicKey();
  const { Ed25519PublicKey } = await import('@aptos-labs/ts-sdk');
  const publicKey = new Ed25519PublicKey(key.result);
  return publicKey;
};
