import { ISwapTokenType } from "@/pages/swap/hooks/type";
import { configChainsInfoAtom } from "@/state";
import { AssetsToken } from "@/stores/tokenStore/type/AssetsToken";
import { mockTonChainId, mockTonOkxChainID } from "config/ton";
import { useAtom } from "jotai";
import configChains from "proviers/web3Provider/chains";
import { IChainId } from "proviers/web3Provider/type";

const useChains = () => {
  const [configChainsInfo, setConfigChainsInfo] = useAtom(configChainsInfoAtom);

  const getChainIdByName = (value: string | undefined): IChainId | undefined => {
    if (configChainsInfo && value) {
      for (const [key, val] of Object.entries(configChainsInfo.chain_id_name)) {
        if (val === value) {
          return Number(
            key == mockTonOkxChainID.toString() ? configChains.ton.id.toString() : key,
          ) as IChainId;
        }
      }
    }
    return undefined;
  };

  const getChainNameById = (chainId: IChainId | undefined) => {
    const apiChainId = chainId === mockTonOkxChainID ? mockTonChainId : chainId;

    if (configChainsInfo && typeof apiChainId === "number") {
      return configChainsInfo.chain_id_name[apiChainId];
    }
    return undefined;
  };

  const getITokenBySwapToken = (swapToken: ISwapTokenType | undefined): AssetsToken | undefined => {
    if (!swapToken) return;
    const assets: AssetsToken = {
      isNative: !swapToken?.address,
      isToken: !!swapToken?.address,
      chainId: getChainIdByName(swapToken?.chain) ?? -1,
      decimals: swapToken?.decimals ?? 18,
      symbol: swapToken?.symbol ?? "",
      name: swapToken?.name ?? "",
      address: swapToken?.address ?? "",
      balance: "0",
      price: Number(swapToken?.priceUsd),
      image: swapToken?.imageUrl ?? "",
      source: "all",
      id: `${swapToken?.address ?? ""}-${getChainIdByName(swapToken?.chain) ?? -1}-${swapToken?.symbol ?? ""}`,
      formatted: "0",
      priceChangeH24: swapToken?.priceChangeH24?.toString() ?? "0",
      isRiskToken: false,
    };
    return assets;
  };

  return {
    getChainIdByName,
    getChainNameById,
    getITokenBySwapToken,
  };
};

export default useChains;
