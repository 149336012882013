import { IWeb3ChainType } from '../type'
import { chainStdOrderList } from './index'

const sortChains = (chains: IWeb3ChainType[]) => {
  const res = []
  const chainOrderingStdListIds = chainStdOrderList.map((v) => v.id)
  const workChains = [...chains]
  for (let i = 0; i < chainOrderingStdListIds.length; i++) {
    const chain = workChains.find((v) => v.id === chainOrderingStdListIds[i])
    if (chain) res.push(chain)
  }
  const otherChains = workChains.filter((v) => !chainOrderingStdListIds.includes(v.id))
  otherChains.sort((a, b) => {
    const aValue = a.name.replace(/[^a-zA-Z]/g, '')
    const bValue = b.name.replace(/[^a-zA-Z]/g, '')
    for (let i = 0; i < Math.max(aValue.length, bValue.length); i++) {
      const charA = aValue[i] ? aValue[i].toLowerCase() : ''
      const charB = bValue[i] ? bValue[i].toLowerCase() : ''
      if (charA !== charB) {
        return charA.charCodeAt(0) < charB.charCodeAt(0) ? -1 : 1
      }
    }
    return 0
  })
  res.push(...otherChains)
  return res
}

export { sortChains }
