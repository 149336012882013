import { getTokenShareMessageId } from "@/api";
import { moonpayIcon } from "@/assets";
import IText from "@/components/IText";
import { Grid, Skeleton, TIcon, Toast } from "@/components/tmd";
import { isAndroid, isIOS } from "@/components/tmd/utils/validate";
import { mockBtcEvmChainId } from "@/config/btc";
import { IWeb3ChainType } from "@/proviers/web3Provider/type";
import { swapTokenDefaults } from "@/config/const";
import { mockEthEvmChainId } from "@/config/eth";
import { mockSolEvmChainId } from "@/config/sol";
import useTPopup from "@/hooks/popup/usePopup";
import { usePopupBtcType } from "@/hooks/popup/usePopupBtcType";
import useLoginInfo from "@/hooks/useLoginInfo";
import usePopup from "@/hooks/popup/usePopup";
import usePosterPortal from "@/hooks/usePosterPortal";
import Join from "@/pages/market/components/Join";
import { receiveLink } from "@/pages/token/utils";
import useBTCAddressType2 from "@/pages/walletPage/hooks/useBTCAddressType2";
import configChains from "@/proviers/web3Provider/chains";
import useChainsStore from "@/stores/chainsStore/hooks/useChainsStore";
import rampStore from "@/stores/rampStore";
import useRampStore from "@/stores/rampStore/hooks/useRampStore";
import tokenStore from "@/stores/tokenStore";
import useChains from "@/stores/tokenStore/hooks/useChains";
import { AssetsToken } from "@/stores/tokenStore/type/AssetsToken";
import useTheme from "@/stores/userStore/hooks/useTheme";
import walletStore from "@/stores/walletStore";
import { getChainByChainIdV2 } from "@/stores/walletStore/utils";
import { transformMoonPayCodeFromSymbol } from "@/utils/moonpay";
import { formatNumber } from "@/utils/number";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { TrendingToken } from "api/type";
import classNames from "classnames";
import { MenuItem } from "components/MenuItem";
import { t } from "i18next";
import { checkGiftSupported } from "pages/gift/actions";
import { useSelectedToken } from "pages/gift/hooks/useGiftCreateState";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routers } from "router";
import { isBtc } from "utils";
import { zeroAddress } from "viem";
import { visaSvg, mastercardSvg } from "assets";
import { TOKEN_DETAIL_KEY } from "@/constants/storageKey";
import useBuyAndSell from "@/hooks/useBuyAndSell";

export enum MarketTabsEnum {
  SEND = "Send",
  RECEIVE = "Receive",
  CARD = "Add cash",
  SWAP = "Swap",
  GIFT = "Gift",
  REFER = "Share",
  CHAT = "Chat",
}

// ----------------------------------------------------------------
type Props = {
  toSwap: () => void;
  token: TrendingToken | undefined;
  disabled: boolean;
  // showItems?: MarketTabsEnum[]
  chainId: number | undefined;
  iconClassname?: string;
  extraData: Record<string, unknown>;
  className?: string;
  marketData: Record<string, any>;
  isLoading?: boolean;
  showRamp?: boolean;
};

// 暂时先不显示 refer 按钮
const noReferChainIds = [
  80094, // bera
  1514, // story
  126, // movement
];

export function Menus({
  chainId,
  toSwap,
  token,
  disabled = false,
  iconClassname,
  // showItems = defaultTabsShow,
  extraData,
  marketData,
  className,
  isLoading,
  showRamp = false,
}: Props) {
  const navigate = useNavigate();
  const [_, setSelectedToken] = useSelectedToken();
  const { getChainIdByName } = useChains();
  const webApp = useWebApp();
  const { createPoster, destroyPoster } = usePosterPortal();
  const { user } = useLoginInfo();
  const [isPosterCreating, setPosterCreating] = useState(false);
  const { supportGiftChains, showMenus, chains } = useChainsStore();
  const dataChainId = chainId ? chainId : getChainIdByName(token?.chain);
  const { moonPaySupportCodes } = useRampStore();

  const chain = getChainByChainIdV2({
    chainId: dataChainId || -1,
    chains,
  });

  const isFourMeme = useMemo(() => {
    return token?.fourMemeToken && !token?.launchOnPancake;
  }, [token]);
  const showItems = showMenus({ isFourMeme, ...chain } as IWeb3ChainType);

  const { setOpen: setReceiveOpen, component: receiveComponent } = usePopupBtcType({});
  const { setOpen: setSendOpen, component: sendComponent } = usePopupBtcType({
    routeType: "send",
  });

  const curToken = useMemo(() => {
    if (!token) return undefined;

    const assets: AssetsToken = {
      isNative: token.isNative,
      isToken: !token.isNative,
      chainId: dataChainId ?? -1,
      decimals: token?.decimals,
      symbol: token?.symbol?.toLocaleUpperCase(),
      name: token?.symbol,
      address: token?.address,
      balance: "0",
      price: Number(token?.priceUsd || 0),
      image: token?.imageUrl || "",
      source: "market",
      id: `${token.address ?? ""}-${dataChainId}-${token.symbol}`,
      formatted: "-",
      priceChangeH24: token?.priceChangeH24?.toString?.() || "",
      isRiskToken: false,
    };
    rampStore.setSelectedToken(assets);
    return assets;
  }, [dataChainId, token]);
  const { component: rampComponent, setToken: setTokenRamp } = useBuyAndSell();

  // const { setOpen, component: qrCode } = useTPopup({
  //   showCloseButton: false,
  //   content: <QrCode />
  // })

  const openTgInvite = async () => {
    Toast.show({ icon: "loading", duration: 0 });
    setPosterCreating(true);
    try {
      const chainId = dataChainId;
      const posterImgData = (await createPoster({
        token,
        chainId,
        marketData,
      })) as string;
      console.log("%c posterImgData", "color: red", posterImgData);

      if (!posterImgData) return;

      const fromChainId =
        ((chainId === mockBtcEvmChainId ? mockEthEvmChainId : chainId) || "") + "";

      const toChainId = (typeof chainId === "number" ? chainId.toString() : "") + "";

      let fromCurrency = "";
      let toCurrency = "";

      if (chainId === mockBtcEvmChainId) {
        fromCurrency = swapTokenDefaults[mockEthEvmChainId] || "";
        toCurrency = token?.address || "";
      } else if (!token?.address) {
        fromCurrency = swapTokenDefaults[chainId || mockSolEvmChainId] || "";
        toCurrency = "";
      } else if (isFourMeme) {
        fromCurrency = token?.riseTokenAddress || "";
        toCurrency = token?.address || "";
      } else {
        fromCurrency = "";
        toCurrency = token?.address || swapTokenDefaults[chainId || mockSolEvmChainId] || "";
      }
      const redirectUrl = `SWAPTOKEN_${fromChainId}-${fromCurrency}-${toChainId}-${toCurrency}`;
      const params = {
        image: posterImgData,
        symbol: token?.symbol || "",
        sender_name: user?.tgName || user?.nickname || "",
        sender_id: user?.tgId || "",
        redirect_url: redirectUrl,
        price: (marketData?.lastPrice?.toFixed(2) || 0) + "",
        gain: ((token?.priceChangeH24 || 0).toFixed(2) || 0) + "",
        cap: token?.marketCapUsd ? formatNumber(token?.marketCapUsd || "") : "-",
        // @ts-ignore
        liquidity: token?.liquidityUsd ? formatNumber(token?.liquidityUsd || "") : "-",
      };

      console.log("poster-getTokenShareMessageId-params", params);

      const messageId = await getTokenShareMessageId(params);
      Toast.clear();

      if (messageId) {
        console.log("poster-messageId", messageId);
        webApp?.shareMessage?.(messageId);
      }
    } catch (err) {
      console.log("%c poster-create-err", "color: red", err);
    } finally {
      Toast.clear();
      setPosterCreating(false);
      destroyPoster();
    }
  };

  const { setOpen: setJoinOpen, component: setJoinCompoent } = useTPopup({
    showCloseButton: false,
    fullscreen: true,
    content: <Join />,
    contentClassName: "flex flex-col items-center overflow-y-auto",
  });

  const iconBaseClass = "text-t1";
  const iconBaseFontSize = "20px";
  const menus = [
    {
      iconSrc: (
        <TIcon name="tg_wallet_sent" fontSize={iconBaseFontSize} className={iconBaseClass} />
      ),
      title: t("tg_wallet_token.icon_item1"),
      disabled: !showItems.includes(MarketTabsEnum.SEND),
      action: () => {
        if (disabled) return;
        if (isBtc(dataChainId as number)) {
          setSendOpen(true);
          return;
        }
        const urlSearchStr = new URLSearchParams({
          chainId: dataChainId ? dataChainId.toString() : "",
          address: token?.address ?? "",
        }).toString();

        navigate(`/send/input-address?${urlSearchStr}`);
        // setChooseSendOpen(true)
      },
      className: "",
    },
    {
      iconSrc: (
        <TIcon name="tg_wallet_reception" fontSize={iconBaseFontSize} className={iconBaseClass} />
      ),
      title: t("tg_wallet_token.icon_item2"),
      disabled: !showItems.includes(MarketTabsEnum.RECEIVE),
      action: () => {
        if (disabled) return;
        if (isBtc(dataChainId as number)) {
          setReceiveOpen(true);
          return;
        }

        navigate(
          receiveLink({
            chainId: dataChainId || "-1",
            address: token?.address ? token?.address : zeroAddress,
          }),
        );
      },
    },
    {
      iconSrc: (
        <TIcon name="tg_wallet_swap" fontSize={iconBaseFontSize} className={iconBaseClass} />
      ),
      title: t("tg_wallet_token.icon_item3"),
      disabled: !showItems.includes(MarketTabsEnum.SWAP),
      action: () => {
        if (disabled) return;
        toSwap();
      },
    },
    {
      iconSrc: (
        <TIcon name="tg_wallet_red-gift" fontSize={iconBaseFontSize} className={iconBaseClass} />
      ),
      title: t("tg_wallet_token.icon_item4"),
      disabled:
        !showItems.includes(MarketTabsEnum.GIFT) ||
        !checkGiftSupported({
          chains: supportGiftChains,
          chainId: dataChainId,
        }),
      action: () => {
        setSelectedToken(curToken);
        navigate(Routers.gift.create(0));
      },
    },
    {
      iconSrc: (
        <TIcon name="tg_wallet_friend" fontSize={iconBaseFontSize} className={iconBaseClass} />
      ),
      title: t("tg_wallet_token.icon_item6"),
      disabled:
        !showItems.includes(MarketTabsEnum.REFER) ||
        noReferChainIds.includes(dataChainId as number) ||
        isPosterCreating,
      action: () => {
        if (disabled) return;
        openTgInvite();
      },
    },
    // {
    //   iconSrc: <SwapHomeSvg />,
    //   title: 'Stake',
    //   action: () => {
    //     if (disabled) return
    //     toStake()
    //   }
    // },
    // {
    //   iconSrc: (
    //     <TIcon
    //       name="tg_wallet_chat"
    //       fontSize={iconBaseFontSize}
    //       className={iconBaseClass}
    //     />
    //   ),
    //   title: MarketTabsEnum.CHAT,
    //   action: () => {
    //     // console.log('set open chat.')
    //     setJoinOpen(true)
    //   }
    // }
  ];
  const { theme } = useTheme();
  // ramp start ---
  // https://qsg07xytt12z.sg.larksuite.com/wiki/AEjSwPubYi4NPTkC8S2lqkASgZd
  // 1. 判断代币是否支持 moonpay
  // 2. 支持展示 buy token
  // 3. 不支持，判断是否是原生代币
  // 4. 是，不展示按钮
  // 5. 不是，判断原生代币是否支持 moonpay
  // 6 不支持，跳转到 receive
  // 7 支持，跳转到 moonpay

  const storageTokenRef = useRef<AssetsToken>();
  try {
    const storageStr = localStorage.getItem(TOKEN_DETAIL_KEY);
    if (storageStr) {
      storageTokenRef.current = JSON.parse(storageStr);
    }
  } catch (error) {
    //
  }
  const symbol = useMemo(() => {
    return storageTokenRef.current?.symbol || token?.symbol;
  }, [token?.symbol]);
  const isNative = useMemo(() => {
    return storageTokenRef.current?.isNative || token?.isNative;
  }, [token?.isNative]);

  const { setOpen: btcAddressSelectOpen, component: btcComponent } = useBTCAddressType2({
    onAddressChange(val) {
      if (val?.type && curToken) {
        val?.type && walletStore.btcWalletTypeActions(val.type);
        rampStore.setMoonpayToken(curToken);
        navigate(Routers.ramp.moonpay());
      }
    },
  });

  const isIos = useMemo(() => {
    return isIOS();
  }, []);
  const isAndr = useMemo(() => {
    return isAndroid();
  }, []);
  const payIcon = useMemo(() => {
    if (theme === "light") {
      return isIos ? moonpayIcon.moonpayApplePayIcon : moonpayIcon.moonpayGooglePayIcon;
    } else {
      return isIos ? moonpayIcon.moonpayApplePayDarkIcon : moonpayIcon.moonpayGooglePayDarkIcon;
    }
  }, [isIos, theme]);

  const onMoonPayFlag = useMemo(() => {
    if (chainId || chainId === 0) {
      const chain = getChainByChainIdV2({
        chains,
        chainId,
      });
      if (symbol && chain?.name) {
        const moonPayCode = transformMoonPayCodeFromSymbol(symbol, chain?.name);
        return moonPaySupportCodes.includes(moonPayCode);
      }
    }
    return false;
  }, [chainId, chains, moonPaySupportCodes, symbol]);

  const nativeToken = useMemo(() => {
    if (isNative) {
      return undefined;
    } else {
      const token = tokenStore.tokenList.find(
        (token) => token.chainId === chainId && token.isNative,
      );
      return token;
    }
  }, [chainId, isNative]);

  const onNativeMoonPayFlag = useMemo(() => {
    if (nativeToken?.symbol && chain?.name) {
      const moonPayCode = transformMoonPayCodeFromSymbol(nativeToken?.symbol, chain?.name);
      return moonPaySupportCodes.includes(moonPayCode);
    }
    return false;
  }, [chain?.name, moonPaySupportCodes, nativeToken?.symbol]);

  const { setOpen: setDepositOpen, component: depositComponent } = usePopup({
    title: t("tg_wallet_token.depositTitle"),
    bodyStyle: {
      backgroundColor: "var(--background-BG4)",
    },
    content: (
      <>
        <div
          className="text-t1 mt-2 flex h-[54px] items-center justify-between rounded-[8px] px-4 text-sm"
          style={{
            backgroundColor: "var(--background-BG2)",
          }}
          onClick={() => {
            if (nativeToken) {
              rampStore.setMoonpayToken(nativeToken);
              navigate(Routers.ramp.moonpay());
            }
          }}
        >
          <div className="flex items-center gap-[2px]">
            <img src={visaSvg} />
            <img src={mastercardSvg} />
            {(isIos || isAndr) && <img className="h-[18px] w-[28px]" src={payIcon} alt="" />}
          </div>
        </div>
        <div
          className="text-t1 mt-2 flex h-[54px] items-center rounded-[8px] px-4 text-base font-medium"
          style={{
            backgroundColor: "var(--background-BG2)",
          }}
          onClick={() => {
            const link = receiveLink({
              // @ts-expect-error any
              chainId,
              // @ts-expect-error any
              address: nativeToken.address,
              hideBuyButton: true,
            });
            navigate(link);
          }}
        >
          <IText value="tg_wallet_token.crypto" />
        </div>
      </>
    ),
  });

  if (showRamp) {
    if (onMoonPayFlag) {
      const title = `${t("tg_wallet_token.buy")} ${symbol?.toUpperCase()}`;
      menus.splice(3, 0, {
        iconSrc: (
          <TIcon
            name="tg_wallet_fiat-currency"
            fontSize={iconBaseFontSize}
            className={iconBaseClass}
          />
        ),
        title,
        disabled: !showItems.includes(MarketTabsEnum.CARD),
        action: () => {
          if (!curToken) return;
          if (onMoonPayFlag) {
            if (chainId === configChains.btc.chain?.id) {
              btcAddressSelectOpen(true);
            } else {
              rampStore.setMoonpayToken(curToken);
              navigate(Routers.ramp.moonpay());
            }
          }
        },
        className: "relative",
      });
    } else if (isNative) {
      // do nothing
    } else if (chainId && nativeToken) {
      const title = `${t("tg_wallet_token.deposit")} ${nativeToken?.symbol?.toUpperCase()}`;
      menus.splice(3, 0, {
        iconSrc: (
          <TIcon
            name="tg_wallet_fiat-currency"
            fontSize={iconBaseFontSize}
            className={iconBaseClass}
          />
        ),
        title,
        disabled: !showItems.includes(MarketTabsEnum.CARD),
        action: () => {
          if (onNativeMoonPayFlag) {
            setDepositOpen(true);
          } else {
            navigate(
              receiveLink({
                chainId,
                address: nativeToken.address,
              }),
            );
          }
        },
        className: "relative",
      });
    }
  }

  // ramp end ---

  const gridColumns = useMemo(() => {
    if (showRamp && (onMoonPayFlag || (chainId && nativeToken))) {
      return 6;
    }
    return 5;
  }, [chainId, nativeToken, onMoonPayFlag, showRamp]);

  if (isLoading) {
    return (
      <div className={classNames("px-[20px] py-10", className)}>
        <Skeleton animated className="h-[65px] rounded-[4px]" />
      </div>
    );
  }

  return (
    <div className={classNames("py-10 bg-bg1", className)}>
      {/* {stakeComponent} */}
      {receiveComponent}
      {sendComponent}
      {/* {chooseSendComponent} */}
      {/* {qrCode} */}
      {setJoinCompoent}
      {btcComponent}
      {depositComponent}
      <Grid columns={gridColumns} gap={0}>
        {menus.map((m) => (
          <Grid.Item key={m.title} className="flex-1">
            <MenuItem
              disabled={m.disabled}
              icon={m.iconSrc}
              title={m.title}
              onClick={() => m?.action()}
              iconClassname={iconClassname}
              className={m.className}
            />
          </Grid.Item>
        ))}
      </Grid>
    </div>
  );
}
