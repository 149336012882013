import { getTomoChains } from "@/api";
import { convertChainsArrayToObject } from "@/hooks/api/useChainConfig";
import { configChainsInfoAtom } from "@/state";
import useChainsStore from "@/stores/chainsStore/hooks/useChainsStore";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { observer } from "mobx-react-lite";
import { PropsWithChildren, useEffect } from "react";
import { WagmiProvider } from "wagmi";
import { evmChainsConfigV2, getViemChainByTomoChain } from "../../stores/chainsStore/utils";
import { default as chains, default as configChains } from "./chains";
import allChain from "./chains/wagmiConfig/allChain";
import btc from "./chains/wagmiConfig/btc";
import cosmos from "./chains/wagmiConfig/cosmos";
import doge from "./chains/wagmiConfig/doge";
import duckChain from "./chains/wagmiConfig/duckChain";
import duckChainTest from "./chains/wagmiConfig/duckChainTest";
import movement from "./chains/wagmiConfig/movement";
import solana from "./chains/wagmiConfig/solana";
import sui from "./chains/wagmiConfig/sui";
import ton from "./chains/wagmiConfig/ton";
import tonTestnet from "./chains/wagmiConfig/tonTestnet";
import tron from "./chains/wagmiConfig/tron";
import { IWeb3ChainType, Web3Type } from "./type";

const Provider = ({ children }: PropsWithChildren) => {
  const [configChainsInfo, setConfigChainsInfo] = useAtom(configChainsInfoAtom);

  const {
    evmChains,
    evmConfig,
    setEvmChains,
    setEvmConfig,
    setAllChains,
    setRenderChains,
    setMarketSelectChains,
    setSupportSwapChains,
    setSupportGiftChains,
    setUnSupportHistoryChains,
    setChains,
  } = useChainsStore();

  useQuery({
    queryKey: ["chains"],
    placeholderData: keepPreviousData,
    refetchInterval: 300_000,
    queryFn: async () => {
      try {
        // 返回所有 evm 链和非 evm 链
        // evm 链数据上存在 rpcUrls 和 blockExplorerUrl 字段，非 evm 链上不存在，使用前端自己配置的
        // 所有链数据上都有对 gift、swap、history 是否支持的字段
        const res = await getTomoChains();
        localStorage.setItem("TOMO_CHAIN", JSON.stringify(res || []));
        const configChainsInfo = convertChainsArrayToObject(res);
        setConfigChainsInfo({ chain_id_name: configChainsInfo });
        const viemChains = getViemChainByTomoChain({ tomoChains: res });

        const evmChainsApi = viemChains.map((viemChain) => {
          //@ts-expect-error: ignore
          return {
            chain: viemChain,
            id: viemChain.id,
            type: Web3Type.EVM,
            icon: viemChain.tomoEvmChain.icon,
            name: viemChain.name,
            networkType: viemChain.tomoEvmChain.isTestnet ? "test" : "main",
          } as IWeb3ChainType;
        });

        if (evmChainsApi.length) {
          if (JSON.stringify(evmChainsApi) !== JSON.stringify(evmChains)) {
            setEvmChains(evmChainsApi);
            const config = evmChainsConfigV2(evmChainsApi);
            setEvmConfig(config);
            const renderChains = [
              btc,
              solana,
              ton,
              tron,
              sui,
              cosmos,
              doge,
              movement,
              ...evmChainsApi,
              tonTestnet,
            ].map((chain) => {
              if (chain.type !== "EVM") {
                const tomoChainFromServer = res.find((v) => v.chainId === chain.id);
                if (tomoChainFromServer && chain.chain) {
                  chain.chain.tomoNonEvmChain = tomoChainFromServer;
                }
              }
              return chain;
            });
            setRenderChains(renderChains);
            // remove ton test
            const allChains = [allChain, ...renderChains].filter(
              (chain) => chain.type !== Web3Type.TONTEST,
            );
            setAllChains(allChains);
            setChains(allChains);
            setMarketSelectChains([
              allChain,
              ...renderChains.filter(
                (chain) => chain.id !== duckChainTest.id && chain.id !== duckChain.id,
              ),
            ]);
            const notSupportSwapChains = [
              ...allChains.filter(
                (chain) =>
                  chain?.chain?.tomoEvmChain?.supportSwap === false &&
                  chain.id !== configChains.berachain_main.id,
              ),
              chains.tonTestnet,
              chains.mevmTestnet,
              chains.doge,
              chains.movement,
            ];

            const supportSwapChains = [
              ...allChains.filter((chain) => {
                return (
                  chain &&
                  chain?.type !== "ALL" &&
                  typeof chain?.chain?.id === "number" &&
                  !notSupportSwapChains.find(
                    (notSupportChain) => chain?.id === notSupportChain.id,
                  ) &&
                  (chain?.type === "EVM" ||
                    chain.type === "SOL" ||
                    chain.type === "TRON" ||
                    chain.type === "SUI" ||
                    chain.type === "BTC" ||
                    chain.type === "TON")
                );
              }),
            ];

            setSupportSwapChains(supportSwapChains);

            const unSupportHistoryChains = [
              ...allChains.filter((chain) => chain?.chain?.tomoEvmChain?.supportHistory === false),
              chains.bscTestnet,
              chains.ton,
              chains.movement,
            ];

            setUnSupportHistoryChains(unSupportHistoryChains);

            const SupportGiftChains = [
              ...allChains.filter(
                (chain) =>
                  chain?.chain?.tomoEvmChain?.supportGift ||
                  chain?.chain?.tomoNonEvmChain?.supportGift,
              ),
            ];

            setSupportGiftChains(SupportGiftChains);

            // setOkxChains(renderChains)
            // setChains()
          }
        }
        // return viemChains
        return evmChainsApi;
      } catch (error) {
        return null;
      }
    },
  });
  // evmConfig
  // evmChains
  // setEvmConfig
  // setEvmChains

  useEffect(() => {
    console.log({
      evmChains,
      evmConfig,
    });
  }, [evmChains, evmConfig]);

  return evmConfig && <WagmiProvider config={evmConfig}>{children}</WagmiProvider>;
};

export default observer(Provider);
