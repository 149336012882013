import { CountryListItem, CurrencyItem, QuoteSide } from '@/api/ramp/type'
import { NoResult } from '@/components/NoResult'
import { onRampChain } from '@/proviers/web3Provider/chains'
import rampStore from '@/stores/rampStore'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCountryIcon } from './utils'
import { TSearch, TNoResult } from '@/components/tmd'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import { getTomoChainByChainId } from '@/stores/tokenStore/util'
import { t } from 'i18next'

export const SelectCurrency = observer(() => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const selectedToken = rampStore.selectedToken
  const type = rampStore.selectedTab
  const currencyList = rampStore.buyCurrencyList
  const [filterCurrency, setFilterCurrency] =
    useState<CountryListItem[]>(currencyList)

  const [offset, setOffset] = useState({
    direct: 70,
    flag: false
  })
  useEffect(() => {
    if (!scrollRef || !scrollRef.current) return
    const scrollHandle = (e: any) => {
      const offsetScroll = e.target.scrollTop
      if (offsetScroll <= 70) {
        setOffset((v) => ({
          direct: 70,
          flag: false
        }))
      } else {
        setOffset((v) => ({
          direct: offsetScroll,
          flag: v.direct < offsetScroll
        }))
      }
    }
    scrollRef.current.addEventListener('scroll', scrollHandle)
    return () => {
      scrollRef.current?.removeEventListener('scroll', scrollHandle)
    }
  }, [scrollRef])

  const CurrencyItemDom = (currency: CountryListItem, index: number) => {
    const FlagIcon = getCountryIcon(currency.country)
    return (
      <div
        key={`currency_${index}`}
        className="flex items-center gap-2 py-4"
        onClick={() => {
          const cryptoDetail = rampStore.supportCryptoList.find(
            (item) =>
              item.symbol === selectedToken.symbol &&
              item.network ===
                onRampChain[rampStore.selectedToken?.chainId].chain &&
              item.buy
          )
          if (type === QuoteSide.BUY) {
            rampStore.setBuySelectedCurrency({
              ...currency,
              cryptoDetail
            })
          } else {
            // rampStore.setSellSelectedCurrency({
            //   ...currency,
            //   cryptoDetail
            // })
          }
          navigate(-1)
        }}
      >
        <div className="flag-icon size-9">
          <FlagIcon />
        </div>

        <div className="flex flex-col gap-[2px]">
          <div className="text-base font-medium text-t1">
            {currency.countryName}
          </div>
          <div className=" text-xs text-t2">{currency.symbol}</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <BackButton onClick={() => navigate(-1)} />
      <div className="flex h-full flex-col">
        <div
          className="z-10 mx-[20px] mt-4"
          style={{
            opacity: offset.flag ? 0 : 1,
            pointerEvents: offset.flag ? 'none' : 'auto'
          }}
        >
          <TSearch
            type="change"
            onChange={(e) => {
              setFilterCurrency(
                currencyList.filter(
                  (item: CountryListItem) =>
                    item.symbol.toLowerCase().includes(e.toLowerCase()) ||
                    item.countryName.toLowerCase().includes(e.toLowerCase())
                )
              )
            }}
            placeholder={t('tg_wallet_ramp_currency.currency_placeholder')}
          />
        </div>
        <div
          className="no-scrollbar mt-[-73px] flex-1 overflow-scroll"
          ref={scrollRef}
        >
          <div className="mx-6 mt-[85px] h-full">
            {filterCurrency && filterCurrency.length > 0 ? (
              filterCurrency.map(CurrencyItemDom)
            ) : (
              <div className="-mt-[68px] h-full">
                <TNoResult
                  className=" text-sm text-t3"
                  emptyText={t('tg_wallet_ramp_currency.currency_no_result')}
                  type="list"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
})
