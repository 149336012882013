import {
  RampInstantSDK,
  RampInstantEventTypes
} from '@ramp-network/ramp-instant-sdk'
import useLoginInfo from './useLoginInfo'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import { getChainByToken } from '@/stores/walletStore/utils'
import useChainsStore from '@/stores/chainsStore/hooks/useChainsStore'

const isDev = false

const useOTC = (config?: { onPurchaseCreated?: (event: any) => void }) => {
  const { getRenderAddress, user } = useLoginInfo()
  const { chains } = useChainsStore()

  const showRamp = (token: AssetsToken) => {
    const hostApiKey = isDev
      ? '6jet666hbakx3zvq75s73ds9xtfg7tyyckyze7ym'
      : 'zv6q5pv56u6tebstsrbcnbtxeb7twpz84jdxncwx'
    const hostAppName = 'tomo'
    const hostLogoUrl = 'https://pub.tomo.inc/TomoLogo.png'

    const userAddress = getRenderAddress(getChainByToken(token, chains))
    const RampModal = new RampInstantSDK({
      containerNode: document.getElementById('root') as HTMLDivElement,
      hostApiKey,
      hostAppName,
      hostLogoUrl,
      userAddress: userAddress,
      variant: 'auto',
      enabledFlows: ['ONRAMP'],
      swapAsset: token.whiteToken?.ramp_support || '',
      defaultAsset: token.whiteToken?.ramp_support || ''
      // swapAmount: '150000000000000000000', // 150 ETH in wei
    })

    RampModal.on('*', (event) => console.log('RampModal event', event))

    RampModal.on(RampInstantEventTypes.PURCHASE_CREATED, (event) => {
      console.log('RampModal PURCHASE_CREATED', event)
      config?.onPurchaseCreated?.(event)
    })
    // RampModal.on(RampInstantEventTypes.WIDGET_CLOSE, (event) => {
    //   console.log('RampModal WIDGET_CLOSE', event)
    //   config?.onPurchaseCreated?.(event)
    // })
    RampModal.show()
  }

  return {
    showRamp
  }
}

export default useOTC
