import { errorLightSvg, errorDarkSvg } from "assets";
import usePopup from "hooks/usePopup";
import { useState } from "react";
import * as Sentry from "@sentry/react";
import { errorHandler } from "@/utils/sentry/logger/errorHandler";
import { ErrorLevel } from "@/utils/sentry/const";
import isError from "@/utils/sentry/logger/isError";
import useTheme from "@/stores/userStore/hooks/useTheme";

type ErrorBoundaryProps = {
  error: unknown;
  resetError: () => void;
};

type TState = () => void;

export const beforeCapture = (scope: Sentry.Scope, error: unknown) => {
  if (isError(error)) {
    const detail = errorHandler(ErrorLevel.fatal, "Page", "page crash err", error);
    const { reason, level, context } = detail;
    const err = detail.error;
    const reasonStr = reason ? ` (${reason})` : "";
    err.message = `[Page] - page crash err${reasonStr}`;
    scope.update({
      level,
      ...context,
    });
  }
};

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const [resetFn, setResetFn] = useState<TState>();
  const { theme } = useTheme();
  const { setOpen, component } = usePopup({
    title: " ",
    fullscreen: true,
    showCloseButton: true,
    onClose: () => resetFn?.(),
    content: (
      <>
        <div className="flex flex-col items-center justify-center px-[20px] text-center">
          <img src={theme == "light" ? errorLightSvg : errorDarkSvg} alt="" />
          <div className="text-t3 mt-4">No search result</div>
          {/* <div
            style={{
              color: '#666'
            }}
            className="mt-[10px] flex text-center"
          >
            Your network is experiencing some problems, please check and try
            refreshing
          </div>
          <button
            onClick={() => resetFn?.()}
            className="mt-[24px] h-[48px] w-[240px] rounded-[8px] bg-red px-[16px] py-[12px] text-xl font-[500] text-white"
          >
            Refresh
          </button> */}
        </div>
      </>
    ),
  });

  const ErrorFallback = ({ error, resetError }: ErrorBoundaryProps) => {
    if (error) {
      setOpen(true);
      setResetFn(() => resetError);
    } else {
      setOpen(false);
    }
    return component;
  };

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} beforeCapture={beforeCapture}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
