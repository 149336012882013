import { loggerT } from '@/utils/sentry/logger'
import isError from '@/utils/sentry/logger/isError'

const giftTradeSentry = (
  type: string,
  params: Record<string, any>,
  err: unknown
) => {
  if (isError(err)) {
    const extra = {
      type,
      params,
      error: JSON.stringify(err)
    }
    loggerT.fatal('Gift', `${type} err`, err, { extra })
  }
}

export default giftTradeSentry
