import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'

const icon = chainsSvgs.movement
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    id: 126,
    name: 'Movement Mainnet',
    nativeCurrency: { decimals: 8, name: 'MOVE', symbol: 'MOVE' }
  },
  id: 126,
  type: 'APTOS',
  name: 'Movement Mainnet',
  icon: icon,
  networkType
}

export default chainInfo
