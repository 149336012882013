import { IWeb3ChainType } from '@/proviers/web3Provider/type'
import { Config } from '@wagmi/core'
import { observable, action, computed, autorun } from 'mobx'

type IChainsStore = {
  evmConfig: Config<any, any> | undefined
  evmChains: IWeb3ChainType[] | undefined
  renderChains: IWeb3ChainType[] | undefined
  allChains: IWeb3ChainType[] | undefined
  allChainsAction: (info: IWeb3ChainType[] | undefined) => void
  okxChains: IWeb3ChainType[] | undefined
  okxChainsAction: (info: IWeb3ChainType[] | undefined) => void
  chains: IWeb3ChainType[] | undefined
  chainsAction: (info: IWeb3ChainType[] | undefined) => void
  marketSelectChains: IWeb3ChainType[] | undefined
  marketSelectChainsAction: (info: IWeb3ChainType[] | undefined) => void
  evmChainsAction: (info: IWeb3ChainType[] | undefined) => void
  renderChainsAction: (info: IWeb3ChainType[] | undefined) => void
  evmConfigAction: (info: Config<any, any> | undefined) => void
  chainIds: number[]
  supportSwapChains: IWeb3ChainType[] | undefined
  supportSwapChainsAction: (info: IWeb3ChainType[] | undefined) => void
  supportGiftChains: IWeb3ChainType[] | undefined
  supportGiftChainsAction: (info: IWeb3ChainType[] | undefined) => void
  unSupportHistoryChains: IWeb3ChainType[] | undefined
  unSupportHistoryChainsAction: (info: IWeb3ChainType[] | undefined) => void
}

const chainsStore: IChainsStore = observable({
  evmConfig: undefined,
  evmConfigAction: action((info: IChainsStore['evmConfig']) => {
    chainsStore.evmConfig = info
  }),
  evmChains: undefined,
  renderChains: undefined,
  evmChainsAction: action((info: IChainsStore['evmChains']) => {
    chainsStore.evmChains = info
  }),
  allChains: undefined,
  allChainsAction: action((info: IChainsStore['allChains']) => {
    chainsStore.allChains = info
  }),
  okxChains: undefined,
  okxChainsAction: action((info: IChainsStore['okxChains']) => {
    chainsStore.okxChains = info
  }),
  chains: undefined,
  chainsAction: action((info: IChainsStore['chains']) => {
    chainsStore.okxChains = info
  }),
  marketSelectChains: undefined,
  marketSelectChainsAction: action(
    (info: IChainsStore['marketSelectChains']) => {
      chainsStore.okxChains = info
    }
  ),
  renderChainsAction: action((info: IChainsStore['renderChains']) => {
    chainsStore.renderChains = info
  }),
  get chainIds() {
    return computed(() => {
      if (chainsStore.allChains) {
        return chainsStore.allChains
          .map((chain) => chain.id)
          .filter((item) => typeof item === 'number')
      }
      return []
    }).get()
  },
  supportSwapChainsAction: action((info: IChainsStore['renderChains']) => {
    chainsStore.supportSwapChains = info
  }),
  supportSwapChains: undefined,
  supportGiftChainsAction: action((info: IChainsStore['renderChains']) => {
    chainsStore.supportGiftChains = info
  }),
  supportGiftChains: undefined,
  unSupportHistoryChainsAction: action((info: IChainsStore['renderChains']) => {
    chainsStore.unSupportHistoryChains = info
  }),
  unSupportHistoryChains: undefined
})

export default chainsStore

autorun(() => {
  console.log({
    key: 'chainsStore',
    chainIds: chainsStore.chainIds,
    supportGiftChains: chainsStore.supportGiftChains?.map(
      (value) => value.name
    ),
    unSupportHistoryChains: chainsStore.unSupportHistoryChains?.map(
      (value) => value.name
    )
  })
})
