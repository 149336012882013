import { useEffect, useState } from "react";
import tokenStore from "..";
import { reaction } from "mobx";

const useTokenStore = () => {
  const [stableTokens, setStableTokens] = useState(() => tokenStore.stableTokens);
  const [tokenList, setTokenList] = useState(() => tokenStore.tokenList);
  const [isLoading, setIsLoading] = useState(() => tokenStore.isLoading);

  useEffect(() => {
    const stableTokensReaction = reaction(
      () => tokenStore.stableTokens,
      (v) => setStableTokens(v),
    );
    const tokenListReaction = reaction(
      () => tokenStore.tokenList,
      (v) => setTokenList(v),
    );
    const loadingReaction = reaction(
      () => tokenStore.isLoading,
      (v) => setIsLoading(v),
    );

    return () => {
      stableTokensReaction();
      tokenListReaction();
      loadingReaction();
    };
  }, []);

  return {
    stableTokens,
    tokenList,
    isLoading,
  };
};

export default useTokenStore;
