import { TSkeleton as Skeleton } from '@/components/tmd'

const SkeletonNormal = () => {
  return (
    <div className="px-5">
      <Skeleton className="mt-5" style={{ '--height': '145px' }} />

      {Array.from({ length: 9 }, (_, index) => index).map((index) => (
        <Skeleton className="mt-4" key={index} style={{ '--height': '44px' }} />
      ))}
    </div>
  )
}

export default SkeletonNormal
