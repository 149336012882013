const beraChainTokens: any[] = [
  {
    name: "BERA",
    displayName: "BERA Token",
    symbol: "BERA",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: true,
    address: "",
  },
  {
    name: "berachain_usdc.e",
    displayName: "USDC.e",
    symbol: "USDC.e",
    decimals: 6,
    chain: "BERACHAIN",
    isNative: false,
    address: "0x549943e04f40284185054145c6E4e9568C1D3241",
  },
  // {
  //   name: 'berachain_usde',
  //   displayName: 'USDe',
  //   symbol: 'USDe',
  //   decimals: 18,
  //   chain: 'BERACHAIN',
  //   isNative: false,
  //   address: '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34'
  // },
  {
    name: "berachain_weeth",
    displayName: "weETH",
    symbol: "weETH",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0x7DCC39B4d1C53CB31e1aBc0e358b43987FEF80f7",
  },
  // {
  //   name: 'berachain_lbtc',
  //   displayName: 'LBTC',
  //   symbol: 'LBTC',
  //   decimals: 8,
  //   chain: 'BERACHAIN',
  //   isNative: false,
  //   address: '0xecAc9C5F704e954931349Da37F60E39f515c11c1'
  // },
  {
    name: "berachain_rseth",
    displayName: "rsETH",
    symbol: "rsETH",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0x4186BFC76E2E237523CBC30FD220FE055156b41F",
  },
  {
    name: "berachain_solvbtc.bbn",
    displayName: "SolvBTC.BBN",
    symbol: "SolvBTC.BBN",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0xCC0966D8418d412c599A6421b760a847eB169A8c",
  },
  {
    name: "berachain_pumpbtc.bera",
    displayName: "pumpBTC.bera",
    symbol: "pumpBTC.bera",
    decimals: 8,
    chain: "BERACHAIN",
    isNative: false,
    address: "0x1fCca65fb6Ae3b2758b9b2B394CB227eAE404e1E",
  },
  // {
  //   name: 'berachain_pendle',
  //   displayName: 'PENDLE',
  //   symbol: 'PENDLE',
  //   decimals: 18,
  //   chain: 'BERACHAIN',
  //   isNative: false,
  //   address: '0xFf9c599D51C407A45D631c6e89cB047Efb88AeF6'
  // },
  {
    name: "berachain_usda",
    displayName: "USDa",
    symbol: "USDa",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0xff12470a969Dd362EB6595FFB44C82c959Fe9ACc",
  },
  {
    name: "berachain_stbtc",
    displayName: "stBTC",
    symbol: "stBTC",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0xf6718b2701D4a6498eF77D7c152b2137Ab28b8A3",
  },
  {
    name: "berachain_fbtc",
    displayName: "FBTC",
    symbol: "FBTC",
    decimals: 8,
    chain: "BERACHAIN",
    isNative: false,
    address: "0xbAC93A69c62a1518136FF840B788Ba715cbDfE2B",
  },
  {
    name: "berachain_mim",
    displayName: "MIM",
    symbol: "MIM",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0x5B82028cfc477C4E7ddA7FF33d59A23FA7Be002a",
  },
  {
    name: "berachain_susda",
    displayName: "sUSDa",
    symbol: "sUSDa",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0x2840F9d9f96321435Ab0f977E7FDBf32EA8b304f",
  },
  {
    name: "berachain_rusd",
    displayName: "rUSD",
    symbol: "rUSD",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0x09D4214C03D01F49544C0448DBE3A27f768F2b34",
  },
  {
    name: "berachain_unibtc",
    displayName: "uniBTC",
    symbol: "uniBTC",
    decimals: 8,
    chain: "BERACHAIN",
    isNative: false,
    address: "0xC3827A4BC8224ee2D116637023b124CED6db6e90",
  },
  {
    name: "berachain_stone",
    displayName: "STONE",
    symbol: "STONE",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0xEc901DA9c68E90798BbBb74c11406A32A70652C3",
  },
  {
    name: "berachain_rsweth",
    displayName: "rswETH",
    symbol: "rswETH",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0x850CDF416668210ED0c36bfFF5d21921C7adA3b8",
  },
  {
    name: "berachain_susde",
    displayName: "sUSDe",
    symbol: "sUSDe",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0x211Cc4DD073734dA055fbF44a2b4667d5E5fE5d2",
  },
  {
    name: "berachain_solvbtc",
    displayName: "SolvBTC",
    symbol: "SolvBTC",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0x541FD749419CA806a8bc7da8ac23D346f2dF8B77",
  },
  {
    name: "berachain_hold",
    displayName: "HOLD",
    symbol: "HOLD",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0xFF0a636Dfc44Bb0129b631cDd38D21B613290c98",
  },
  {
    name: "berachain_honey",
    displayName: "HONEY",
    symbol: "HONEY",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0xFCBD14DC51f0A4d49d5E53C2E0950e0bC26d0Dce",
  },
  {
    name: "berachain_ohm",
    displayName: "OHM",
    symbol: "OHM",
    decimals: 9,
    chain: "BERACHAIN",
    isNative: false,
    address: "0x18878Df23e2a36f81e820e4b47b4A40576D3159C",
  },
  {
    name: "berachain_wbera",
    displayName: "WBERA",
    symbol: "WBERA",
    decimals: 18,
    chain: "BERACHAIN",
    isNative: false,
    address: "0x6969696969696969696969696969696969696969",
  },
];

export default beraChainTokens;
