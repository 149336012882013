export const supportMoonPayAssets = [
  '1inch_bsc',
  '1inch_eth',
  'aave',
  'ada',
  'algo',
  'ape',
  'apt',
  'arb_arb',
  'arkm_eth',
  'atom',
  'avax_cchain',
  'axs',
  'bat',
  'bch',
  'blur_eth',
  'bnb_bsc',
  'bonk_sol',
  'btc',
  'btt_bsc',
  'bttc',
  'cati_ton',
  'celo',
  'chz',
  'comp',
  'core',
  'crv_eth',
  'crv_pol',
  'cusd',
  'dai',
  'dai_zksync',
  'degen_base',
  'dgb',
  'doge',
  'dogs_ton',
  'dot',
  'dydx_dydx',
  'egld',
  'eigen_eth',
  'ena_eth',
  'ens',
  'eos',
  'etc',
  'eth',
  'eth_arbitrum',
  'eth_base',
  'eth_optimism',
  'eth_polygon',
  'eth_zksync',
  'fet_eth',
  'fil',
  'floki',
  'flow',
  'flow_flow_evm',
  'gmt_bsc',
  'gmt_eth',
  'gmt_polygon',
  'gmt_sol',
  'gods_immutable',
  'grt',
  'grt_polygon',
  'hbar',
  'hmstr_ton',
  'icp_icp',
  'imx',
  'imx_immutable',
  'inj_inj',
  'jasmy_eth',
  'jto_sol',
  'jup_sol',
  'ldo_eth',
  'link',
  'looks',
  'lpt_eth',
  'lsk',
  'ltc',
  'magic_arbitrum',
  'mana',
  'me_sol',
  'meme_eth',
  'mew_sol',
  'mina_mina',
  'miota',
  'mkr',
  'moodeng_sol',
  'near',
  'neiro_eth',
  'not_ton',
  'okb',
  'okt',
  'om',
  'ondo_eth',
  'osmo',
  'pengu_sol',
  'pepe',
  'pixel',
  'pixel_ron',
  'pnut_sol',
  'pol',
  'pol_polygon',
  'portal',
  'pyth',
  'pyusd',
  'pyusd_sol',
  'qnt_eth',
  'qtum',
  'ray_sol',
  'render_sol',
  'rlusd',
  'rlusd_xrp',
  'ronin',
  'rune',
  'sand',
  'sand_polygon',
  'sei_sei',
  'sfp_bsc',
  'shib',
  'slp',
  'slp_ronin',
  'snx',
  'sol',
  'steth',
  'stmx',
  'strk_eth',
  'strk_strk',
  'stx',
  'sui',
  'sweat_near',
  'theta_theta',
  'tia_tia',
  'ton',
  'trb_eth',
  'trump_sol',
  'trx',
  'tusd',
  'uni',
  'usdc',
  'usdc_arbitrum',
  'usdc_base',
  'usdc_cchain',
  'usdc_celo',
  'usdc_noble',
  'usdc_optimism',
  'usdc_polygon',
  'usdc_ronin',
  'usdc_sol',
  'usdc_xlm',
  'usdc_zksync',
  'usdt',
  'usdt_arbitrum',
  'usdt_bsc',
  'usdt_celo',
  'usdt_optimism',
  'usdt_polygon',
  'usdt_sol',
  'usdt_ton',
  'usdt_trx',
  'usdt_zksync',
  'utk',
  'venom_venom',
  'vet',
  'voxel_polygon',
  'waxp',
  'wbtc',
  'weth',
  'wif_sol',
  'wld',
  'wld_optimism',
  'wld_wld',
  'xlm',
  'xno',
  'xrp',
  'xtz',
  'zil',
  'zrx'
]
