import { TSkeleton as Skeleton } from '@/components/tmd'

const SkeletonFavorite = () => {
  return (
    <>
      {Array.from({ length: 7 }, (_, index) => index).map((index) => (
        <div key={index} className="py-3">
          <Skeleton key={index} style={{ '--height': '50px' }} />
        </div>
      ))}
    </>
  )
}

const SkeletonRecommand = () => {
  return (
    <>
      <Skeleton className="mt-4" style={{ '--height': '48px' }} />

      <div className="mt-5 grid grid-cols-2 gap-2">
        {Array.from({ length: 6 }, (_, index) => index).map((index) => (
          <Skeleton key={index} style={{ '--height': '60px' }} />
        ))}
      </div>

      <Skeleton className="mt-10" style={{ '--height': '52px' }} />
    </>
  )
}

const SkeletonMarket = (props: { favorite?: boolean }) => {
  const { favorite } = props
  return (
    <div className="px-5">
      <Skeleton className="my-2" style={{ '--height': '46px' }} />
      <div className="pb-3 pt-2">
        <Skeleton style={{ '--height': '24px' }} />
      </div>
      <div className="py-2.5">
        <Skeleton style={{ '--height': '18px' }} />
      </div>
      {favorite ? <SkeletonFavorite /> : <SkeletonRecommand />}
    </div>
  )
}

export default SkeletonMarket
