import { addChainIdToApiReq } from '@/stores/tokenStore/util'
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import {
  favouriteTokens,
  getAllTokens,
  getBinanceAlphaTokens,
  getFavouriteTokens,
  getFourMemeMarketList,
  getFourMemeTransactions,
  getMemesOverview,
  getSelectedTokens,
  getTokenByName,
  getTokenPrices,
  getTokenSearch,
  getTrendingTokens,
  IntervalType
} from 'api'
import { formatUnits } from 'viem'

// --------------------------------------------------------
export const useTrendingTokens = (params?: { chain?: string }) => {
  return useQuery({
    // staleTime: 30_000,
    refetchInterval: 30_000,
    queryKey: ['market-trending-tokens', params],
    queryFn: async () => {
      const data = await getTrendingTokens(params)
      return addChainIdToApiReq(data)
    },
    placeholderData: keepPreviousData
  })
}

// --------------------------------------------------------
export const useSelectedTokens = () => {
  return useQuery({
    staleTime: 300_000,
    queryKey: ['market-selected-tokens'],
    queryFn: async () => {
      const data = await getSelectedTokens()
      return addChainIdToApiReq(data)
    }
  })
}

// --------------------------------------------------------
export const useTokens = () => {
  return useQuery({
    staleTime: 60_000,
    queryKey: ['market-all-tokens'],
    queryFn: getAllTokens
  })
}

// --------------------------------------------------------
export const useTokenByName = (tokenName: string, refetchInterval = 0) => {
  return useQuery({
    enabled: !!tokenName?.trim(),
    staleTime: 30_000,
    queryKey: ['use-token-by-name', tokenName],
    refetchInterval,
    queryFn: () => getTokenByName(tokenName)
  })
}

// --------------------------------------------------------
export const useTokenSearch = (content: string, chain: string) => {
  return useQuery({
    staleTime: 30_000,
    queryKey: ['use-token-search', content, chain],
    // queryFn: () => getTokenSearch(content, chain)
    queryFn: async () => {
      const res = await getTokenSearch(content, chain)
      return addChainIdToApiReq(res)
    }
  })
}

// --------------------------------------------------------
export const useTokenPrices = (
  tokenName: string,
  interval: IntervalType,
  isTestnet: boolean,
  refetchInterval: number = 0
) => {
  return useQuery({
    staleTime: 10_000,
    queryKey: ['use-token-prices', tokenName, interval],
    queryFn: () => {
      if (isTestnet) {
        return null
      }
      return getTokenPrices(tokenName, interval)
    }
  })
}

// --------------------------------------------------------
export const useMemesOverview = (chain: string, address: string) => {
  return useQuery({
    enabled: !!chain,
    staleTime: 30_000,
    queryKey: ['use-memes-overview', chain, address],
    queryFn: () => getMemesOverview(chain, address)
  })
}

// --------------------------------------------------------

export const useGetFavouriteTokens = (props?: {
  refetchInterval: number | false
}) => {
  const refetchInterval = props?.refetchInterval ?? 20_000
  return useQuery({
    // staleTime: 3000,
    refetchInterval,
    queryKey: ['use-get-favourite-tokens'],
    queryFn: async () => {
      const data = await getFavouriteTokens()
      return addChainIdToApiReq(data)
    }
  })
}

export const useGetBinanceAlphaTokens = () => {
  return useQuery({
    // staleTime: 3000,
    refetchInterval: 20_000,
    queryKey: ['use-get-binance-alpha-tokens'],
    queryFn: async () => {
      const data = await getBinanceAlphaTokens()
      return addChainIdToApiReq(data)
    }
  })
}

export const useFourMemeMarketList = (params: {
  status: 'new' | 'early' | 'published'
  launchOnPancake: boolean
  orderByField?: string
  orderByRule?: 'ASC' | 'DESC'
}) => {
  return useQuery({
    refetchInterval: 20_000,
    queryKey: [
      'use-get-binance-alpha-tokens',
      params.status,
      params.launchOnPancake,
      params.orderByField,
      params.orderByRule
    ],
    queryFn: async () => {
      try {
        const data = await getFourMemeMarketList(params)
        const currentData = data.map((item) => ({
          ...item,
          chain: 'BSC',
          name: `${
            item.fourMemeToken && !item.launchOnPancake ? 'FOURMEME' : 'BSC'
          }-${item.tokenAddress}`,
          volumeH24: item.volumeWeiH24
            ? Number(
                formatUnits(BigInt(item.volumeWeiH24), item.decimals || 18)
              ) * Number(item.priceUsd)
            : 0
        }))
        return addChainIdToApiReq(currentData)
      } catch (error) {
        return []
      }
    },
    enabled: Boolean(params.status && params.launchOnPancake !== undefined)
  })
}

export const useFourMemeTransactions = (address: string) => {
  return useQuery({
    refetchInterval: 20_000,
    queryKey: ['use-four-meme-transactions', address],
    queryFn: () => getFourMemeTransactions(address)
  })
}

export const useFavouriteTokens = () => {
  return useMutation({
    mutationKey: ['use-favourite-tokens'],
    mutationFn: favouriteTokens
  })
}
