import { getUrlSwapParams, getUrlSwapTokenParams } from '@/utils/helper'
import { fromHex } from '@/utils/string'
import { useInitData } from '@vkruglikov/react-telegram-web-app'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Routers } from 'router'
import { zeroAddress } from 'viem'

export enum ActionType {
  GIFT = 'GIFT',
  SEND = 'SEND'
}
export const useStartApp = () => {
  const [initData] = useInitData()
  const navigate = useNavigate()

  useEffect(() => {
    const isInit = sessionStorage.getItem('tg_started')
    if (initData?.start_param && !isInit) {
      // const params = fromHex(initData?.start_param)

      // if (params.path) {
      //   navigate(params.path)
      // }
      const params = initData?.start_param.split('_')
      if (params[0] === 'GIFT') {
        navigate(Routers.gift.claim(params[1]))
      }
      if (params[0] === 'SEND') {
        const [_, address, chainId, toAddress, btcAdrType] = params
        navigate(
          Routers.send.inputAddress(
            `address=${address}&chainId=${chainId}&toAddress=${toAddress}&btcAdrType=${btcAdrType}`
          )
        )
      }

      if (params[0] === 'SWAP') {
        // navigate(`${Routers.swap}?${fromHex(params[1])}`)
        navigate(`${Routers.swap}${getUrlSwapParams(initData?.start_param)}`)
      }
      if (params[0] === 'SWAPTOKEN') {
        navigate(
          `${Routers.swap}${getUrlSwapTokenParams(initData?.start_param)}`
        )
      }
      if (params[0] === 'RAMP') {
        navigate(Routers.ramp.paymentStatus(params[1]))
      }
      if (params[0] === 'HISTORY') {
        navigate(Routers.history.detail(params[1]))
      }
      if (params[0] === 'RECEIVE') {
        navigate(
          Routers.token.receive(Number(params[1]), params[2] || zeroAddress)
        )
      }
      sessionStorage.setItem('tg_started', 'true')
    }
  }, [initData, navigate])
}