import { logout } from '@/utils'
import { InitDataUnsafe } from '@vkruglikov/react-telegram-web-app'
import { action, observable } from 'mobx'
import {
  biometryInfoType,
  SwapFeeAddress,
  UserState,
  UserTotp,
  UserType
} from './type'
import { initBiometry, initUserInfo, initUserState } from './utils'

export interface IUserStore {
  tgData: {
    data: InitDataUnsafe | undefined
    query: string | undefined
  }
  userState: UserState
  biometry: biometryInfoType
  pageRoute: string
  loginState: number
  userInfoRefresh: number
  userInfo: UserType
  swapFeeAddress: SwapFeeAddress
  totpInfo: UserTotp | null
  autoLogin: boolean
  loginAction: () => void
  updateUserStateAction: (userState: UserState) => void
  updateTgAction: (data: InitDataUnsafe, query: string) => void
  updateBiometryAction: (bi: biometryInfoType) => void
  updateUserInfoAction: (info: UserType) => void
  fetchUserInfoAction: () => void
  updateRouteAction: (page: string) => void
  updateSwapFeeAddress: (addr: SwapFeeAddress) => void
  updateAutoLoginAction: () => void
  updateUserTotp: (totoInfo: UserTotp) => void
  clearUserAction: () => void
}

const userStore: IUserStore = observable({
  tgData: {
    data: undefined,
    query: undefined
  },
  userState: initUserState(),
  biometry: initBiometry(),
  loginState: 0,
  pageRoute: '',
  userInfoRefresh: 0,
  userInfo: initUserInfo(),
  swapFeeAddress: {
    ethereumAddress: '',
    solanaAddress: '',
    feeRate: 0.5,
    tonAddress: '',
    tronAddress: ''
  },
  totpInfo: null,
  autoLogin: false,
  loginAction: action(() => {
    userStore.loginState = new Date().getTime()
  }),
  updateUserStateAction: action((userState: UserState) => {
    userStore.userState = {
      ...userStore.userState,
      ...userState
    }
    localStorage.setItem('userState', JSON.stringify(userState))
  }),
  updateTgAction: action((data: InitDataUnsafe, query: string) => {
    userStore.tgData = { data, query }
  }),
  updateBiometryAction: action((bi: biometryInfoType) => {
    userStore.biometry = bi
  }),
  updateUserInfoAction: action((info: UserType) => {
    userStore.userInfo = info
    localStorage.setItem('user', JSON.stringify(info))
  }),
  fetchUserInfoAction: action(() => {
    userStore.userInfoRefresh = new Date().getTime()
  }),
  updateRouteAction: action((page: string) => {
    userStore.pageRoute = page
  }),
  updateSwapFeeAddress: action((addr: SwapFeeAddress) => {
    userStore.swapFeeAddress = addr
  }),
  updateAutoLoginAction: action(() => {
    userStore.autoLogin = true
  }),
  updateUserTotp: action((totpInfo: UserTotp) => {
    userStore.totpInfo = totpInfo
  }),
  clearUserAction: action(() => {
    logout()
  })
})

export default userStore
