import {
  getBalances,
  getChainGasInfo,
  getChainInfos,
  getMevDetail,
  getTransactionHash,
  pollForMethods,
  sendTransaction,
} from "@/api/chain";
import {
  ApiParams,
  CenterSubmitParams,
  CenterSubmitResult,
  ChainGasChainInfo,
  ChainGasParams,
  ChainGasResult,
  MEVInfoType,
  TomoTxStatus,
} from "@/api/type";
import { FeeMode } from "@/components/FeeSelect";
import { mockTonOkxChainID } from "@/config/ton";
import { Aggregator, ITomoToken } from "@/constants/types";
import configChains from "@/proviers/web3Provider/chains";
import { IWeb3ChainType, Web3Type } from "@/proviers/web3Provider/type";
import tokenStore from "@/stores/tokenStore";
import { Mwei } from "@/stores/tokenStore/hooks/read/useGas";
import { UserType } from "@/stores/userStore/type";
import { getChainByChainIdV2, getScanUrl, sendHashToast } from "@/stores/walletStore/utils";
import { formatUnits } from "viem";
import { getTransactionHash as getTransactionScanHash } from "@/hooks/useTransactionHash";
import userStore from "@/stores/userStore";

export type ChainGasFeesType = {
  baseFee: string;
  priorityFee: string;
  fee: string;
  data: ChainGasResult;
};

const getFeesBySol = (
  res: ChainGasResult,
): {
  [key in FeeMode]: ChainGasFeesType;
} => {
  // SOL：base fee + (gas limit * priority fee)

  const priorityFees: {
    [key in FeeMode]: string;
  } = {
    [FeeMode.FAST]: (
      (BigInt(res.gasLimit) * BigInt(res.priorityFeeHigh)) /
      BigInt(Mwei)
    ).toString(),
    [FeeMode.SLOW]: ((BigInt(res.gasLimit) * BigInt(res.priorityFeeLow)) / BigInt(Mwei)).toString(),
    [FeeMode.AVERAGE]: (
      (BigInt(res.gasLimit) * BigInt(res.priorityFeeMedium)) /
      BigInt(Mwei)
    ).toString(),
  };

  return {
    [FeeMode.FAST]: {
      baseFee: res.baseFee.toString(),
      priorityFee: priorityFees[FeeMode.FAST],
      fee: (BigInt(res.baseFee) + BigInt(priorityFees[FeeMode.FAST])).toString(),
      data: res,
    },
    [FeeMode.SLOW]: {
      baseFee: res.baseFee.toString(),
      priorityFee: priorityFees[FeeMode.SLOW],
      fee: (BigInt(res.baseFee) + BigInt(priorityFees[FeeMode.SLOW])).toString(),
      data: res,
    },
    [FeeMode.AVERAGE]: {
      baseFee: res.baseFee.toString(),
      priorityFee: priorityFees[FeeMode.AVERAGE],
      fee: (BigInt(res.baseFee) + BigInt(priorityFees[FeeMode.AVERAGE])).toString(),
      data: res,
    },
  };
};

const getFeesByEvm = (
  res: ChainGasResult,
): {
  [key in FeeMode]: ChainGasFeesType;
} => {
  // ETH: gas limit * (base fee + priority fee)

  const priorityFees: {
    [key in FeeMode]: string;
  } = {
    [FeeMode.FAST]: res.priorityFeeHigh.toString(),
    [FeeMode.SLOW]: res.priorityFeeLow.toString(),
    [FeeMode.AVERAGE]: res.priorityFeeMedium.toString(),
  };

  const gasPrices: {
    [key in FeeMode]: string;
  } = {
    [FeeMode.FAST]: (BigInt(res.baseFee) + BigInt(res.priorityFeeHigh)).toString(),
    [FeeMode.SLOW]: (BigInt(res.baseFee) + BigInt(res.priorityFeeLow)).toString(),
    [FeeMode.AVERAGE]: (BigInt(res.baseFee) + BigInt(res.priorityFeeMedium)).toString(),
  };

  return {
    [FeeMode.FAST]: {
      baseFee: res.baseFee.toString(),
      priorityFee: priorityFees[FeeMode.FAST],
      fee: (BigInt(res.gasLimit) * BigInt(gasPrices[FeeMode.FAST])).toString(),
      data: res,
    },
    [FeeMode.SLOW]: {
      baseFee: res.baseFee.toString(),
      priorityFee: priorityFees[FeeMode.SLOW],
      fee: (BigInt(res.gasLimit) * BigInt(gasPrices[FeeMode.SLOW])).toString(),
      data: res,
    },
    [FeeMode.AVERAGE]: {
      baseFee: res.baseFee.toString(),
      priorityFee: priorityFees[FeeMode.AVERAGE],
      fee: (BigInt(res.gasLimit) * BigInt(gasPrices[FeeMode.AVERAGE])).toString(),
      data: res,
    },
  };
};

export const getChainGasInfoByFeeMode = async ({
  chainId,
  callData,
  params,
  addressList,
  chains,
  feeMode,
}: ChainGasParams & {
  feeMode: FeeMode;
  chains: IWeb3ChainType[] | undefined;
}) => {
  // Frontend logic for calculating gas fee

  const chainsInfo = await getChainByTomoIndex({ tomoIndex: chainId });

  if (!chainsInfo) return;

  if (!chainsInfo.support) return;

  const res = await getChainGasInfo({
    chainId: chainsInfo.chainIndex,
    callData,
    params,
    addressList,
  });
  if (res) {
    const fee = (() => {
      const chain = getChainByChainIdV2({ chainId, chains });

      if (chainId === configChains.solana.id) {
        const fees = getFeesBySol(res);
        return {
          fees,
          fee: fees[feeMode],
        };
      }

      if (chain?.type === "EVM") {
        const fees = getFeesByEvm(res);
        return {
          fees,
          fee: fees[feeMode],
        };
      }
    })();

    return fee;
  }
};

export const getChainGasInfoFees = ({
  result,
  chainId,
  chains,
}: {
  result:
    | {
        fees: {
          Instant: ChainGasFeesType;
          Average: ChainGasFeesType;
          Fast: ChainGasFeesType;
        };
        fee: ChainGasFeesType;
      }
    | undefined;
  chainId: number;
  chains: IWeb3ChainType[] | undefined;
}):
  | {
      [key in FeeMode]:
        | {
            gasLimit: string;
            gasPrice: string;
          }
        | undefined;
    }
  | undefined => {
  if (!result) return undefined;
  return {
    [FeeMode.FAST]: getChainGasInfoDetail({
      result: { fee: result.fees[FeeMode.FAST] },
      chainId,
      chains,
    }),
    [FeeMode.SLOW]: getChainGasInfoDetail({
      result: { fee: result.fees[FeeMode.SLOW] },
      chainId,
      chains,
    }),
    [FeeMode.AVERAGE]: getChainGasInfoDetail({
      result: { fee: result.fees[FeeMode.AVERAGE] },
      chainId,
      chains,
    }),
  };
};

export const getChainGasInfoDetail = ({
  result,
  chainId,
  chains,
}: {
  result: {
    fees?: {
      Instant: ChainGasFeesType;
      Average: ChainGasFeesType;
      Fast: ChainGasFeesType;
    };
    fee: ChainGasFeesType;
  };
  chainId: number;
  chains: IWeb3ChainType[] | undefined;
}) => {
  const chain = getChainByChainIdV2({ chainId, chains });
  switch (chain?.type) {
    case Web3Type.EVM:
      // ETH: gas limit * (base fee + priority fee)
      return {
        gasLimit: result.fee?.data.gasLimit,
        gasPrice: (Number(result.fee?.baseFee) + Number(result.fee?.priorityFee)).toString(),
      };
    case Web3Type.SOL:
      // SOL：base fee + (gas limit * priority fee/1000000)
      return {
        gasLimit: result.fee?.data.gasLimit,
        gasPrice: result.fee?.priorityFee,
      };
    default:
      break;
  }
};

const apiChainsInfo: ChainGasChainInfo[] = [];

export const getChainByTomoIndex = async ({ tomoIndex }: { tomoIndex: number }) => {
  const chains = await (async () => {
    if (apiChainsInfo.length <= 0) {
      const chains = await getChainInfos();
      apiChainsInfo.push(...chains);
      return apiChainsInfo;
    }
    return apiChainsInfo;
  })();

  return chains.find((chain) => chain.chainId === tomoIndex);
};

export const getChainsByTomo = async () => {
  const chains = await (async () => {
    if (apiChainsInfo.length <= 0) {
      const chains = await getChainInfos();
      apiChainsInfo.push(...chains);
      return apiChainsInfo;
    }
    return apiChainsInfo;
  })();

  return chains;
};

export const getChainMEVInfoList = ({
  chain,
  chains,
}: {
  chain: IWeb3ChainType;
  chains: ChainGasChainInfo[] | undefined;
}) => {
  const chainId = chain.id;

  const tomoChainInfo = chains?.find((chain) => chain.chainId === chainId);

  if (tomoChainInfo) {
    const mevInfoList = tomoChainInfo.mevInfoList;
    return mevInfoList && mevInfoList.length > 0 ? mevInfoList : undefined;
  }
};

export type SendTransactionByCenterParamsType = {
  type: CenterSubmitParams["type"]; // 1:swap 2:bridge 3:transfer
  source: CenterSubmitParams["source"]; //1：okx 2: rango 3.ston-fi 4: jupiter 0: other
  slippage?: CenterSubmitParams["slippage"];
  fromAddress: CenterSubmitParams["fromAddress"];
  toAddress: CenterSubmitParams["toAddress"];
  fromAmount: CenterSubmitParams["fromAmount"];
  toAmount: CenterSubmitParams["toAmount"];
  sourceDex?: CenterSubmitParams["sourceDex"];
  estimatedGas?: CenterSubmitParams["estimatedGas"];
  failReason?: CenterSubmitParams["failReason"];
  callData: CenterSubmitParams["callData"];
  tx?: CenterSubmitParams["tx"];
  user: UserType; // 1:swap 2:bridge 3:transfer
  fromToken: ITomoToken;
  toToken: ITomoToken;
  fromChainId?: IWeb3ChainType["id"];
  toChainId?: IWeb3ChainType["id"];
  extParams?: CenterSubmitParams["extParams"];
  extJson?: CenterSubmitParams["extJson"];
  mevInfo?: MEVInfoType;
};

export const sendTransactionByCenterApi = async ({
  user,
  type,
  source,
  slippage,
  fromToken,
  toToken,
  fromAddress,
  toAddress,
  fromChainId,
  toAmount,
  fromAmount,
  sourceDex,
  estimatedGas,
  failReason,
  externalOrderId,
  callData,
  extParams,
  tx,
  toChainId,
  extJson,
  mevInfo,
}: SendTransactionByCenterParamsType) => {
  if (typeof fromChainId !== "number" || typeof toChainId !== "number") {
    return;
  }
  const fromChainInfo = await getChainByTomoIndex({ tomoIndex: fromChainId });
  const toChainInfo = await getChainByTomoIndex({ tomoIndex: toChainId });

  if (fromChainInfo) {
    // sendTransaction
    const params: CenterSubmitParams = {
      platform: 3,
      type,
      userId: user.id,
      fromChainIndex: fromChainInfo?.chainIndex.toString(),
      toChainIndex: toChainInfo?.chainIndex.toString() || "-1".toString(),
      fromTokenPrice: Number(fromToken.price),
      toTokenPrice: Number(toToken.price),
      source,
      slippage,
      fromAddress,
      toAddress,
      fromTokenAddress: fromToken.address,
      fromAmount: fromAmount,
      toTokenAddress: toToken.address,
      toAmount: toAmount,
      sourceDex: sourceDex || "",
      externalOrderId: externalOrderId || "",
      estimatedGas: estimatedGas || "",
      failReason: failReason || "",
      tx: tx || "",
      callData,
      extParams,
      extJson: extJson,
      mevSymbol: mevInfo?.mevSymbol,
    };
    const res = await sendTransaction(params);

    return res;
  }
};

export const sendRawTransactionByCenterApi = async ({
  apiParams,
  callData,
  extParams,
  tx,
}: {
  apiParams?: ApiParams;
  callData: SendTransactionByCenterParamsType["callData"];
  tx?: SendTransactionByCenterParamsType["tx"];
  extParams?: SendTransactionByCenterParamsType["extParams"];
}) => {
  if (apiParams) {
    const { user, type, params } = apiParams;
    const { fromToken, toToken } = params;
    let hash: string | undefined;

    const fromChainId =
      fromToken.chainId === mockTonOkxChainID ? configChains.ton.id : fromToken.chainId;

    const toChainId = toToken.chainId === mockTonOkxChainID ? configChains.ton.id : toToken.chainId;

    const sendParams: SendTransactionByCenterParamsType = {
      user: user,
      type: (() => {
        if (type === "Send") {
          return 3;
        }
        if (type === "Approve") {
          return 4;
        }
        if (fromToken.chainId === toToken.chainId) {
          return 1;
        }
        return 2;
      })(),
      source: (() => {
        switch (params.params?.routeInfo.aggregatorType) {
          case Aggregator.OKX:
            return 1;
          case Aggregator.RANGO:
            return 2;
          case Aggregator.STONFI:
            return 3;
          case Aggregator.JUPITER:
            return 4;
          default:
            return 0;
        }
      })(),
      slippage: params.params?.slippage,
      fromToken,
      toToken,
      fromAddress: params.fromAddress,
      toAddress: params.toAddress,
      fromChainId: fromChainId,
      toChainId: toChainId,
      fromAmount: params.params?.amount || "",
      toAmount: params.params?.routeInfo.minReceived || "",
      sourceDex: params.params?.routeInfo.swapperTitle,
      estimatedGas: params.gasFee?.toString() || "",
      externalOrderId: params.params?.routeInfo.rangoRequestId,
      failReason: "",
      callData: callData,
      extParams,
      tx: tx,
      extJson: params.extJson,
      mevInfo: apiParams.mevInfo,
    };

    // res 为订单号
    const res = await sendTransactionByCenterApi(sendParams);

    const chainsInfo = await getChainByTomoIndex({ tomoIndex: fromChainId });

    const needCheckStatus = chainsInfo ? chainsInfo?.orderStatusSupport : true;

    if (res) {
      const result = await pollForMethods<{ orderId: string }, CenterSubmitResult>({
        params: {
          orderId: res,
        },
        callback: getTransactionHash,
        check: "tx",
        failCheck: "failReason",
        supportCheck: "status",
        transactionEnd: apiParams.transactionEnd,
        toastBack: needCheckStatus
          ? async (res) => {
              const typeArr = ["Send", "Swap"];
              const rType = typeArr.includes(type) ? type : "";
              if (rType) {
                const fromAmount =
                  rType === "Send"
                    ? formatUnits(BigInt(params.value || "0"), params.fromToken.decimals)
                    : params.params?.amount;

                const fromSymbol =
                  rType === "Send"
                    ? params?.fromToken.symbol?.toLocaleUpperCase() || ""
                    : params.params?.fromToken.symbol?.toLocaleUpperCase() || "";

                if (!fromAmount) {
                  return;
                }
                let scanTxUrl: string | undefined = "";
                try {
                  if (apiParams?.params?.fromToken?.chainInfo) {
                    const tonAddress = userStore.userInfo.tonAddress;
                    const tonAddressTest = userStore.userInfo.tonAddressTest;
                    const scanHash = await getTransactionScanHash({
                      chain: apiParams.params.fromToken.chainInfo,
                      hash: res.tx,
                      tonAddress,
                    });
                    const scanHashTest = await getTransactionScanHash({
                      chain: apiParams.params.fromToken.chainInfo,
                      hash: res.tx,
                      tonAddress: tonAddressTest,
                    });
                    scanTxUrl = getScanUrl({
                      chain: apiParams.params.fromToken.chainInfo,
                      hash: scanHash,
                      hashTest: scanHashTest,
                    });
                  }
                } catch (error) {
                  //
                }
                sendHashToast({
                  type: rType as any,
                  status: res.status === TomoTxStatus.TX_SUCCESS ? "success" : "failed",
                  fromAmount: fromAmount,
                  toAmount:
                    rType === "Send" ? fromAmount : params.params?.routeInfo.minReceived || "",
                  fromSymbol: fromSymbol,
                  toSymbol: params.params?.toToken?.symbol?.toLocaleUpperCase() || "",
                  scanTxUrl,
                });
                setTimeout(() => {
                  tokenStore.refreshTokenStore(new Date().getTime());
                }, 500);
              }
              // sendHashToast
            }
          : undefined,
        init: apiParams.init,
      });
      //  轮询接口 进行 hash 获取
      return result.tx;
    }
  }
};

export type TokensBalanceType = {
  chainId: number;
  walletAddress: string;
  tokenAddressList: string[];
};
export const getTokensBalance = async ({
  chainId,
  walletAddress,
  tokenAddressList,
}: TokensBalanceType) => {
  const chainInfo = await getChainByTomoIndex({ tomoIndex: chainId });
  if (!chainInfo) throw new Error("chainInfo not found");
  const balances = await getBalances({
    chainIndex: chainInfo.chainIndex.toString(),
    walletAddress,
    tokenAddressList,
  });

  return balances;
};

export const getChainMevInfoDetail = async ({ chains }: { chains: IWeb3ChainType[] }) => {
  const tomoChains = await getChainsByTomo();
  const chainIds = chains.map((chain) => chain.id);
  const filterTomoChains = tomoChains.filter((chain) => {
    return chainIds.includes(chain.chainId);
  });
  if (filterTomoChains.length) {
    const res = await getMevDetail({
      chainIndex: filterTomoChains.map((tomoChain) => tomoChain.chainIndex.toString()),
    });
    return res;
  }
};
