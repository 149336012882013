import { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useUserStore from "@/stores/userStore/hooks/useUserStore";
import userStore from "@/stores/userStore";
import { ExpreTime } from "@/stores/userStore/utils";
import commonStore from "@/stores/commonStore";

const GuardRoute = observer(({ children }: PropsWithChildren) => {
  const { tgData, userState } = useUserStore();
  const location = useLocation();
  const navigate = useNavigate();
  const hoursExp = Date.now() - userState.loginTime > ExpreTime;
  const tokenExp = Date.now() - (userState.tokenExpired || Date.now()) > 0;
  const isTgUser =
    tgData.data?.user?.id && tgData.data?.user?.id === userState.tgId && userState.userId;

  useEffect(() => {
    if (!tgData.data?.user?.id) return;
    if (!isTgUser || hoursExp || tokenExp || userState?.frozen) {
      navigate("/login");
      userStore.updateUserStateAction({
        ...(userStore.userState || {}),
        loginTime: 0,
      });
      return;
    } else if (!userState?.email) {
      navigate("/account/recovery-email");
      return;
    }
  }, [
    isTgUser,
    hoursExp,
    tokenExp,
    tgData.data?.user?.id,
    userState?.frozen,
    navigate,
    location.pathname,
    userState?.email,
  ]);

  useEffect(() => {
    const flag =
      location.pathname === "/" ||
      location.pathname === "/swap" ||
      location.pathname === "/market" ||
      location.pathname.includes("/market/token") ||
      location.pathname.includes("/swap/select-token") ||
      location.pathname.includes("/send/select-token") ||
      location.pathname.includes("/send/input-amount") ||
      location.pathname.includes("/gift") ||
      location.pathname.includes("/account/manage-token");
    commonStore.updatebalanceSwitch(flag);
  }, [location.pathname]);

  if (!isTgUser) {
    return null;
  }
  if (hoursExp || tokenExp) {
    return null;
  }

  return children;
});

export default GuardRoute;
