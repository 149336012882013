import configChains from "@/proviers/web3Provider/chains";
import { convertBase64ToHex, getTransactionsByInMessageHashV2, getTonWebAsync, tonDecimals } from "config/ton";
import { sleep } from "utils";
import { isEmpty } from "utils/helper";
import { formatUnits } from "viem";

export const queryTransaction = async (address: string, msgHash: string) => {
  const transactionsV2 = await getTransactionsByInMessageHashV2(msgHash);
  let transaction: any = {};
  const transactions = transactionsV2.transactions;
  if (transactions && transactions.length) {
    transaction = transactions.find((trans: any) => {
      // 0:B66772D6E61BB4711FE16DDCB96D9EA76315F73A5E6354D9DFB2277264E5ECB1
      const account = transactionsV2.address_book[trans.account].user_friendly;
      if (account.toLocaleLowerCase() == address.toLocaleLowerCase()) {
        return true;
      }
      return false;
    });
    if (!isEmpty(transaction)) {
      transaction.hashHex = convertBase64ToHex(transaction.hash);
      transaction.feeFormatted = formatUnits(
        BigInt(transaction.total_fees),
        configChains.tonTestnet.chain?.nativeCurrency.decimals || tonDecimals,
      );
      transaction.valueFormatted = (await getTonWebAsync()).utils.fromNano(transaction.out_msgs[0].value);
    }
  }

  return transaction;
};

const useTonTransactions = () => {
  const waitForTonTransactionSuccess = async ({
    address,
    msgHash,
    timeoutMs = 30 * 1000,
  }: {
    address: string;
    msgHash: string;
    timeoutMs?: number;
  }): Promise<boolean> => {
    const endTime = Date.now() + timeoutMs;

    while (Date.now() < endTime) {
      try {
        const trans = await queryTransaction(address, msgHash);
        if (trans && Object.keys(trans).length) {
          return true;
        }
        await sleep(1500);
      } catch (error) {
        console.error(`Error checking transaction ${msgHash}:`, error);
      }
    }

    return false;
  };

  return {
    queryTransaction,
    waitForTonTransactionSuccess,
  };
};

export default useTonTransactions;
