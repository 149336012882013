import React from "react";
// import TomoIcon from 'assets/imgs/market/tomo2x.png'
import classNames from "classnames";
import useTheme from "@/stores/userStore/hooks/useTheme";

type Props = {
  emptyText?: React.ReactNode;
  textClassName?: string;
  containerClassName?: string;
};
export function NoResult({ emptyText, textClassName, containerClassName }: Props) {
  const { theme } = useTheme();

  return (
    <div
      className={classNames(
        containerClassName,
        "flex flex-col gap-4 justify-center items-center py-10",
      )}
    >
      <img
        src={
          theme == "light"
            ? "/assets/imgs/noresult/light-new.svg"
            : "/assets/imgs/noresult/dark-new.svg"
        }
        alt="empty icon"
        className="size-[80px]"
      />
      <div className={classNames(textClassName, "text-title/40")}>
        {emptyText || "No search result"}
      </div>
    </div>
  );
}
