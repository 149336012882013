import { observable, autorun, action, computed, IComputedValue } from "mobx";
import { IChainId } from "@/proviers/web3Provider/type";
import { AssetsToken } from "../tokenStore/type/AssetsToken";
import { getCache, setCache, STORAGE_KEY } from "@/utils/cacheManage";
import tokenStore from "../tokenStore";
import configChains from "@/proviers/web3Provider/chains";
import { WalletType } from "../tokenStore/type/BTCToken";
import { ChainsBalaceType, TransactionsType } from "./type";
import { IHistoryType, ReportHistoryType } from "@/state";
import { mergeTxs, updateSignleTx } from "./utils";
import allChain from "@/proviers/web3Provider/chains/wagmiConfig/allChain";

export interface IWalletStore {
  chainId: IChainId;
  walletTokens: AssetsToken[];
  cryptoTokens: AssetsToken[];
  whiteRiskTokenKeys: string[];
  walletTxs: TransactionsType;
  walletReportTxs: ReportHistoryType[];
  refreshTime: number;
  walletTokensActions: (tokens: AssetsToken[]) => void;
  cryptoTokensActions: (tokens: AssetsToken[]) => void;
  whiteRiskTokenKeysActions: (tokenKeys: string[]) => void;
  walletTxsActions: (txs: TransactionsType) => void;
  walletTxUpdateActions: (tx: IHistoryType) => void;
  walletTokenSwitchActions: (symbol: string, chainId: number, flag: boolean, isRisk?: boolean) => void;
  chainActions: (chainId: IChainId) => void;
  refreshActions: (time: number) => void;
  walletTxReportActions: (txs: ReportHistoryType[]) => void;
  getTotalBalance: IComputedValue<number>;
  getChainTokenList: IComputedValue<AssetsToken[]>;
  btcWalletType: WalletType;
  btcWalletTypeActions: (type: WalletType) => void;
  getChainsTotalBalace: IComputedValue<ChainsBalaceType>;
}

const walletStore: IWalletStore = observable({
  chainId: -1,
  walletTokens: [],
  cryptoTokens: [],
  whiteRiskTokenKeys: getCache(STORAGE_KEY.RISK_WHITE_TOKEN_KEYS, []),
  walletTxs: {},
  refreshTime: 0,
  walletReportTxs: [],
  walletTokensActions: action((tokens: AssetsToken[]) => {
    walletStore.walletTokens = tokens;
  }),
  cryptoTokensActions: action((tokens: AssetsToken[]) => {
    walletStore.cryptoTokens = tokens;
  }),
  whiteRiskTokenKeysActions: action((tokenKeys: string[]) => {
    setCache(STORAGE_KEY.RISK_WHITE_TOKEN_KEYS, tokenKeys);
    walletStore.whiteRiskTokenKeys = tokenKeys;
  }),
  walletTxsActions: action((txs: TransactionsType) => {
    walletStore.walletTxs = mergeTxs(walletStore.walletTxs, txs);
  }),
  walletTxUpdateActions: action((history: IHistoryType) => {
    const txs = updateSignleTx(JSON.parse(JSON.stringify(walletStore.walletTxs)), history);
    if (txs) {
      walletStore.walletTxs = mergeTxs(walletStore.walletTxs, txs);
    }
  }),
  walletTxReportActions: action((txs: ReportHistoryType[]) => {
    walletStore.walletReportTxs = txs;
  }),
  walletTokenSwitchActions: action((symbol: string, chainId: number, flag: boolean, isRisk?: boolean) => {
    const storeKey = `${symbol}_${chainId}`;
    const hidenTokens: Array<string> = getCache(STORAGE_KEY.HIDDEN_TOKENS, []);
    const showTokens: Array<string> = getCache(STORAGE_KEY.SHOW_TOKENS, []);
    const hideIdx = hidenTokens.findIndex((key) => key === storeKey);
    const showIdx = showTokens.findIndex((key) => key === storeKey);
    if (flag) {
      if (hideIdx !== -1) hidenTokens.splice(hideIdx, 1);
      if (showIdx === -1) showTokens.push(storeKey);
    } else {
      if (showIdx !== -1) showTokens.splice(showIdx, 1);
      if (hideIdx === -1) hidenTokens.push(storeKey);
    }

    setCache(STORAGE_KEY.HIDDEN_TOKENS, hidenTokens);
    setCache(STORAGE_KEY.SHOW_TOKENS, showTokens);
    walletStore.refreshActions(new Date().getTime());
    tokenStore.refreshTokenStore(new Date().getTime());
    // white risk token
    if (isRisk) {
      if (flag) {
        walletStore.whiteRiskTokenKeysActions([...new Set([...walletStore.whiteRiskTokenKeys, storeKey])]);
        walletStore.whiteRiskTokenKeys;
      } else {
        walletStore.whiteRiskTokenKeysActions(walletStore.whiteRiskTokenKeys.filter((key) => key !== storeKey));
      }
    }
  }),
  chainActions: action((chainId: IChainId) => {
    walletStore.chainId = chainId;
  }),
  refreshActions: action((time: number) => {
    walletStore.refreshTime = time;
  }),
  getTotalBalance: computed(() => {
    let tokenList = walletStore.cryptoTokens;
    if (walletStore.chainId !== configChains.allChain.id) {
      tokenList = walletStore.cryptoTokens.filter((i) => i.chainId === walletStore.chainId);
    }
    return tokenList
      .map((token) => {
        return (token?.price || 0) * Number(token?.formatted ?? 0);
      })
      .reduce((accToken, curToken) => {
        return (accToken || 0) + curToken;
      }, 0);
  }),
  getChainTokenList: computed(() => {
    if (walletStore.chainId === -1) return walletStore.walletTokens;
    return walletStore.walletTokens.filter((token) => token.chainId === walletStore.chainId);
  }),
  btcWalletType: "bitcoinP2Pkh",
  btcWalletTypeActions: action((type: WalletType) => {
    walletStore.btcWalletType = type;
  }),
  getChainsTotalBalace: computed(() => {
    let total = 0;
    const chainsTotalBalance = walletStore.walletTokens.reduce((acc: ChainsBalaceType, token) => {
      if (!acc[token.chainId]) {
        acc[token.chainId] = 0;
      }
      const currentBalance = (token?.price || 0) * Number(token?.formatted ?? 0);

      acc[token.chainId] += currentBalance;
      total += currentBalance;
      return acc;
    }, {});
    chainsTotalBalance[allChain.id] = total;
    return chainsTotalBalance;
  }),
});

autorun(() => {
  console.log({
    key: "walletStore",
    walletStore,
  });
});

export default walletStore;
