import { TIcon, useTranslation } from '@/components/tmd'
import { Routers } from '@/router'
import useChainsStore from '@/stores/chainsStore/hooks/useChainsStore'
import rampStore from '@/stores/rampStore'
import useRampStore from '@/stores/rampStore/hooks/useRampStore'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import { getChainByChainIdV2 } from '@/stores/walletStore/utils'
import { transformMoonPayCodeFromSymbol } from '@/utils/moonpay'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

export enum ReceiveTabKeys {
  buy = 'buy'
}

interface ReceiveTabsType {
  icon: React.ReactNode
  key: ReceiveTabKeys
  label: string
}

export default function ReceiveTabButtons({
  token
}: {
  token: AssetsToken | undefined
}) {
  const navigate = useNavigate()

  const { moonPaySupportCodes } = useRampStore()
  const { chains } = useChainsStore()
  const { t } = useTranslation()
  const isSupportMoonpay = useMemo(() => {
    if (token) {
      const chain = getChainByChainIdV2({
        chainId: token.chainId,
        chains
      })
      if (chain) {
        const code = transformMoonPayCodeFromSymbol(token.symbol, chain.name)
        const support = moonPaySupportCodes.includes(code)

        return support
      } else {
        return false
      }
    }
    return false
  }, [chains, moonPaySupportCodes, token])

  const receiveTabs = useMemo(() => {
    const tabs: ReceiveTabsType[] = isSupportMoonpay
      ? [
          {
            icon: <TIcon name="tg_wallet_fiat-currency" fontSize="20" />,
            key: ReceiveTabKeys.buy,
            label: t('tg_wallet_ramp.buy')
          }
        ]
      : []
    return tabs
  }, [isSupportMoonpay, t])

  const handleClick = useCallback(() => {
    if (token) {
      if (isSupportMoonpay) {
        console.log(token)
        rampStore.setMoonpayToken(token)
        navigate(Routers.ramp.moonpay())
      }
    }
  }, [isSupportMoonpay, navigate, token])

  return (
    <>
      {receiveTabs.map((tab) => (
        <div
          key={tab.key}
          onClick={handleClick}
          className="box-border flex h-[36px] w-[100px]  items-center justify-center rounded-[36px] border border-l1 text-t1"
        >
          {tab.icon}
          <span className="ml-1 text-[13px]  font-medium">{tab.label}</span>
        </div>
      ))}
    </>
  )
}
