import { Skeleton as ASkeleton } from 'antd-mobile'
import { SkeletonProps } from './PropsType'
import { mergeProps } from '../utils/get-default-props'
import classNames from 'classnames'

const defaultStyles = {
  '--border-radius': '6px'
}
const defaultProps = {
  animated: true
}
export const Skeleton = (p: SkeletonProps) => {
  const styles = mergeProps(p.style, defaultStyles)
  const props = mergeProps(p, defaultProps)
  const { style: unsetStyle, className, ...restProps } = props

  return (
    <ASkeleton
      style={styles}
      className={classNames('tmd-skeleton', className)}
      {...restProps}
    />
  )
}
