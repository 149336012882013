import accountAddressSvg from "@/assets/imgs/account-address.svg";
import accountEditSvg from "@/assets/imgs/account-edit.svg";
import accountKeySvg from "@/assets/imgs/account-key.svg";
import accountNameSvg from "@/assets/imgs/account-name.svg";
import accountPhraseSvg from "@/assets/imgs/account-phrase.svg";
import accountRemoveEnableSvg from "@/assets/imgs/account-remove-enable.svg";
import accountRemoveSvg from "@/assets/imgs/account-remove.svg";
import avatarPng from "@/assets/imgs/avatar.png";
import allChainSvg from "@/assets/imgs/chains/all-chain.svg";
import b3Jpg from "@/assets/imgs/chains/b3.jpg";
import opSvg from "@/assets/imgs/chains/op.svg";
import solSvg from "@/assets/imgs/chains/sol.svg";
import suiSvg from "@/assets/imgs/chains/sui.svg";
import CloseRoundSvg from "@/assets/imgs/close-round.svg";
import copyThemeSvg from "@/assets/imgs/copy-theme.svg";
import copySvg from "@/assets/imgs/copy.svg";
import copy2Svg from "@/assets/imgs/copy2.svg";
import tokensTimeSvg from "@/assets/imgs/tokens/time.svg";
import tokensExportSvg from "@/assets/imgs/tokens/export.svg";
import DownSvg from "@/assets/imgs/down.svg";
import editSvg from "@/assets/imgs/edit.svg";
import emptySvg from "@/assets/imgs/empty.svg";
import historySvg from "@/assets/imgs/history.svg";
import infoGraySvg from "@/assets/imgs/info-gray.svg";
import infoSvg from "@/assets/imgs/info.svg";
import linkThemeSvg from "@/assets/imgs/link-theme.svg";
import linkSvg from "@/assets/imgs/link.svg";
import loginBg1Svg from "@/assets/imgs/login-bg-1.svg";
import loginBg2Svg from "@/assets/imgs/login-bg-2.svg";
import logoSvg from "@/assets/imgs/logo.svg";
import mintSvg from "@/assets/imgs/mint.svg";
import noticeSvg from "@/assets/imgs/notice.svg";
import okxPng from "@/assets/imgs/okx.png";
import qrCodeSvg from "@/assets/imgs/qr-code.svg";
import qrCode2Svg from "@/assets/imgs/qr-code2.svg";
import rankSvg from "@/assets/imgs/rank.svg";
import receiveSvg from "@/assets/imgs/receive.svg";
import recoveryPhraseSvg from "@/assets/imgs/recovery-phrase.svg";
import rightSvg from "@/assets/imgs/right.svg";
import roundCheckedSvg from "@/assets/imgs/round-checked.svg";
import roundDownSvg from "@/assets/imgs/round-down.svg";
import roundUpSvg from "@/assets/imgs/round-up.svg";
import roundSvg from "@/assets/imgs/round.svg";
import selectEmptySvg from "@/assets/imgs/select-empty.svg";
import selectedSvg from "@/assets/imgs/selected.svg";
import sendSvg from "@/assets/imgs/send.svg";
import shareSvg from "@/assets/imgs/share.svg";
import duckchainJPG from "@/assets/imgs/socialMedia/duckchain.png";
import galxeJPG from "@/assets/imgs/socialMedia/galxe.jpeg";
import instagramSvg from "@/assets/imgs/socialMedia/instagram.svg";
import jasperJPG from "@/assets/imgs/socialMedia/jasper.jpg";
import jupiterSvg from "@/assets/imgs/socialMedia/jupiter.svg";
import kiloexJPG from "@/assets/imgs/socialMedia/kiloex.jpg";
import orbiterSvg from "@/assets/imgs/socialMedia/orbiter.svg";
import rangoSvg from "@/assets/imgs/socialMedia/rango.svg";
import scratchwinJPG from "@/assets/imgs/socialMedia/scratchwin.jpg";
import stonfiSvg from "@/assets/imgs/socialMedia/ston-fi.svg";
import sunSwapSvg from "@/assets/imgs/socialMedia/sun-swap.svg";
import tgBlueSvg from "@/assets/imgs/socialMedia/tg-blue.svg";
import tgWhiteSvg from "@/assets/imgs/socialMedia/tg-white.svg";
import tiktokSvg from "@/assets/imgs/socialMedia/tiktok.svg";
import timonPng from "@/assets/imgs/socialMedia/timon.png";
import xSvg from "@/assets/imgs/socialMedia/x.svg";
import xpinPng from "@/assets/imgs/socialMedia/xpin.png";
import swapSvg from "@/assets/imgs/swap.svg";
import tgWhiteRoundedSvg from "@/assets/imgs/tg-white-rounded.svg";
import tgWhiteLineSvg from "@/assets/imgs/tg-white.svg";
import tgSvg from "@/assets/imgs/tg.svg";
import toastErrorSvg from "@/assets/imgs/toast/error.svg";
import toastInfoSvg from "@/assets/imgs/toast/info.svg";
import toastLoadingSvg from "@/assets/imgs/toast/loading.svg";
import toastSuccessSvg from "@/assets/imgs/toast/success.svg";
import toastWarningSvg from "@/assets/imgs/toast/warning.svg";
import bnbSvg from "@/assets/imgs/tokens/bnb.svg";
import vectorSvg from "@/assets/imgs/tokens/vector.svg";
import usdtSvg from "@/assets/imgs/tokens/usdt.svg";
import UpSvg from "@/assets/imgs/up.svg";

import depositHomeSvg from "@/assets/imgs/deposit-home.svg";
import receiveHomeSvg from "@/assets/imgs/receive-home.svg";
import swapHomeSvg from "@/assets/imgs/swap-home.svg";
import tokenSwapSvg from "@/assets/imgs/token-swap.svg";

import accountAddress2Svg from "@/assets/imgs/account-address2.svg";
import arrowDownSvg from "@/assets/imgs/arrow-down.svg";
import arrowRightRoundSvg from "@/assets/imgs/arrow-right-round.svg";
import arrowRightSvg from "@/assets/imgs/arrow-right.svg";
import back1Svg from "@/assets/imgs/back-1.svg";
import backupSvg from "@/assets/imgs/backup.svg";
import backupMailSvg from "@/assets/imgs/backup_mail.svg";
import bnbMaticSvg from "@/assets/imgs/bnb-matic.svg";
import btcDownSvg from "@/assets/imgs/btc-down.svg";
import buySvg from "@/assets/imgs/buy.svg";
import arbJpeg from "@/assets/imgs/chains/arb.jpeg";
import cosmosPng from "@/assets/imgs/chains/cosmos.png";
import MovementSvg from "@/assets/imgs/chains/movement.svg";
import tonSvg2 from "@/assets/imgs/chains/ton.svg";
import checkPassSvg from "@/assets/imgs/check-pass.svg";
import checkboxActiveSvg from "@/assets/imgs/checkbox-active.svg";
import checkboxSvg from "@/assets/imgs/checkbox.svg";
import checkedThemeSvg from "@/assets/imgs/checked-theme.svg";
import confirmArrowSvg from "@/assets/imgs/confirm-arrow.svg";
import copy2ThemeSvg from "@/assets/imgs/copy2-theme.svg";
import copyBlackSvg from "@/assets/imgs/copy_black.svg";
import copyGraySvg from "@/assets/imgs/copy_gray.svg";
import marketTipSvg from "@/assets/imgs/market/tip.svg";
import marketQuickSvg from "@/assets/imgs/market/quick.svg";
import downloadSvg from "@/assets/imgs/download.svg";
import ellipsisRoundSvg from "@/assets/imgs/ellipsis-round.svg";
import exchangeSvg from "@/assets/imgs/exchange.svg";
import externalWalletSvg from "@/assets/imgs/external-wallet.svg";
import eyeCloseSvg from "@/assets/imgs/eye-close.svg";
import eyeSvg from "@/assets/imgs/eye.svg";
import gasSvg from "@/assets/imgs/gas.svg";
import giftSvg from "@/assets/imgs/gift.svg";
import babylonSvg from "@/assets/imgs/icon-babylon.svg";
import backSvg from "@/assets/imgs/icon-back.svg";
import simpleCopySvg from "@/assets/imgs/icon-copy.svg";
import lombardSvg from "@/assets/imgs/icon-lombard.svg";
import lorenzoSvg from "@/assets/imgs/icon-lorenzo.svg";
import iconTime from "@/assets/imgs/icon-time.svg";
import tomoBlackSvg from "@/assets/imgs/icon-tomo-blank.svg";
import keySvg from "@/assets/imgs/key.svg";
import mailSvg from "@/assets/imgs/mail.svg";
import marketCheckedSvg from "@/assets/imgs/market/checked.svg";
import marketInfoSvg from "@/assets/imgs/market/info.svg";
import marketLogoInvitePng from "@/assets/imgs/market/logoInvite.png";
import mastercardSvg from "@/assets/imgs/mastercard.svg";
import mevProtectionDarkSvg from "@/assets/imgs/mev-protection-dark.svg";
import mevProtectionSvg from "@/assets/imgs/mev-protection.svg";
import payPinSvg from "@/assets/imgs/pay_pin.svg";
import popupCloseSvg from "@/assets/imgs/popup-close.svg";
import qrCode3Svg from "@/assets/imgs/qr-code3.svg";
import qrcodeTwoSvg from "@/assets/imgs/qrcode_two.svg";
import referSvg from "@/assets/imgs/refer.svg";
import resetSvg from "@/assets/imgs/reset.svg";
import RightBlackSvg from "@/assets/imgs/right-black.svg";
import roundCheckedWhiteSvg from "@/assets/imgs/round-checked-white.svg";
import roundedPlusSvg from "@/assets/imgs/rounded-plus.svg";
import scanSvg from "@/assets/imgs/scan.svg";
import secretSvg from "@/assets/imgs/secret.svg";
import securityKeySvg from "@/assets/imgs/security-key.svg";
import securitySvg from "@/assets/imgs/security.svg";
import sentSubmitted from "@/assets/imgs/send-submitted.png";
import settingSvg from "@/assets/imgs/setting.svg";
import share2ThemeSvg from "@/assets/imgs/share2-theme.svg";
import share2Svg from "@/assets/imgs/share2.svg";
import sliveryXSvg from "@/assets/imgs/slivery-x.svg";
import spinerSvg from "@/assets/imgs/spiner.svg";
import spiner2Svg from "@/assets/imgs/spiner2.svg";
import spiner3Svg from "@/assets/imgs/spiner3.svg";
import stakeSvg from "@/assets/imgs/stake.svg";
import successRoundSvg from "@/assets/imgs/success-round.svg";
import swapHistoryItemSvg from "@/assets/imgs/swap-history-item.svg";
import swapHistoryLoadingSvg from "@/assets/imgs/swap-history-loading.svg";
import swapLineSvg from "@/assets/imgs/swap-line.svg";
import swapLoadingSvg from "@/assets/imgs/swap-loading.svg";
import swapRightSvg from "@/assets/imgs/swap-right.svg";
import swapSuccessSvg from "@/assets/imgs/swap-success.svg";
import swapWhiteSvg from "@/assets/imgs/swap-white.svg";

import babylonLogoSvg from "@/assets/imgs/task/babylon.svg";
import lombardLogoSvg from "@/assets/imgs/task/lombard.svg";
import rewardTokensSvg from "@/assets/imgs/task/reward-tokens.svg";
import stakestoneLogoSvg from "@/assets/imgs/task/stakestone.svg";
import tokenSwapOutlinedSvg from "@/assets/imgs/task/token-swap-outlined.svg";
import telegramSvg from "@/assets/imgs/telegram.png";
import telegram2Svg from "@/assets/imgs/telegram2.svg";
import timeSvg from "@/assets/imgs/time.svg";
import tipsSvg from "@/assets/imgs/tips.svg";
import whyPng from "@/assets/imgs/tokens/why.png";
import wifPng from "@/assets/imgs/tokens/wif.png";
import visaSvg from "@/assets/imgs/visa.svg";
import weiboPng from "@/assets/imgs/weibo.png";
import tronSvg from "/assets/imgs/chains/tron.svg";
import visibilitySvg from "@/assets/imgs/visibility.svg";
import visibilityOffSvg from "@/assets/imgs/visibility-off.svg";
const errorBoundarySvg = "/assets/imgs/error-boundary.svg";
const errorLightSvg = "@/assets/imgs/error-light.svg";
const errorDarkSvg = "@/assets/imgs/error-dark.svg";
const logoOutlinedSvg = "/assets/imgs/task/logo-outlined.svg";
const loginTomoLogoSvg = "/assets/imgs/login-tomo-logo-new.svg";
const referLogoSvg = "/assets/imgs/refer-logo.svg";
import tradeSvg from "@/assets/imgs/task/trade.svg";
import listSvg from "@/assets/imgs/list.svg";
import usdSvg from "@/assets/imgs/ramp/USD.svg";
import alchemyPay from "@/assets/imgs/ramp/alchemy.png";
import cryptoSentSvg from "@/assets/imgs/ramp/crypto-sent.svg";
import divide from "@/assets/imgs/ramp/divide.svg";
import result from "@/assets/imgs/ramp/result.svg";
import confirmOrder from "@/assets/imgs/ramp/confirm-order.svg";
import inTransfer from "@/assets/imgs/ramp/in-transfer.svg";
import success from "@/assets/imgs/ramp/success.svg";
import loading from "@/assets/imgs/ramp/loading.svg";
import processIcon from "@/assets/imgs/ramp/processing-icon.svg";
import completedIcon from "@/assets/imgs/ramp/completed-icon.svg";
import failedIcon from "@/assets/imgs/ramp/failed-icon.svg";
import closedIcon from "@/assets/imgs/ramp/closed-icon.svg";
import evmSvg from "@/assets/imgs/evm.svg";
import Right2Svg from "@/assets/imgs/right2.svg";
import MnemonicLogoSvg from "@/assets/imgs/mnemonic/logo.svg";
import MnemonicLogo1Svg from "@/assets/imgs/mnemonic/logo_1.svg";
import MnemonicLogoDark1Svg from "@/assets/imgs/mnemonic/logo_dark_1.svg";
import MnemonicLogo6Svg from "@/assets/imgs/mnemonic/logo_6.svg";
import MnemonicLogoDark6Svg from "@/assets/imgs/mnemonic/logo_dark_6.svg";
import MnemonicLogo2Svg from "@/assets/imgs/mnemonic/logo_2.svg";
import MnemonicLogoDark2Svg from "@/assets/imgs/mnemonic/logo_dark_2.svg";
import MnemonicLogo3Svg from "@/assets/imgs/mnemonic/logo_3.svg";
import MnemonicLogoDark3Svg from "@/assets/imgs/mnemonic/logo_dark_3.svg";
import MnemonicLogo4Svg from "@/assets/imgs/mnemonic/logo_4.svg";
import MnemonicLogoDark4Svg from "@/assets/imgs/mnemonic/logo_dark_4.svg";
import MnemonicLogo5Svg from "@/assets/imgs/mnemonic/logo_5.svg";
import MnemonicLogoDark5Svg from "@/assets/imgs/mnemonic/logo_dark_5.svg";
import MnemonicKeySvg from "@/assets/imgs/mnemonic/key.svg";
import MnemonicClockSvg from "@/assets/imgs/mnemonic/clock.svg";
import MnemonicNoCheckSvg from "@/assets/imgs/mnemonic/nocheck.svg";
import MnemonicNoCheckDarkSvg from "@/assets/imgs/mnemonic/nocheck_dark.svg";
import MnemonicCheckedSvg from "@/assets/imgs/mnemonic/checked.svg";
import MnemonicCheckedDarkSvg from "@/assets/imgs/mnemonic/checked_dark.svg";
import MnemonicTickSvg from "@/assets/imgs/mnemonic/tick.svg";
import MnemonicForkSvg from "@/assets/imgs/mnemonic/fork.svg";
import MnemonicEyehideSvg from "@/assets/imgs/mnemonic/eyehide.svg";
import MnemonicEyehideDarkSvg from "@/assets/imgs/mnemonic/eyehide_dark.svg";
import MnemonicWarningSvg from "@/assets/imgs/mnemonic/warning.svg";
import GAuthLogo from "@/assets/imgs/gauth/auth.svg";
import GAuthLogoDark from "@/assets/imgs/gauth/auth_dark.svg";
import GAuthSuccess from "@/assets/imgs/gauth/ga_success.svg";
import GAuthFail from "@/assets/imgs/gauth/ga_fail.svg";
import taskReward from "@/assets/imgs/task-reward.svg";
import taskPointDetailSvg from "@/assets/imgs/task-point-detail.svg";
import taskPointDetailDarkSvg from "@/assets/imgs/task-point-detail-dark.svg";
import friendsInvitedRewardSvg from "@/assets/imgs/friends-invited-reward.svg";
import rampHistory from "@/assets/imgs/ramp/history-icon.svg";
import rampEmpty from "@/assets/imgs/ramp/empty.svg";
import failedStatusIcon from "@/assets/imgs/ramp/failed-status-icon.svg";
import applePay from "@/assets/imgs/ramp/apple-pay-icon.svg";
import applyPayDark from "@/assets/imgs/ramp/applePay-dark.svg";
import googlePay from "@/assets/imgs/ramp/google-pay-icon.svg";
import googlePayDark from "@/assets/imgs/ramp/google-pay-dark.svg";
import googlePaylight from "@/assets/imgs/ramp/google-pay-light.svg";
import noSupportImg from "@/assets/imgs/ramp/no-support-img.svg";
import receiveToRampDark from "@/assets/imgs/receive/receive-to-ramp-dark.png";
import receiveToRampLight from "@/assets/imgs/receive/receive-to-ramp-light.png";
import claimedGiftDown from "@/assets/imgs/gift/claimed-gift-down.png";
import sendGiftUp from "@/assets/imgs/gift/send-gift-up.png";
import rampLoading from "@/assets/imgs/ramp/loading-icon2.svg";
import applePayDark from "@/assets/imgs/ramp/applePay-dark.svg";
import giftDetail from "@/assets/imgs/gift/gift-detail.png";
import giftBox from "@/assets/imgs/gift/gift-box.png";
import giftBoxOpen from "@/assets/imgs/gift/git-box-open.png";
import ailayerSvg from "@/assets/imgs/chains/ailayer.svg";
import bitlayerSvg from "@/assets/imgs/chains/bitlayer.svg";
import merlinPng from "@/assets/imgs/chains/merlin.png";
import botanixTestnetJpg from "@/assets/imgs/chains/botanix-testnet.jpg";
import berachainTestSvg from "@/assets/imgs/chains/berachain.svg";
import storyTestJpg from "@/assets/imgs/chains/story.jpg";
import berachainTestJpg from "@/assets/imgs/chains/berachain.jpg";
import worldChainJpg from "@/assets/imgs/chains/world-chain.jpg";
import b2Png from "@/assets/imgs/chains/b2.png";
import b2Svg from "@/assets/imgs/chains/b2.svg";
import seiSvg from "@/assets/imgs/chains/sei.svg";
import lorenzoChainSvg from "@/assets/imgs/chains/lorenzo.svg";
import gravityJpg from "@/assets/imgs/chains/gravity.jpg";
import zksyncSvg from "@/assets/imgs/chains/zksync.svg";
import zksyncPng from "@/assets/imgs/chains/zksync.png";
import opbnbSvg from "@/assets/imgs/chains/opbnb.svg";
import tacSvg from "@/assets/imgs/chains/tac.svg";
import mevmTestnetSvg from "@/assets/imgs/chains/mevm-testnet.svg";
import promTestnetSvg from "@/assets/imgs/chains/prom-testnet.svg";
import neoXJpg from "@/assets/imgs/chains/neo.jpg";
import bobPng from "@/assets/imgs/chains/bob.png";
import corn from "@/assets/imgs/chains/corn-logo.svg";
import JoinVsg from "@/assets/imgs/market/join.svg";
import fourMemeSvg from "@/assets/imgs/market/fourMeme.svg";
import JoinVsgDark from "@/assets/imgs/market/join-dark.svg";
import CommunitySvg from "@/assets/imgs/market/community.svg";
import WhatIsStarLight from "@/assets/imgs/starForGas/what-is-star-light.svg";
import WhatIsStarDark from "@/assets/imgs/starForGas/what-is-star-dark.svg";
import TgStar from "@/assets/imgs/starForGas/tg-star.svg";
import NewUserGift from "@/assets/imgs/gift/new-user-gift.svg";
import Doge from "@/assets/imgs/chains/doge.svg";

import riskPolygonSvg from "@/assets/imgs/risk/polygon.svg";
import riskPolygonDarkSvg from "@/assets/imgs/risk/polygon-dark.svg";
import riskMaskIcon from "@/assets/imgs/risk/risk-icon.svg";
import riskMaskDarkIcon from "@/assets/imgs/risk/rsk-icon-dark.svg";
import riskPoweredByIcon from "@/assets/imgs/risk/riskPowerByDark.svg";
import moonpayApplePayIcon from "@/assets/imgs/moonpay/applepay.svg";
import moonpayApplePayDarkIcon from "@/assets/imgs/moonpay/applepay_dark.svg";
import moonpayGooglePayIcon from "@/assets/imgs/moonpay/googlepay.svg";
import moonpayGooglePayDarkIcon from "@/assets/imgs/moonpay/googlepay_dark.svg";

import tomoLogoIcon from "@/assets/imgs/logo/tomo_logo.svg";
import recoveryNoBackupIcon from "@/assets/imgs/recovery/no_back_up.svg";
import recoveryExpiredIcon from "@/assets/imgs/recovery/expired.svg";

import joinCn from "@/assets/imgs/join/china.svg";
import joinUk from "@/assets/imgs/join/uk.svg";

const tomoLogo = {
  tomoLogoIcon,
};
const recoveryIcon = {
  recoveryNoBackupIcon,
  recoveryExpiredIcon,
};
const joinSvg = {
  joinCn,
  joinUk,
};

const moonpayIcon = {
  moonpayApplePayIcon,
  moonpayApplePayDarkIcon,
  moonpayGooglePayIcon,
  moonpayGooglePayDarkIcon,
};
const chainlistSrc = {
  btc: "/assets/imgs/chainlist/btc.svg",
  eth: "/assets/imgs/chainlist/eth.svg",
  arb: "/assets/imgs/chainlist/arb.svg",
  ava: "/assets/imgs/chainlist/avalanche.svg",
  b3: "/assets/imgs/chainlist/b3.svg",
  base: "/assets/imgs/chainlist/base.svg",
  blast: "/assets/imgs/chainlist/blast.svg",
  bsc: "/assets/imgs/chainlist/bsc.svg",
  duck: "/assets/imgs/chainlist/duckchain.svg",
  linea: "/assets/imgs/chainlist/linea.svg",
  mantle: "/assets/imgs/chainlist/mantle.svg",
  polygon: "/assets/imgs/chainlist/polygon.svg",
  scroll: "/assets/imgs/chainlist/scroll.svg",
  sol: "/assets/imgs/chainlist/sol.svg",
  ton: "/assets/imgs/chainlist/ton.svg",
  tron: "/assets/imgs/chainlist/tron.svg",
  storyTestnet: "/assets/imgs/chains/story.svg",
  berachainTestnet: "/assets/imgs/chains/berachain.jpg",
  botanixTestnet: "/assets/imgs/chains/botanix-testnet.jpg",
  ailayer: "/assets/imgs/chains/ailayer.png",
  bitlayer: "/assets/imgs/chains/bitlayer.png",
  merlin: "/assets/imgs/chains/merlin.png",
  doge: "/assets/imgs/chains/doge.svg",
  movement: "/assets/imgs/chains/movement.svg",
};

const chainsSvgs = {
  duckChainSvg: chainlistSrc.duck,
  ethSvg: chainlistSrc.eth,
  allChainSvg,
  btcSvg: chainlistSrc.btc,
  // solSvg: chainlistSrc.sol,
  solSvg: solSvg,
  arbSvg: chainlistSrc.arb,
  arbJpeg,
  cosmosPng,
  opSvg,
  bscSvg: chainlistSrc.bsc,
  lineaSvg: chainlistSrc.linea,
  blastSvg: chainlistSrc.blast,
  polygonSvg: chainlistSrc.polygon,
  avaxSvg: chainlistSrc.ava,
  mantleSvg: chainlistSrc.mantle,
  baseSvg: chainlistSrc.base,
  scrollSvg: chainlistSrc.scroll,
  storyTestnetSvg: storyTestJpg,
  berachainTestnetSvg: berachainTestSvg,
  berachainTestnetJpg: berachainTestJpg,
  botanixTestnetJpg: botanixTestnetJpg,
  worldChainPng: worldChainJpg,
  b3Jpg,
  b3Svg: chainlistSrc.b3,
  tronChainSvg: chainlistSrc.tron,
  tronSvg,
  suiSvg,
  opbnbSvg,
  tonSvg: chainlistSrc.ton,
  tonSvg2,
  tacSvg,
  corn,
  ailayer: ailayerSvg,
  // ailayer: ailayerPng,
  bitlayer: bitlayerSvg,
  merlin: merlinPng,
  mevmTestnet: mevmTestnetSvg,
  promTestnet: promTestnetSvg,
  neoX: neoXJpg,
  bob: bobPng,
  b2Png,
  b2Svg,
  seiSvg,
  gravityJpg,
  lorenzoChainSvg,
  zksyncPng,
  zksyncSvg,
  doge: Doge,
  movement: MovementSvg,
};
const tokensSvgs = {
  usdtSvg,
  whyPng,
  wifPng,
  bnbSvg,
  vectorSvg,
  tokensTimeSvg,
  tokensExportSvg,
};
const socialMediaSvgs = {
  tgBlueSvg,
  tgWhiteSvg,
  orbiterSvg,
  rangoSvg,
  jupiterSvg,
  stonfiSvg,
  okxPng,
  xSvg,
  tiktokSvg,
  instagramSvg,
  jasperJPG,
  kiloexJPG,
  galxeJPG,
  duckchainJPG,
  timonPng,
  xpinPng,
  scratchwinJPG,
  tomoLogo: loginTomoLogoSvg,
  sunSwapSvg,
};
const toastSvgs = {
  toastSuccessSvg,
  toastErrorSvg,
  toastInfoSvg,
  toastWarningSvg,
  toastLoadingSvg,
};
const arrowSvgs = {
  rightNewSvg: arrowRightSvg,
  rightSvg,
  downSvg: DownSvg,
  leftSvg: popupCloseSvg,
  rightV2Svg: RightBlackSvg,
  Right2Svg: Right2Svg,
};
const mnemonicImgs = {
  logoSvg: MnemonicLogoSvg,
  logo1Svg: MnemonicLogo1Svg,
  logoDark1Svg: MnemonicLogoDark1Svg,
  logo2Svg: MnemonicLogo2Svg,
  logoDark2Svg: MnemonicLogoDark2Svg,
  logo3Svg: MnemonicLogo3Svg,
  logoDark3Svg: MnemonicLogoDark3Svg,
  logo4Svg: MnemonicLogo4Svg,
  logoDark4Svg: MnemonicLogoDark4Svg,
  logo5Svg: MnemonicLogo5Svg,
  logoDark5Svg: MnemonicLogoDark5Svg,
  logo6Svg: MnemonicLogo6Svg,
  logoDark6Svg: MnemonicLogoDark6Svg,
  keySvg: MnemonicKeySvg,
  clockSvg: MnemonicClockSvg,
  nocheckSvg: MnemonicNoCheckSvg,
  nocheckDarkSvg: MnemonicNoCheckDarkSvg,
  checkedSvg: MnemonicCheckedSvg,
  checkedDarkSvg: MnemonicCheckedDarkSvg,
  tickSvg: MnemonicTickSvg,
  forkSvg: MnemonicForkSvg,
  eyehideSvg: MnemonicEyehideSvg,
  eyehideDarkSvg: MnemonicEyehideDarkSvg,
  warning: MnemonicWarningSvg,
};
const gauthImgs = {
  gaLogo: GAuthLogo,
  gaLogoDark: GAuthLogoDark,
  gaSuccess: GAuthSuccess,
  gaFail: GAuthFail,
};

const starForGasSvg = {
  WhatIsStarLight,
  WhatIsStarDark,
  TgStar,
};
const riskSvg = {
  polygon: riskPolygonSvg,
  polygoDark: riskPolygonDarkSvg,
  riskMaskIcon,
  riskMaskDarkIcon,
  riskPoweredByIcon,
};
export {
  accountAddress2Svg,
  accountAddressSvg,
  accountEditSvg,
  accountKeySvg,
  accountNameSvg,
  accountPhraseSvg,
  accountRemoveEnableSvg,
  accountRemoveSvg,
  alchemyPay,
  applePay,
  applePayDark,
  applyPayDark,
  arrowDownSvg,
  arrowRightRoundSvg,
  arrowSvgs,
  avatarPng,
  babylonLogoSvg,
  babylonSvg,
  back1Svg,
  backSvg,
  backupMailSvg,
  backupSvg,
  bnbMaticSvg,
  btcDownSvg,
  buySvg,
  chainsSvgs,
  checkboxActiveSvg,
  checkboxSvg,
  checkedThemeSvg,
  checkPassSvg,
  claimedGiftDown,
  closedIcon,
  CloseRoundSvg,
  CommunitySvg,
  completedIcon,
  confirmArrowSvg,
  confirmOrder,
  copy2Svg,
  copy2ThemeSvg,
  copyBlackSvg,
  marketTipSvg,
  marketQuickSvg,
  copyGraySvg,
  copySvg,
  copyThemeSvg,
  cryptoSentSvg,
  depositHomeSvg,
  divide,
  downloadSvg,
  DownSvg,
  editSvg,
  ellipsisRoundSvg,
  emptySvg,
  errorBoundarySvg,
  errorDarkSvg,
  errorLightSvg,
  evmSvg,
  exchangeSvg,
  externalWalletSvg,
  eyeCloseSvg,
  eyeSvg,
  failedIcon,
  failedStatusIcon,
  friendsInvitedRewardSvg,
  gasSvg,
  gauthImgs,
  giftBox,
  giftBoxOpen,
  giftDetail,
  giftSvg,
  googlePay,
  googlePayDark,
  googlePaylight,
  historySvg,
  iconTime,
  infoGraySvg,
  infoSvg,
  inTransfer,
  JoinVsg,
  JoinVsgDark,
  keySvg,
  linkSvg,
  linkThemeSvg,
  listSvg,
  loading,
  loginBg1Svg,
  loginBg2Svg,
  loginTomoLogoSvg,
  logoOutlinedSvg,
  logoSvg,
  lombardLogoSvg,
  lombardSvg,
  lorenzoSvg,
  mailSvg,
  marketCheckedSvg,
  marketInfoSvg,
  marketLogoInvitePng,
  mastercardSvg,
  mevProtectionDarkSvg,
  mevProtectionSvg,
  mintSvg,
  mnemonicImgs,
  noSupportImg,
  noticeSvg,
  okxPng,
  payPinSvg,
  popupCloseSvg,
  processIcon,
  qrCode2Svg,
  qrCode3Svg,
  qrCodeSvg,
  qrcodeTwoSvg,
  rampEmpty,
  rampHistory,
  rampLoading,
  rankSvg,
  receiveHomeSvg,
  receiveSvg,
  receiveToRampDark,
  receiveToRampLight,
  recoveryPhraseSvg,
  referLogoSvg,
  referSvg,
  resetSvg,
  result,
  rewardTokensSvg,
  RightBlackSvg,
  roundCheckedSvg,
  roundCheckedWhiteSvg,
  roundDownSvg,
  roundedPlusSvg,
  roundSvg,
  roundUpSvg,
  scanSvg,
  secretSvg,
  securityKeySvg,
  securitySvg,
  selectedSvg,
  selectEmptySvg,
  fourMemeSvg,
  sendGiftUp,
  sendSvg,
  sentSubmitted,
  settingSvg,
  share2Svg,
  share2ThemeSvg,
  shareSvg,
  simpleCopySvg,
  sliveryXSvg,
  socialMediaSvgs,
  spiner2Svg,
  spiner3Svg,
  spinerSvg,
  stakestoneLogoSvg,
  stakeSvg,
  starForGasSvg,
  riskSvg,
  NewUserGift,
  success,
  successRoundSvg,
  swapHistoryItemSvg,
  swapHistoryLoadingSvg,
  swapHomeSvg,
  swapLineSvg,
  swapLoadingSvg,
  swapRightSvg,
  swapSuccessSvg,
  swapSvg,
  swapWhiteSvg,
  taskPointDetailDarkSvg,
  taskPointDetailSvg,
  taskReward,
  telegram2Svg,
  telegramSvg,
  tgSvg,
  tgWhiteLineSvg,
  tgWhiteRoundedSvg,
  tgWhiteSvg,
  timeSvg,
  tipsSvg,
  toastSvgs,
  tokensSvgs,
  tokenSwapOutlinedSvg,
  tokenSwapSvg,
  tomoBlackSvg,
  tradeSvg,
  UpSvg,
  usdSvg,
  visaSvg,
  weiboPng,
  // swapImgs,
  visibilitySvg,
  visibilityOffSvg,
  moonpayIcon,
  tomoLogo,
  recoveryIcon,
  joinSvg,
};
