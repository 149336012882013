import { chatMemberCount } from "@/api";
import { JoinVsg, JoinVsgDark } from "@/assets";
import { TButton } from "@/components/tmd";
import usePageOpen from "@/hooks/usePageOpen";
import useTheme from "@/stores/userStore/hooks/useTheme";
import { useEffect, useState } from "react";

const tgGroupLink: string = import.meta.env.VITE_TG_GROUP;

const Join = () => {
  const [members, setMembers] = useState(0);
  const { openLink } = usePageOpen();
  const { theme } = useTheme();

  useEffect(() => {
    chatMemberCount()
      .then((data) => {
        setMembers(data?.members_count || 0);
      })
      .catch();
  }, []);

  return (
    <>
      <img src={theme === "light" ? JoinVsg : JoinVsgDark} className="mt-[30px] size-[200px]" />

      <div className="mt-4 w-full space-y-[2px]">
        <h5 className="font-semibold text-h3 text-t1">Tomo Community</h5>
        <p className="text-xs font-medium text-t3">
          {members} members
          {/* , 2 539 online */}
        </p>
      </div>

      <p className="flex-1 mt-6 w-full text-sm font-normal text-t2">
        Tomo is an all-in-one social wallet. Log in with socials and manage assets across Bitcoin, EVM, Solana, and
        others networks.
        <br />
        Trade, stake, earn, swap, chat, post and more!
      </p>

      <div className="flex-none mt-4 w-full">
        <TButton block size="large" onClick={() => openLink(tgGroupLink)}>
          Join
        </TButton>
      </div>
    </>
  );
};

export default Join;
