import { errorContents } from "@/config/const";
import { IOKXHistoryType } from "@/state";
import { SwapFeeAddress } from "@/stores/userStore/type";
import { getSdkHash } from "@/utils/sdk";
import { sentryInterceptors } from "@/utils/sentry/axiosInterceptors";
import { SimpleTransaction } from "@aptos-labs/ts-sdk";
import { InitDataUnsafe } from "@vkruglikov/react-telegram-web-app";
import axios from "axios";
import { ISwapTokenTypeV2 } from "pages/swap/hooks/useSwapTokensV2";
import { IChainId } from "proviers/web3Provider/type";
import { getPassKey, getUserInfo, logout } from "utils";
import { checkAddress, convertToIntervalType, intRandom } from "utils/helper";
import { AllTokenBalancesByAddressRes } from "./okx";
import {
  BtcCreateSendBtcPsbt,
  BtcPushTx,
  BtcSignPsbt,
  CreateStarInvoiceReqType,
  CreateStarInvoiceResType,
  DataResult,
  DogeTxRes,
  getSolRentReq,
  getSolRentRes,
  FourMemeTransaction,
  TomoBalancesByWalletId,
  TomoTokenInfo,
  getStarOrderListReqType,
  getStarOrderListResType,
  IGetTokenRiskParams,
  IFinishSeedExportRes,
  IInitSeedExportRes,
  ISignCosmosTxParams,
  ISwapTokensParams,
  MarketConfigToken,
  MemeOverview,
  PriceItem,
  RequestType,
  Result,
  SolSendTx,
  TomoChain,
  TradeToken,
  TrendingToken,
  UserAddressType,
} from "./type";
import beraChainTokens from "@/utils/beraChainTokens";
import logger from "@/utils/sentry/logger";

interface OKXResponeType<T> {
  data: T;
  msg: string;
  code: string;
}
export type PageParams = {
  page: number;
  limit: number;
  skip: number;
};
export interface ISocialInfo {
  appleBound: boolean;
  emailBound: boolean;
  googleBound: boolean;
  kakaoBound: boolean;
  recoveryEmail: string;
  recoveryEmailBound: boolean;
  tgBound: boolean;
  tradePasswordBound: boolean;
  twitterBound: boolean;
  username: string;
}

export const successCode = 8000;
const timeout = 20000;
export const botServer = axios.create({
  baseURL: import.meta.env.VITE_BOT_API,
  timeout,
}); //bot-server/
const avpimApi = axios.create({
  baseURL: import.meta.env.VITE_AVPIM_API,
  timeout,
}); //
const tomoApi = axios.create({
  baseURL: import.meta.env.VITE_TOMO_API,
  timeout,
}); //api/

const tomoTokenApi = axios.create({
  baseURL: import.meta.env.VITE_TOMO_TOKEN_API,
  timeout,
}); //api/
const tomoGatewayApi = axios.create({
  baseURL: import.meta.env.VITE_TOMO_GATEWAY_API,
  timeout,
}); //api/

const tomoWalletApi = axios.create({
  baseURL: import.meta.env.VITE_TOMO_WALLET_API,
  timeout,
}); //api/
const tomoBalanceApi = axios.create({
  baseURL: import.meta.env.VITE_TOMO_BALANCE,
  timeout,
});

export const tomoSDKApi = axios.create({
  baseURL: import.meta.env.VITE_TOMO_API_SDK,
  timeout,
}); //api/

export const authApi = axios.create({
  baseURL: import.meta.env.VITE_AUTH_API,
  timeout,
}); //tg-auth/
const avpimTomoApi = axios.create({
  baseURL: "https://avpim.tomo.inc",
  timeout,
}); //tg-auth/
const configApi = axios.create({
  baseURL: import.meta.env.VITE_CONFIG_API,
  timeout,
}); //tg-auth/
const mempoolApi = axios.create({
  baseURL: import.meta.env.VITE_MEMPOOL_API,
  timeout,
}); // mempool
const btcPushRpc = axios.create({
  baseURL: import.meta.env.VITE_BTC_PUSH_RPC,
  timeout,
}); // lorenzo

const tgStarApi = axios.create({
  baseURL: import.meta.env.VITE_STAR_API,
});

export const onrampApi = axios.create({
  baseURL: import.meta.env.VITE_TOMO_ONRAMP,
  timeout,
});

export const onrampNoAuthApi = axios.create({
  baseURL: import.meta.env.VITE_TOMO_ONRAMP,
  timeout,
});

export const moonpayApi = axios.create({
  baseURL: import.meta.env.VITE_MOONPAY_API,
  timeout,
});
export const onNewRampApi = axios.create({
  baseURL: import.meta.env.VITE_TOMO_ONRAMP_API,
  timeout,
});

export const walletServerApi = axios.create({
  baseURL: `${import.meta.env.VITE_CHAIN_API}/wallet`,
  timeout,
});
// moonpayApi.interceptors.request.use((config) => {
//   config.headers.Authorization = `Bearer ${
//     import.meta.env.VITE_MOONPAY_API_KEY
//   }`
//   return config
// })

const requestList = [
  botServer,
  avpimApi,
  tomoApi,
  authApi,
  tgStarApi,
  tomoSDKApi,
  tomoTokenApi,
  tomoGatewayApi,
  tomoWalletApi,
  tomoBalanceApi,
  onrampApi,
  walletServerApi,
];
requestList.forEach((apiItem) => {
  apiItem.interceptors.request.use(
    (config) => {
      const passKey = getPassKey();
      if (
        passKey &&
        (config.baseURL === import.meta.env.VITE_TOMO_API ||
          config.baseURL === import.meta.env.VITE_TOMO_API_SDK)
      ) {
        config.headers.mfa = passKey;
      }
      let token;
      const userState = window.localStorage.getItem("userState");
      if (userState) {
        token = JSON.parse(userState).token;
      }
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (config.baseURL === import.meta.env.VITE_TOMO_API_SDK) {
        config.headers["tomo-session-id"] = getSdkHash();
      }

      /*
         else {
          // default token
          config.headers.Authorization = `Bearer ${IS_DEV ? devToken : prodToken}`
        }
        */

      return config;
    },
    (error) => {
      if (error?.response?.status === 401) {
        return Promise.reject(error);
      }
    },
  );
  apiItem.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && !error.request.responseURL?.includes("/bot-server/sdk/payload")) {
        if (error.response.status === 401) {
          logout();
          window.location.href = "/login";
          return;
        }
        if (error.response.status === 0) {
          return Promise.reject(errorContents.serverError);
        }
      }

      return Promise.reject(error);
    },
  );

  sentryInterceptors(apiItem);
});

export const loginApi = async (query: string) => {
  const ret = await botServer.get(`login?${query}`);
  return ret.data;
};
export const loginApiV2 = async (initData: InitDataUnsafe) => {
  const ret = await tomoApi.post(`/v2/login`, initData);
  return ret.data;
};

export const loginJavaApi = async (initData: string) => {
  const res = await tomoApi.post("socialLogin/projectUser/loginByTelegramMini", {
    telegramAuthData: initData,
  });
  return res.data;
};

export async function getSocialInfo(token: string) {
  const res = await tomoApi.get("socialLogin/projectUser/socialInfo", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
}

// for ton-connect ton_proof
export const execTonProof = async (
  query: string,
  data: { domain: string; payload: string; workChain: number },
) => {
  const ret = await botServer.post(`sdk/ton/proof?${query}`, data, {
    headers: {
      "tomo-session-id": getSdkHash() || "",
    },
  });
  return ret.data;
};

export const getTransactionList: (params: PageParams) => Promise<
  {
    id: number;
    method: "in" | "out";
    value: number;
    status: "success" | "pending" | "failed";
    timestamp: number;
  }[]
> = async (params: any) => {
  // console.log('getTransactionList', { params })
  if (params.page > 3) {
    return [];
  }
  //return 10 data after 1500ms
  const statusList: ["success", "pending", "failed"] = ["success", "pending", "failed"];
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(
        Array.from({ length: 10 }).map((_, index) => ({
          id: intRandom(100, 100000),
          method: intRandom(1, 2) == 0 ? "in" : "out",
          value: Math.random() * 99999,
          status: statusList[intRandom(0, 2)],
          timestamp: new Date().getTime(),
        })),
      );
    }, 1500);
  });
};

// export const keyShareApi = async () => {
//   const ret = await tomoApi.get(`user/keyShare`, {})
//   return ret.data
// }

export type INetworkInfoType = {
  chainID: number;
  img: string;
  name: string;
  rpc: string;
};

export type INetworkType = {
  mainnet: INetworkInfoType[];
  testnet: INetworkInfoType[];
};

export const networksApi = async (): Promise<INetworkType> => {
  const ret = await botServer.get(`/networks`, {});
  return ret.data;
};

export const regKeyApi = async () => {
  const res = await avpimApi.post("pk/reg", {});
  // console.log('apiRes', res)
  return res.data;
};

export const signKeyApi = async (data: { message: string; pubkey: string }) => {
  const res = await avpimApi.post("pk/sign", data);
  return res.data;
};

export const v2PkRegApi = async (device_no: string) => {
  const res = await avpimApi.post("pk/v2/reg/" + device_no, {});
  // console.log('apiRes', res)
  return res.data;
};

export const v2PkSignApi = async (data: { message: string; device_no: string }) => {
  const res = await avpimApi.post("pk/v2/sign", data);
  return res.data;
};
export const v2PkCheckApi = async (device_no: string) => {
  const res = await authApi.get("v2/passkey/check/" + device_no);
  return res.data;
};
export const v2PkCreateApi = async (data: { device_no: string; pubkey: string }) => {
  const res = await authApi.post("v2/passkey/create/", data);
  return res.data;
};
export const v2PkValidateApi = async (data: {
  device_no: string;
  message: string;
  sig: string;
}) => {
  const res = await authApi.post("v2/passkey/validate/", data);
  return res.data;
};
export const getTradingTokens = async (): Promise<TradeToken[]> => {
  // http://fans-tech-backend.tomo.services/api
  // import.meta.env.VITE_TOMO_API
  const ret = await axios.get(`${import.meta.env.VITE_TOMO_API}wallet/tradingTokens`);
  return ret.data.result;
};

// export const getAllTokens = async (): Promise<TokenInfo[]> => {
//   const ret = await avpimApi.get(`/tg-auth/v1/token/all`)
//   return ret.data.data
// }

export const getAllBalance = async (params: {
  evm_address: string;
  solana_address: string;
  sui_address: string;
}): Promise<
  {
    balance: string;
    chain_id: number;
    contract: string;
    decimals: number;
    image: string;
    is_native: boolean;
    mercuryo_support: string;
    name: string;
    price: number;
    ramp_support: string;
    symbol: string;
    chain: string;
    display_name: string;
  }[]
> => {
  const ret = await avpimApi.get(`/tg-auth/v1/token/balance`, { params });
  return ret.data.data;
};

export const createPassKeyApi = async (data: { kind: string; pubkey: string }) => {
  const res = await authApi.post("v1/passkey/create", data);
  // console.log('apiRes', res)
  return res.data;
};

export const checkPasskeyApi = async (kind: string) => {
  try {
    const res = await authApi.get("v1/passkey/check/" + kind);

    return res.data;
  } catch (error) {
    localStorage.removeItem("user");
    localStorage.removeItem("pass-key");
    localStorage.removeItem("pubkey");
    throw error;
  }
};

export const validatePasskeyApi = async (data: { kind: string; message: string; sig: string }) => {
  const res = await authApi.post("v1/passkey/validate", data);
  return res.data;
};

export const evmSignApi = async (data: {
  tx: string;
  kind: string;
  sig: string;
  chainid: number;
}) => {
  const res = await authApi.post("v1/evm/sign", data);
  return res.data;
};

export const sendTranSactionApi = async ({ url, raw }: { url: string; raw: string }) => {
  const ret = await axios.post(url, {
    jsonrpc: "2.0",
    method: "eth_sendRawTransaction",
    id: new Date().getTime(),
    params: [raw],
  });
  return ret;
};

export const signEvmTransaction = async (
  mfa: string,
  data: {
    transaction: {
      data: string | undefined;
      gas: string | undefined;
      gasPrice: string | undefined;
      maxFeePerGas: string | undefined;
      maxPriorityFeePerGas: string | undefined;
      nonce: number | undefined;
      to: string | undefined;
      value: string | undefined;
    };
    chainId: IChainId;
  },
) => {
  const { token } = getUserInfo();
  const res = await tomoApi.post("socialLogin/projectWallet/ethereum/signTransaction", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      mfa: mfa,
    },
  });
  return res.data;
};

export const signEvmTypedData = async (
  mfa: string,
  data: {
    typedData: {
      types: {
        [key in string]: any;
      };
      domain: {
        [key in string]: any;
      };
      primaryType: string;
      message: {
        [key in string]: any;
      };
    };
    walletId: number;
  },
) => {
  const { token } = getUserInfo();
  // https://app.unyx.tech/api/wallet/ethereum/signTypedData

  const res = await tomoApi.post(
    "socialLogin/projectWallet/ethereum/signTypedData",
    { ...data, walletId: undefined },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        mfa: mfa,
      },
    },
  );
  return res.data;
};

export const sendEvmTransactionApi = async (
  mfa: string,
  data: {
    transaction: { [key: string]: any };
    chainId: number;
    rpc: string;
  },
) => {
  const { token } = getUserInfo();

  const res = await tomoApi.post("socialLogin/projectWallet/ethereum/sendTransaction", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      MFA: mfa,
    },
  });
  return res.data;
};

export const tronSignRawTransaction = async (params: SolSendTx) => {
  const { data } = await tomoApi.post(`socialLogin/projectWallet/tron/signRawTransaction`, params);
  return data;
};

export const solSignRawTransaction = async (params: SolSendTx) => {
  const { data } = await tomoApi
    .post(`/socialLogin/projectWallet/solana/signRawTransaction`, params)
    .catch((error) => {
      const message = error?.response?.data?.message;
      return Promise.reject(message ? message : errorContents.transactionError);
    });
  return data;
};

export const suiSignTransaction = async (params: { tx: string; url: string }) => {
  const { data } = await tomoApi
    .post(`/socialLogin/projectWallet/sui/signTransaction`, params)
    .catch((error) => {
      const message = error?.response?.data?.message;
      return Promise.reject(message ? message : errorContents.transactionError);
    });
  return data;
};

export const btcSignPsbt = async (params: BtcSignPsbt) => {
  const { data } = await tomoApi.post(`/socialLogin/projectWallet/bitcoin/signPsbt`, params);
  return data;
};

// send BTC
export const btcCreateSendBtcPsbt = async (params: BtcCreateSendBtcPsbt) => {
  const { data } = await tomoApi.post(
    `/socialLogin/projectWallet/bitcoin/createSendBitcoinPsbt`,
    params,
  );
  return data;
};

export const decodeInfoByHex = async (params: any) => {
  const { data } = await tomoApi.post(`/socialLogin/projectWallet/bitcoin/decode`, params);
  return data;
};

export const btcSignMessage = async (params: any) => {
  const { data } = await tomoApi.post(`/socialLogin/projectWallet/bitcoin/signMessage`, params);
  return data;
};

export const btcPushTx = async (params: BtcPushTx) => {
  const { data } = await tomoApi.post(`/socialLogin/projectWallet/bitcoin/pushTransaction`, params);
  return data;
};

export const btcRpcPushTx = async (rawTransaction: string) => {
  try {
    const res = await btcPushRpc.post("/api/tx", rawTransaction);
    return res.data;
  } catch (err) {
    console.log("req btcRpcPushTx failed: ", err);
    return "";
  }
};

export const btcSignPsbtAndPush = async (params: BtcSignPsbt) => {
  const { data } = await tomoApi.post(`/socialLogin/projectWallet/bitcoin/signPsbtAndPush`, params);
  return data;
};

export const btcAddressAndPK = async (networkType: string) => {
  try {
    const res = await tomoApi.get(
      `/socialLogin/projectWallet/bitcoin/address?networkType=${networkType}`,
    );
    return res.data;
  } catch (err) {
    console.log("req btcAddressAndPK failed: ", err);
    return {};
  }
};

export const getBtcTipHeight = async () => {
  try {
    const res = await mempoolApi.get(`/api/blocks/tip/height`);
    return res.data;
  } catch (err) {
    console.log("req getBtcHeight failed: ", err);
    return null;
  }
};

export const getBtcNetworkFees = async () => {
  try {
    const res = await mempoolApi.get(`/api/v1/fees/recommended`);
    return res.data;
  } catch (err) {
    console.log("req getBtcNetworkFees failed: ", err);
    return null;
  }
};

export const getBtcUtxos = async (address: string) => {
  if (!checkAddress(address)) return [];
  try {
    const res = await mempoolApi.get(`/api/address/${address}/utxo`);
    // const res = await btcRpc.get(`/api/address/${address}/utxo`)
    return res.data;
  } catch (err: any) {
    console.log("req getBtcUtxos failed: ", err);
    return Promise.reject(err.message);
  }
};

export const validateAddressUrl = async (address: string) => {
  try {
    const res = await mempoolApi.get(`/api/v1/validate-address/${address}`);
    return res.data;
  } catch (err) {
    console.log("req validateAddressUrl failed: ", err);
    return [];
  }
};

export const getTxInfo = async (txId: string) => {
  try {
    const res = await mempoolApi.get(`/api/tx/${txId}`);
    // const res = await btcRpc.get(`/api/tx/${txId}`)
    return res.data;
  } catch (err) {
    console.log("req getTxInfo failed: ", err);
    return [];
  }
};

export const signCosmosTransaction = async (params: ISignCosmosTxParams) => {
  try {
    const { data } = await tomoApi.post(
      `/socialLogin/projectWallet/cosmos/signTransaction`,
      params,
    );
    return data;
  } catch (err) {
    console.log("req signCosmosTransaction failed: ", err);
    return null;
  }
};

export const initSeedExport = async (): Promise<RequestType<IInitSeedExportRes> | null> => {
  try {
    const res = await tomoApi.post("/socialLogin/projectWallet/seed/initExport");
    return res.data;
  } catch (err) {
    console.log("req initSeedExport failed: ", err);
    return null;
  }
};

export const finishSeedExport = async (): Promise<RequestType<IFinishSeedExportRes> | null> => {
  try {
    const res = await tomoApi.post("/socialLogin/projectWallet/seed/finishExport");
    return res.data;
  } catch (err) {
    console.log("req finishSeedExport failed: ", err);
    return null;
  }
};

export const cancelSeedExport = async (): Promise<RequestType<boolean> | null> => {
  try {
    const res = await tomoApi.post("/socialLogin/projectWallet/seed/cancelExport");
    return res.data;
  } catch (err) {
    console.log("req cancelSeedExport failed: ", err);
    return null;
  }
};

export const isBindTotp = async (params?: any): Promise<RequestType<any> | null> => {
  try {
    const res = await tomoApi.post(`/socialLogin/projectWallet/isBindTotp`);
    return res.data;
  } catch (err) {
    console.log("req isBindTotp failed: ", err);
    return null;
  }
};

export const verifyTotp = async (params: {
  code: string;
  id: string;
}): Promise<RequestType<any> | null> => {
  try {
    const res = await tomoApi.post(
      `/socialLogin/projectWallet/verifyTotpSecret?code=${params.code}&id=${params.id}`,
    );
    return res.data;
  } catch (err) {
    console.log("req verifyTotp failed: ", err);
    return null;
  }
};

export const verifyGAEmail = async (data: { code: string }): Promise<RequestType<any> | null> => {
  try {
    const res = await tomoApi.post("/socialLogin/projectWallet/seed/initExportByEmailV2", data);
    return res.data;
  } catch (err) {
    console.log("req verifyGAEmail failed: ", err);
    return null;
  }
};

export const sendGAEmail = async (clientId?: string): Promise<RequestType<any> | null> => {
  try {
    const res = await tomoApi.get("/socialLogin/projectWallet/sendInitExportEmail", {
      params: {
        clientId,
      },
    });
    return res.data;
  } catch (err) {
    console.log("req sendGAEmail failed: ", err);
    return null;
  }
};

export const initSeedExportV2 = async (params: {
  code: string;
}): Promise<RequestType<any> | null> => {
  try {
    const res = await tomoApi.post(
      `/socialLogin/projectWallet/seed/initExport/v2?code=${params.code}`,
    );
    return res.data;
  } catch (err) {
    console.log("req initSeedExportV2 failed: ", err);
    return null;
  }
};

export const getCubistUserInfo = async (): Promise<RequestType<any> | null> => {
  try {
    const res = await tomoApi.post("/socialLogin/teleGram/cubist/userInfo");
    return res.data;
  } catch (err) {
    console.log("req getCubistUserInfo failed: ", err);
    return null;
  }
};

export const getTokenRisk = async (
  params: IGetTokenRiskParams,
): Promise<RequestType<any> | null> => {
  try {
    const res = await tomoApi.get(
      `/socialLogin/teleGram/getTokenRisk?chain=${params.chain}&address=${params.address}`,
    );
    return res.data;
  } catch (err) {
    // console.log('req getTokenRisk failed: ', err)
    return null;
  }
};

export const ReferralApi = async () => {
  const { data } = await authApi.get(`/v1/referral`);
  return data;
};

export const ReferralBindApi = async (code: string) => {
  const { data } = await authApi.post(`/v1/referral/bind/${code}`);
  return data;
};

export const ReferralBindApiV2 = async (code: string) => {
  const { data } = await tomoApi.post(`socialLogin/teleGram/bindInviteCode`, {
    inviteCode: code,
  });
  return data;
};

// https://tg-dev.tomo.inc/fans-compose-es-backend/api/v3/memes-trading?chain=56&page=1&size=10&keyword=1inch

export const getSwapAllTokens = async (queryParameters: ISwapTokensParams): Promise<any> => {
  const ret = await avpimApi.get(
    `/fans-compose-es-backend/api/v3/memes-trading`,
    // `/wallet/tokens/search`,
    {
      params: queryParameters,
    },
  );
  return ret.data;
};

// /api/token/tag/list

export const getMarketTabs = async (): Promise<{
  result: {
    id: number;
    name: string;
    sort: number;
  }[];
}> => {
  const ret = await axios.get(`${import.meta.env.VITE_TOMO_API}token/tag/list`, {});

  return ret.data;
};

export const getMarketTabList = async ({
  tagId,
  page,
  size,
}: {
  tagId: number;
  page?: number;
  size?: number;
}): Promise<{
  result: {
    records: MarketConfigToken[];
    total: number;
    size: number;
    current: number;
    pages: number;
  };
}> => {
  // MarketConfigToken
  const ret = await axios.get(`${import.meta.env.VITE_TOMO_API}token/tag/${tagId}`, {
    params: { page, size },
  });
  return ret.data;
};

// /api/

export const getSwapAllTokensV2 = async (): Promise<{
  result: ISwapTokenTypeV2[];
}> => {
  const ret = await tomoApi.get(`socialLogin/teleGram/wallet/tokens`, {});
  return ret.data;
};

/*
http://fans-tech-backend.tomo.services/api
*/
export const getSwapAllTokensV3 = async (params: {
  chain: string;
}): Promise<{
  result: ISwapTokenTypeV2[];
}> => {
  // hack start...
  if (params.chain === "BERACHAIN_MAINNET") {
    return {
      // @ts-ignore
      code: 10000,
      message: "Success",
      result: beraChainTokens.map((token, index) => {
        token.chainId = 80094;
        token.chain = "BERACHAIN_MAINNET";
        token.createdTime = "2024-05-30T06:49:59.000+00:00";
        token.fdvUsd = 0;
        token.groupId = "token-berachain";
        token.id = index;
        token.imageUrl = "";
        token.marketCapUsd = "";
        token.priceChangeH24 = "";
        token.priceUsd = "";
        token.totalSupply = "";
        token.updatedTime = "2025-02-27T09:32:37.000+00:00";
        token.volumeH24 = "";
        return token;
      }),
    };
  }
  // ...hack end

  const ret = await axios.get(`${import.meta.env.VITE_TOMO_API}token/swapList`, {
    params,
  });
  return ret.data;
};

export const getSwapFourMemeSearch = async (queryParameters: {
  content: string;
  chain?: string;
}): Promise<{
  result: ISwapTokenTypeV2[];
}> => {
  const params = { content: queryParameters.content };
  if (queryParameters.chain) {
    Object.assign(params, { chain: queryParameters.chain });
  }

  const ret = await tomoTokenApi.get(
    `/v1/wallet/token/search`,
    // `/wallet/tokens/search`,
    {
      params,
    },
  );
  const currentData = ret.data.data.map((item: ISwapTokenTypeV2) =>
    item.fourMemeToken && !item.launchOnPancake
      ? {
          ...item,
          chain: "BSC",
          name: `${item.fourMemeToken && !item.launchOnPancake ? "FOURMEME" : "BSC"}-${item.address}`,
        }
      : item,
  );
  return {
    result: currentData,
  };
};

export const getFourMemeMarketList = async ({
  status,
  launchOnPancake,
  orderByField,
  orderByRule,
}: {
  status: "new" | "early" | "published";
  launchOnPancake: boolean;
  orderByField?: string;
  orderByRule?: "ASC" | "DESC";
}): Promise<ISwapTokenTypeV2[]> => {
  // 构建基础参数
  const params: any = {
    status,
    launchOnPancake,
  };

  // 只有当 orderByField 存在时才添加排序参数
  if (orderByField) {
    params.orderByField = orderByField;
    params.orderByRule = orderByRule;
  }

  const ret = await tomoTokenApi.get(`/v1/wallet/token/query/meme`, {
    params,
  });
  return ret.data.data;
};

export const getSwapAllTokensV2Search = async (queryParameters: {
  content: string;
  chain?: string;
}): Promise<{
  result: ISwapTokenTypeV2[];
}> => {
  // Only try getSwapFourMemeSearch if content exists
  if (queryParameters.content) {
    const fourMemeTokens = await getSwapFourMemeSearch(queryParameters);

    // If fourMemeTokens has results, return them
    if (fourMemeTokens.result?.length > 0) {
      return fourMemeTokens;
    }
  }

  // Fallback to normal token search
  const normalTokens = await axios.get(`${import.meta.env.VITE_TOMO_API}wallet/tokens/search`, {
    params: queryParameters,
  });

  return {
    result: normalTokens.data.result || [],
  };
};

export const v1AddAssetApi = async (data: {
  chain_id: number;
  decimals: number;
  image?: string;
  name?: string;
  symbol: string;
  token: string;
}) => {
  const res = await authApi.post("v1/asset/add", data);
  // console.log('apiRes', res)
  return res.data;
};
export const v1AllAssetApi = async (params: {
  page: number;
  pageSize: number;
  chain_ids?: number[];
}) => {
  const res = await authApi.get("v1/asset/all", { params });
  return res.data.data;
};
export const stableTokenApi = async () => {
  try {
    const res = await axios.get(`${import.meta.env.VITE_TOMO_API}chain/stable_coin`);
    return res.data;
  } catch (e) {
    console.warn("get stable coin error", e);
  }
  return undefined;
};

// http://fans-tech-backend.tomo.services/apisocialLogin/projectWallet/ethereum/signMessage
// sendTransaction

export const tonSignMessage = async (
  mfa: string,
  data: { signingMessageBoc: string; stateInitBoc: string; isTestnet?: boolean },
) => {
  const { token } = getUserInfo();

  const res = await tomoApi.post(
    "/socialLogin/projectWallet/ton/signTransaction",
    {
      ...data,
      isTestnet: typeof data.isTestnet === "boolean" ? data.isTestnet : false,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        MFA: mfa,
      },
    },
  );
  return res.data;
};

export const getTotalPoint = async (): Promise<{ result: string }> => {
  const ret = await tomoApi.get(`socialLogin/teleGram/totalPoint`);
  return ret.data;
};

export const getTotalPointV2 = async (): Promise<{ result: string }> => {
  const ret = await tomoApi.get(`socialLogin/teleGram/v2/totalPoint`);
  return ret.data;
};

export const getInviteCode = async (): Promise<{
  result: {
    inviteCode: string;
    bindFlag: boolean;
  };
}> => {
  const res = await tomoApi.get(`socialLogin/teleGram/myInviteCode`);
  return res.data;
};

export const getInviteCount = async (): Promise<{
  result: string;
}> => {
  const res = await tomoApi.get(`socialLogin/teleGram/inviteTotalNum`);
  return res.data;
};

export const getReferralInfo = async (): Promise<{
  userId: number;
  totalRefund: string;
  inviteCount: number;
}> => {
  const res = await tomoApi.get("/socialLogin/teleGram/referral/info");
  return res.data?.result;
};

export const getInvitedUserList = async (): Promise<
  {
    avatar: string;
    inviteCount: number;
    nickname: string;
    totalAttribute: string; //totalRefund
    totalRefund: string;
    username: string;
  }[]
> => {
  // 第一页不传cusor，第二页传的cursor是第一页返回的
  // 如果有第二页，cursor传第一页的响应里面回传的cursor
  //  cursor: 1,
  const res = await tomoApi.get(`socialLogin/teleGram/v2/inviteList`, {
    params: { size: 2000 },
  });
  return res?.data?.result;
};

export interface Task {
  completed: boolean;
  name: string;
  point: string;
}
export const getTasksStatus = async (): Promise<Record<string, Task[]>> => {
  const res = await tomoApi.get(`socialLogin/teleGram/v2/task/status`);
  return res.data.result;
};

export const submitRamp = async (symbol: string): Promise<any> => {
  const res = await tomoApi.post(`socialLogin/teleGram/task/ramp?value=${symbol}`);
  return res.data.result;
};

export type SocialMediaPlatform = "x" | "ig" | "tiktok";

export const submitFollowSocialMedia = async (platform: SocialMediaPlatform): Promise<any> => {
  const res = await tomoApi.post(`socialLogin/teleGram/task/follow?value=${platform}`);
  return res.data.result;
};

export enum NewTaskType {
  FollowTgGroup = 1,
  FollowTgChannel = 2,
  FollowTomoWalletOnX = 3,
  BotLaunchGroup = 4,
}

export const submitFollowTg = async (taskType: NewTaskType): Promise<any> => {
  const res = await tomoApi.post(`socialLogin/teleGram/finishTgTask`, {
    taskType,
  });
  return res.data.result;
};

export const submitSwap = async (symbol: string): Promise<any> => {
  const res = await tomoApi.post(`socialLogin/teleGram/task/swap?value=${symbol}`);
  return res.data.result;
};

export const submitBot = async (symbol: string): Promise<any> => {
  const res = await tomoApi.post(`socialLogin/teleGram/task/toBot?value=${symbol}`);
  return res.data.result;
};

export const getSwapPoint = async (): Promise<string> => {
  const res = await tomoApi.get("/socialLogin/teleGram/swapPoint");
  return res?.data?.result;
};

export const getInvitePoint = async (): Promise<string> => {
  const res = await tomoApi.get("/socialLogin/teleGram/invitePoint");
  return res?.data?.result;
};

export const getSwapFeeAddress = async (): Promise<SwapFeeAddress> => {
  const res = await tomoApi.get("/socialLogin/teleGram/wallet/swapFeeBackAddress");
  return res?.data?.result;
};

export const postSwapPoint = async (data: {
  fromToken: string;
  fromChain: string;
  fromContract: string;
  toToken: string;
  toChain: string;
  toContract: string;
  amount: string;
  priceUsd: string;
  txHash: string;
  refundRate: number;
  providerType?: string | undefined; // 'okx' | 'rango'
  sourceChainId?: string; // type string from chainid number
  targetChainId?: string; // type string to chainid number
  rangoRequestId?: string; //  rango request id
  rangoStep?: 0;
}): Promise<string> => {
  const res = await tomoApi.post("/socialLogin/teleGram/swap/record", data);
  return res?.data?.result;
};

export const postSendPoint = async (data: {
  chainId: string;
  txId: string;
  senderUserId: number;
  senderAddress: string;
  receiverAddress: string;
  amount: string; // bigint string
  tokenContract: string;
  decimals: number;
  symbol: string;
  priceUsd: number;
}): Promise<string> => {
  const res = await tomoApi.post("/socialLogin/teleGram/send/record", data);
  return res?.data?.result;
};

export const getTrendingTokens = async (params?: { chain?: string }) => {
  // const { data } = await tomoApi.get(`wallet/trendingTokens`)
  const { data } = await tomoApi.get<Result<TrendingToken[]>>(
    `/socialLogin/teleGram/wallet/trendingTokens`,
    {
      params: {
        ...params,
        page: 1,
        size: 200,
      },
    },
  );
  return data.result;
};
export const getAllTokens = async () => {
  const { data } = await tomoApi.get<Result<TrendingToken[]>>(
    `/socialLogin/teleGram/wallet/tokens`,
    {
      params: {
        page: 1,
        size: 200,
      },
    },
  );
  return data.result;
};

export const getSelectedTokens = async () => {
  const { data } = await tomoApi.get<Result<TrendingToken[]>>(
    `/socialLogin/teleGram/wallet/selectedTokens`,
    {
      params: {
        page: 1,
        size: 200,
      },
    },
  );
  return data.result;
};

export const getTokenByName = async (tokenName: string) => {
  const isFourMemeToken = tokenName.startsWith("FOURMEME-");

  const processedTokenName = isFourMemeToken
    ? "BSC" + tokenName.slice("FOURMEME".length)
    : tokenName;

  const { data } = isFourMemeToken
    ? await tomoTokenApi.get<DataResult<TrendingToken>>(`v1/wallet/token/meme/detail`, {
        params: { tokenName: processedTokenName },
      })
    : await tomoApi.get<Result<TrendingToken>>(`/socialLogin/teleGram/getByName`, {
        params: { tokenName },
      });

  return isFourMemeToken ? data.data : data.result;
};

export const getTokenSearch = async (content: string, chain: string) => {
  // Only try getSwapFourMemeSearch if content exists
  if (content) {
    const fourMemeTokens = await getSwapFourMemeSearch({ content, chain });

    // If fourMemeTokens has results, return them
    if (fourMemeTokens.result?.length > 0) {
      return fourMemeTokens.result;
    }
  }

  // Fallback to normal token search
  const normalTokens = await tomoApi.get<Result<TrendingToken[]>>(
    `/socialLogin/teleGram/wallet/tokens/search`,
    {
      params: chain ? { chain, content } : { content },
    },
  );

  return normalTokens.data.result || [];
};

export const getFourMemeTransactions = async (address: string) => {
  const { data } = await tomoWalletApi.post<DataResult<FourMemeTransaction>>(
    `/v1/wallet/meme/queryTransactions`,
    {
      chainIndex: 5600,
      address,
    },
  );

  return data.data;
};

export type IntervalType =
  | "hour"
  | "four_hour"
  | "day"
  | "week"
  | "month"
  | "minute"
  | "five_minute"
  | "fifteen_minute";

export const getTokenPrices = async (tokenName: string, interval: IntervalType) => {
  const isFourMemeToken = tokenName.startsWith("FOURMEME-");
  const processedTokenName = isFourMemeToken ? tokenName.slice("FOURMEME-".length) : tokenName;

  const { data } = isFourMemeToken
    ? await tomoWalletApi.post<DataResult<PriceItem[]>>(`/v1/wallet/meme/queryKline`, {
        address: processedTokenName,
        chainIndex: 5600,
        interval: convertToIntervalType(interval),
      })
    : await tomoApi.get<Result<PriceItem[]>>(`/socialLogin/teleGram/${processedTokenName}/OHLCV`, {
        params: { interval: interval.toLocaleUpperCase() },
      });

  const result = isFourMemeToken
    ? data.data?.map((item: PriceItem) => ({
        ...item,
        timestamp: item.timestamp ? Number(item.timestamp) * 1000 : undefined,
      }))
    : data.result;
  return result as PriceItem[];
};

export const getMemesOverview = async (chain: string, address: string) => {
  const { data } = await tomoApi.get<Result<MemeOverview>>(
    `/socialLogin/projectWallet/token/overview`,
    {
      params: {
        address,
        chain,
      },
    },
  );
  return data.result;
};

export const getFavouriteTokens = async () => {
  const { data } = await tomoApi.get<Result<TrendingToken[]>>(
    `/socialLogin/teleGram/wallet/favouriteTokens`,
    {
      params: {
        page: 1,
        size: 200,
      },
    },
  );
  return data.result;
};
// import.meta.env.VITE_TOMO_API
export const getBinanceAlphaTokens = async () => {
  // const { data } =
  //   await tomoApi.get<Result<TrendingToken[]>>(`/token/binanceAlpha`)

  const { data } = await axios.get<Result<TrendingToken[]>>(
    `${import.meta.env.VITE_TOMO_API}token/binanceAlpha`,
  );
  return data.result;
};

export const favouriteTokens = async (tokens: string[]) => {
  const { id } = getUserInfo();
  const { data } = await tomoApi.post<Result<TrendingToken[]>>(
    `/socialLogin/teleGram/wallet/favouriteTokens`,
    {
      params: {
        page: 1,
        size: 200,
      },
      userId: id,
      tokens,
    },
  );
  return data.result;
};

export const checkPaymentPasswd = async () => {
  const res = await authApi.get("v1/payment/passwd/check", {});
  return res.data;
};
export const setPaymentPasswd = async (data: { passwd: string }) => {
  const res = await authApi.post("v1/payment/passwd/set", data);
  return res.data;
};
export const changePaymentPasswd = async (data: { old_passwd: string; new_passwd: string }) => {
  const res = await authApi.post("v1/payment/passwd/change", data);
  return res.data;
};
export const validatePaymentPasswd = async (data: { passwd: string }) => {
  const res = await authApi.post("v1/payment/passwd/validate", data);
  return res.data;
};
export const bindEmailCodeVerify = async (params: { email: string; code: string }) => {
  const { data } = await tomoApi.post(
    // `/socialLogin/projectUser/bindEmailCodeVerify`,
    // `/socialLogin/projectUser/v2/bindEmailCodeVerify`,
    `/socialLogin/projectUser/bindRecoverEmailCodeVerifyToken`,
    params,
  );
  return data;
};
export const bindEmailCodeSend = async (email: string) => {
  const { data } = await tomoApi.get(
    // `/socialLogin/projectUser/bindEmailCode?email=${email}`
    // `/socialLogin/projectUser/v2/bindEmailCode?email=${email}`
    `/socialLogin/projectUser/bindRecoverEmailCode?email=${email}`,
  );
  return data;
};

export const getConfigChainsAll = async (): Promise<{
  chain_id_name: {
    [key in IChainId]?: string;
  };
}> => {
  const { data } = await configApi.get(`/chain/all`);
  return data.result;
};

// https://app.tomo.inc/api/socialLogin/teleGram/wallet/tokens/v2/preciseSearch?address=0x9eC02756A559700d8d9e79ECe56809f7bcC5dc27&chain=ETH

export const getSwapTokenByTokenInfo = async (params: {
  address: string;
  chain: string;
}): Promise<ISwapTokenTypeV2[]> => {
  const loggerNoResult = () => {
    logger.error(
      "Swap Tokens",
      "select token find failed",
      new Error(`chain: ${params.chain},address: ${params.address}`),
    );
  };
  // Only try getSwapFourMemeSearch if address exists
  if (params.address) {
    const fourMemeTokens = await getSwapFourMemeSearch({
      content: params.address,
      chain: params.chain,
    });

    // If fourMemeTokens has results, return them
    if (fourMemeTokens.result?.length > 0) {
      return fourMemeTokens.result;
    }
  }

  // Fallback to normal token search
  const normalTokens = await tomoApi.get("/socialLogin/teleGram/wallet/tokens/v2/preciseSearch", {
    params,
  });
  if (normalTokens.data.result.length === 0) {
    loggerNoResult();
  }
  return normalTokens.data.result || [];
};

// login and register
export const reLoginEmailSend = async () => {
  const res = await authApi.get("v1/email/send", {});
  return res.data;
};
export const reLoginEmailVerify = async (data: { device_no: string; code: string }) => {
  const res = await authApi.post("v1/email/verify", data);
  return res.data;
};
export const reLoginIncreaseAuth = async (data: {
  deadline: number;
  device_no: string;
  encry_data: string;
}) => {
  const res = await avpimApi.post("pk/v2/increase", data);
  return res.data;
};
export const reLoginPassIncrease = async (data: {
  deadline: number;
  device_no: "string";
  encry_data: "string";
  pubkey: "string";
}) => {
  const res = await authApi.post("v2/passkey/increase", data);
  return res.data;
};
export const tradePaswordStatus = async () => {
  const ret = await tomoApi.get(`socialLogin/teleGram/user/tradePaswordStatus`, {});
  return ret.data;
};
export const sendTradePwdEmail = async () => {
  const ret = await tomoApi.get(`socialLogin/teleGram/user/sendTradePwdRecoverEmail`, {});
  return ret.data;
};
export const firstSetTradePasword = async (data: { newTradePassword: string }) => {
  const ret = await tomoApi.post(`socialLogin/teleGram/user/firstSetTradePasword`, data);
  return ret.data;
};
export const setTradePasword = async (data: {
  oldTradePassword: string;
  newTradePassword: string;
}) => {
  const ret = await tomoApi.post(`socialLogin/teleGram/user/setTradePasword`, data);
  return ret.data;
};

export const mfaAuthVerificationApi = async (data: any) => {
  console.log({
    key: "sendSol-mfaAuthVerificationApi",
  });
  const ret = await tomoApi.post(`socialLogin/mfa/auth/verification`, data);
  return ret.data;
};
export const mfaAuthVerificationApiV2 = async (data: any) => {
  const ret = await tomoApi.post(`socialLogin/mfa/auth/verification/v2`, data);
  return ret.data;
};

export const verifyTradeEmail = async (code: string) => {
  const ret = await tomoApi.post(`socialLogin/teleGram/user/verifyTradeEmail`, {
    code,
  });
  return ret.data;
};
export const getTelegramUserInfoApi = async () => {
  const res = await tomoApi.get(`socialLogin/teleGram/userInfo`);
  return res.data;
};
export const getDefaultWalletAddressApi = async (userId: number) => {
  const res = await tomoApi.get(
    `socialLogin/projectWallet/getDefaultWalletByUserId?userId=${userId}`,
  );
  return res.data;
};
export const getOkxWalletAccountApi = async () => {
  const res = await tomoApi.get("socialLogin/projectWallet/okxWalletAccount");
  return res.data;
};
export const resetTradePwdEmail = async (data: { code: string; tradePassword: string }) => {
  const ret = await tomoApi.post(`socialLogin/teleGram/user/resetTradePasswordRecoverEmail`, data);
  return ret.data;
};
export const increaseWithpaypin = async (data: { device_no: string; passwd: string }) => {
  const res = await authApi.post("v1/paypin/verify", data);
  return res.data;
};

export const getInviteUserAddressInfo = async (): Promise<RequestType<UserAddressType>> => {
  const ret = await tomoApi.get(`socialLogin/teleGram/inviteUserAddressInfo`);
  return ret.data;
};

/*
 * heart report api
 **/
export const heartBeatPost = async () => {
  const res = await tomoApi.post("socialLogin/projectUser/heartbeat");
  return res.data;
};

/*
 * token list report api
 **/
export const resourceReportPost = async (
  data: {
    address: string;
    amount: string;
    chainID: number;
    native: boolean;
    symbol: string;
    token: string;
    userID: number;
    chainName: string;
  }[],
) => {
  const res = await avpimApi.post("tg-report/v1/report/address/resource", data);
  return res.data;
};

/*
 * tx report api
 **/
export const txReportPost = async (data: {
  chainID: number;
  gas: string;
  tx: string;
  type: "swap" | "send";
  userID: number;
  source: string;
}) => {
  const res = await avpimApi.post("tg-report/v1/report/tx/cost", data);
  return res.data;
};

/*
 * tx list api
 **/
export const txReportListGet = async (params: { page: number; limit: number; userID: number }) => {
  const res = await avpimApi.get("tg-report/v1/report/tx/cost", { params });
  return res.data;
};

export const getAllTokenBalancesByAccount = async (params: {
  accountId: string;
  chains: string; //1,56,42161,8453,81457,43114,137,534352,10,59144,195,501
  filter?: "0" | "1"; //filter risk token
}): Promise<AllTokenBalancesByAddressRes[]> => {
  console.log(params, "params");
  const ret = await tomoApi.get("socialLogin/projectWallet/getOkxWalletAccountTokenBalances", {
    params,
    paramsSerializer: function (params) {
      return new URLSearchParams(params).toString();
    },
  });
  return ret.data.result[0].tokenAssets;
};

export const getAllHistoryByAccount = async (params: {
  accountId: string;
  begin?: string | undefined;
  end?: string | undefined;
  cursor?: string | undefined;
  chainIndex?: string | undefined;
}): Promise<{ cursor: string; transactionList: IOKXHistoryType[] }> => {
  const ret = await tomoApi.get("socialLogin/projectWallet/getOkxWalletAccountTransaction", {
    params,
    paramsSerializer: function (params) {
      return new URLSearchParams(params).toString();
    },
  });
  return ret.data.result[0];
};

/**
 * chat member count.
 */
export const chatMemberCount = async () => {
  const res = await botServer.get("public/api/chat_members_count");
  return res.data;
};
/**
 * chat member status.
 */
export const chatMemberStatus = async (tgId: string | number) => {
  const res = await botServer.get(`public/api/chat_member_status?telegram_id=${tgId}`);
  return res.data;
};
export const botMemberStatus = async (tgId: string | number) => {
  const res = await botServer.get(`public/api/bot_member_status?telegram_id=${tgId}`);
  return res.data;
};

export const getGiftShareMessageId = async ({
  shareId,
  tgId,
  tgName,
}: {
  shareId: string;
  tgId: number;
  tgName: string;
}) => {
  try {
    const { data } = await botServer.post("/gift", {
      share_id: shareId,
      sender_id: tgId,
      sender_name: tgName,
    });
    return data?.data?.message_id;
  } catch (e) {
    return null;
  }
};

export const getTokenShareMessageId = async (body: {
  image: any;
  symbol: string;
  sender_name: string;
  sender_id: string;
  redirect_url: string;
  price: string;
  gain: string;
  cap: string;
  liquidity: string;
}) => {
  try {
    const formData = new FormData();

    formData.append("image", body.image);
    formData.append("symbol", body.symbol);
    formData.append("sender_name", body.sender_name);
    formData.append("sender_id", body.sender_id);
    formData.append("redirect_url", body.redirect_url);
    formData.append("price", body.price);
    formData.append("gain", body.gain);
    formData.append("cap", body.cap);
    formData.append("liquidity", body.liquidity);

    const { data } = await botServer.post("/token_share_message", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data?.data?.message_id || "";
  } catch (e) {
    return null;
  }
};

export const getAppShareMessageId = async (body: { sender_id: number; share_code: string }) => {
  try {
    const { data } = await botServer.post("/app_share_message", body);
    return data?.data?.message_id || "";
  } catch (e) {
    return null;
  }
};

export const getTokenDetailByAddress = async (chain: string, address: string) => {
  const { data } = await tomoApi.get("/socialLogin/projectWallet/token/getByAddressAndChain", {
    params: { chain, address },
  });
  return data.result;
};

export const getTomoChains = async (): Promise<TomoChain[]> => {
  const ret = await axios.get(`${import.meta.env.VITE_TOMO_API}chain/chain_info?platform=TG`);
  return ret.data;
};

export const createStarInvoice = async (
  body: CreateStarInvoiceReqType,
): Promise<CreateStarInvoiceResType> => {
  try {
    const { data } = await tgStarApi.post("/api/v1/tg/swapStars", body);
    switch (data?.code) {
      case 0:
        return data?.data;
      default:
        return Promise.reject(data);
    }
  } catch (e) {
    return Promise.reject();
  }
};

export const getStarOrderList = async (
  body: getStarOrderListReqType,
): Promise<getStarOrderListResType> => {
  try {
    const { data } = await tgStarApi.post("/api/v1/tg/pageQueryOrders", body);
    return data.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const singDogeTx = async (params: DogeTxRes) => {
  const { data } = await tomoApi.post(`/socialLogin/projectWallet/doge/signTransaction`, params);
  return data;
};

export const singMovementTx = async (params: { rawTransaction: SimpleTransaction }) => {
  const { data } = await tomoApi.post(
    `/socialLogin/projectWallet/movement/signTransaction`,
    params,
  );
  return data;
};

export const getMovementPublicKey = async () => {
  const { data } = await tomoApi.get(`/socialLogin/projectWallet/movement/getPublicKey`);
  return data;
};

export const aiFeedPlatformLogin = async () => {
  try {
    return (await tomoApi.post(`/socialLogin/projectUser/platformLogin`, {})).data;
  } catch (e) {
    console.log(e);
  }
};

export const getSolRent = async (data: getSolRentReq): Promise<getSolRentRes> => {
  try {
    const res = await walletServerApi.post("/v1/sol/rent", data);
    return res?.data?.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTokenBalancesByTomo = async (params: {
  walletId: string;
}): Promise<TomoBalancesByWalletId[] | undefined> => {
  try {
    const ret = await tomoBalanceApi.get("v1/balance/getTokenBalance", {
      params,
      paramsSerializer: function (params) {
        return new URLSearchParams(params).toString();
      },
    });
    return ret.data?.data?.balance || undefined;
  } catch (e) {
    console.warn("get balance error", e);
  }
  return undefined;
};

export const getTokenInfoByTomo = async (params: {
  chainIndex: number;
  address: string;
}): Promise<TomoTokenInfo | undefined> => {
  try {
    const ret = await tomoBalanceApi.get("v1/balance/getTokenInfo", {
      params,
      paramsSerializer: function (params) {
        return new URLSearchParams(params).toString();
      },
    });
    return ret.data?.data;
  } catch (e) {
    console.warn("get balance error", e);
  }
  return undefined;
};

export const addTokenByTomo = async (data: {
  walletId: string;
  chainIndex: number;
  address: string;
}) => {
  const res = await tomoBalanceApi.post("v1/customTokens/addCustomToken", data);
  return res.data;
};

export const generateWalletByTomo = async (params: { userId: number }) => {
  try {
    const res = await tomoApi.get("/api/socialLogin/projectWallet/generateWalletId", {
      params,
    });
    return res?.data?.result;
  } catch (e) {
    return Promise.reject("failed to generate walletId");
  }
};
