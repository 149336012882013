import useBackup from "@/pages/login/hooks/useBackup";
import { IWeb3ChainType } from "proviers/web3Provider/type";
import { getBitCoinType, IBtcAddressType } from "utils";
import { TIcon, TIconButton, TCopy } from "@/components/tmd";
import toast from "@/components/tmd/toast/Toast";
import { useTranslation } from "react-i18next";

const ShowAccountItem = (params: {
  chain: IWeb3ChainType;
  address: string | undefined;
  type: IBtcAddressType | undefined;
  openQrcode: (flag: boolean) => void;
}) => {
  const { t } = useTranslation();

  const handleOpenQRCode = () => {
    params.openQrcode(true);
  };

  const { setOpen, component } = useBackup({
    onSkip: () => {
      setOpen(false);
    },
  });

  return (
    <>
      {component}
      <div className="flex min-h-[73px] w-full items-center justify-between gap-3 rounded-[12px] bg-bg1 px-[14px] py-[16px]">
        <div className="flex flex-1 items-center justify-start gap-[8px] overflow-hidden">
          <div className="flex flex-none items-center justify-start gap-[12px]">
            <img className="size-[36px]" src={params.chain.icon} />
          </div>
          <div className="flex flex-col gap-[2px] overflow-hidden">
            <div className="flex items-center">
              <span className="text-base font-medium text-t1">{params.chain.name}</span>
              {params.type && (
                <div className={`ml-[4px] flex h-[16px] items-center rounded-[2px] bg-bg3 px-[4px] py-[2px]`}>
                  <span className="text-df text-t2">{getBitCoinType(params.type)}</span>
                </div>
              )}
            </div>
            <div className="flex items-center justify-start gap-[4px]">
              <span className="max-w-full break-words text-xs text-t2">{params.address}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-none gap-3">
          {params.address && (
            <TIconButton className="size-[38px]">
              <TCopy
                text={params.address || ""}
                iconFontSize="18"
                onCopy={() => toast.success(t("tg_wallet.copied_to_clipboard"))}
              />
            </TIconButton>
          )}

          <TIconButton className="size-[38px]" onClick={handleOpenQRCode}>
            <TIcon name="tg_wallet_QRcode" className="text-t1" fontSize="18" />
          </TIconButton>
        </div>
      </div>
    </>
  );
};

export default ShowAccountItem;
