import { getTokenRisk } from '@/api'
import { IGetTokenRiskParams } from '@/api/type'
import { TToast, useTranslation } from '@/components/tmd'
import { mockSolEvmChainId } from '@/config/sol'
import { prodEvmChains } from '@/proviers/web3Provider/chains'
import useChains from '@/stores/tokenStore/hooks/useChains'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import { getCache, setCache, STORAGE_KEY } from '@/utils/cacheManage'

const gapMinutes = 60

export const isSolanaOrEvmChain = (chainId?: number) => {
  if (!chainId) return false
  const isSolana = chainId === mockSolEvmChainId
  const isEvm = prodEvmChains.find((v) => v.id === chainId)
  return isSolana || isEvm
}

export function getDefaultRiskInfoRes() {
  return { markType: null, rules: [] }
}

const useRiskToken = () => {
  const { t } = useTranslation()
  const { getChainNameById } = useChains()

  const getTokenRiskFormatInfo = async (
    params: IGetTokenRiskParams
  ): Promise<IRiskRes> => {
    const data = await getTokenRiskData(params)
    const res: IRiskRes = getDefaultRiskInfoRes()
    if (!data) return res
    const riskDescList = getRiskTypeDescList(data)
    const warnDescList = getWarnTypeDescList(data)
    const isRisk = isShowRiskMark(data, riskDescList)
    const isWarn = isShowWarnMark(data, warnDescList)
    const showMarkType = isRisk
      ? riskTypes.risk
      : isWarn
        ? riskTypes.warn
        : null
    const riskMeta = {
      type: riskTypes.risk,
      typeText: t('risk.risk'),
      list: riskDescList
    }
    const warnMeta = {
      type: riskTypes.warn,
      typeText: t('risk.warn'),
      list: warnDescList
    }
    res.markType = showMarkType
    res.rules.push(riskMeta, warnMeta)
    return res
  }

  const getTokenRiskData = async (params: IGetTokenRiskParams) => {
    const tokenKey = getStorageTokenKey(params)
    const storageInfo = getTokenRiskFromStorage(tokenKey)

    if (storageInfo?.timestamp + gapMinutes * 60 * 1000 > Date.now()) {
      return storageInfo
    }

    return await getTokenRiskFromServer(params)
  }

  const getTokenRiskFromStorage = (key: string) => {
    const storage = getCache(STORAGE_KEY.RISK_TOKEN_RISK_INFO, {})
    const storageInfo = storage[key]
    if (!storageInfo) return null

    return storageInfo
  }

  const getTokenRiskFromServer = async (params: IGetTokenRiskParams) => {
    const data = await getTokenRisk(params)
    if (!data?.result) {
      saveToStorageCache(params, {})
      return fireErr(data?.message, { showToast: false })
    }
    saveToStorageCache(params, data.result)
    return data.result
  }

  const saveToStorageCache = (
    params: IGetTokenRiskParams,
    riskInfo?: Partial<IRiskData>
  ) => {
    const tokenKey = getStorageTokenKey(params)
    const storage = getCache(STORAGE_KEY.RISK_TOKEN_RISK_INFO, {})
    const newRiskInfo = { ...riskInfo, timestamp: Date.now() }
    storage[tokenKey] = newRiskInfo
    setCache(STORAGE_KEY.RISK_TOKEN_RISK_INFO, storage)
  }

  const getStorageTokenKey = (params: IGetTokenRiskParams) => {
    return `${params.chain}-${params.address}`
  }

  const getRiskTypeDescList = (data: IRiskData) => {
    const list: string[] = []
    if (data.risk?.ownerChangeBalance) {
      list.push(t('risk.ownerChangeBalance'))
    }
    if (data.risk?.balanceMutableAuthority) {
      list.push(t('risk.ownerChangeBalance'))
    }
    if (data.risk?.externalCall) {
      list.push(t('risk.externalCall'))
    }
    if (data.risk?.transferHook) {
      list.push(t('risk.externalCall'))
    }
    if (data.risk?.hiddenOwner) {
      list.push(t('risk.hiddenOwner'))
    }
    if (data.risk?.maliciousAddress) {
      list.push(t('risk.hiddenOwner'))
    }
    if (data.risk?.isProxy) {
      list.push(t('risk.isProxy'))
    }
    if (data.risk?.isOpenSource) {
      list.push(t('risk.isOpenSource'))
    }
    if (data.risk?.isHoneypot) {
      list.push(t('risk.isHoneypot'))
    }
    if (data.risk?.nonTransferable) {
      list.push(t('risk.isHoneypot'))
    }
    if (data.risk?.buyTax) {
      list.push(`${t('risk.isHoneypot')} > 0.5`)
    }
    if (data.risk?.sellTax) {
      list.push(`${t('risk.sellTax')} > 0.5`)
    }
    if (data.risk?.feeRate) {
      list.push(`${t('risk.feeRate')} > 0.5`)
    }
    return list
  }

  const getWarnTypeDescList = (data: IRiskData) => {
    const list: string[] = []
    const buyTaxRes = warnTax(data.warn?.buyTax, t('risk.buyTax'))
    const sellTaxRes = warnTax(data.warn?.sellTax, t('risk.sellTax'))
    if (buyTaxRes) {
      list.push(buyTaxRes)
    }
    if (sellTaxRes) {
      list.push(sellTaxRes)
    }
    if (data?.warn?.cannotSellAll) {
      list.push(t('risk.cannotSellAll'))
    }
    if (data?.warn?.slippageModifiable) {
      list.push(t('risk.slippageModifiable'))
    }
    if (data?.warn?.personalSlippageModifiable) {
      list.push(t('risk.personalSlippageModifiable'))
    }
    if (data?.warn?.transferFeeUpgradable) {
      list.push(t('risk.slippageModifiable'))
    }
    if (data?.warn?.transferPausable) {
      list.push(t('risk.transferPauseAble'))
    }
    if (data?.warn?.isClosable) {
      list.push(t('risk.transferPauseAble'))
    }
    if (data?.warn?.tradingCooldown) {
      list.push(t('risk.trading_cooldown'))
    }
    if (data?.warn?.isBlacklisted) {
      list.push(t('risk.is_blacklisted'))
    }
    if (data?.warn?.freezable) {
      list.push(t('risk.is_blacklisted'))
    }
    if (data?.warn?.isWhitelisted) {
      list.push(t('risk.is_whitelisted'))
    }
    if (data?.warn?.transferHookUpgradable) {
      list.push(t('risk.is_whitelisted'))
    }
    return list
  }

  const getTokenRiskInfo = async (
    token:
      | AssetsToken
      | {
          chainId?: number
          address: string
          isNative?: boolean
        }
  ) => {
    const isSolanaOrEvm = isSolanaOrEvmChain(token.chainId)
    if (!isSolanaOrEvm) return
    if (token?.isNative) return

    const chainName = getChainNameById(token.chainId)!
    const data = await getTokenRiskFormatInfo({
      chain: chainName,
      address: token?.address || ''
    })
    return data
  }

  // 判断是否需要展示为 risk 代币
  // 貔貅币、买入税率>50%、卖出税率>50%，这三种类型只要有一个存在，就需要展示为风险代币
  // 其他风险类型满足 2 个，就需要展示风险提示
  const isShowRiskMark = (data: IRiskData, riskDescList: string[]) => {
    if (
      data?.risk?.isHoneypot ||
      data?.risk?.buyTax ||
      data?.risk?.sellTax ||
      data?.risk?.feeRate ||
      data?.risk?.nonTransferable
    ) {
      return true
    }
    return riskDescList.length >= 2
  }

  // 判断是否需要展示为 warn 代币
  // 买入税率>=10% && <50%、卖出税率>=10% && <50%、交易税率>=10% && <50%，无法全部卖出，交易税可改，
  // 满足一个就需要展示风险提示
  const isShowWarnMark = (data: IRiskData, warnDescList: string[]) => {
    if (
      warnTax(data.warn?.buyTax, t('risk.buyTax')) ||
      warnTax(data.warn?.sellTax, t('risk.sellTax')) ||
      data?.warn?.cannotSellAll ||
      data?.warn?.slippageModifiable ||
      data?.warn?.personalSlippageModifiable ||
      data?.warn?.transferFeeUpgradable
    ) {
      return true
    }
    return warnDescList.length >= 3
  }

  const warnTax = (value: any, nameStr: string) => {
    if (value === undefined) return null
    const tax = Number(value) || 0
    if (tax === 0) {
      if (nameStr == t('risk.buyTax')) {
        return t('risk.unKnowBuyTax')
      }
      if (nameStr === t('risk.sellTax')) {
        return t('risk.unKnowSellTax')
      }
      return `${t('risk.taxUnKnow')} ${nameStr}`
    }
    if (tax < 0.5 && tax >= 0.1) {
      return `${nameStr} >= 0.1 && < 0.5`
    }
    return null
  }

  const fireErr = (errMsg?: string, { showToast } = { showToast: true }) => {
    if (showToast) {
      TToast.error(errMsg || 'Request Error.', { autoClose: 2000 })
    }
    return null
  }

  return {
    getTokenRiskInfo
  }
}

export const capitalCase = (letter: string) => {
  return letter.slice(0, 1).toUpperCase() + letter.slice(1).toLocaleLowerCase()
}

export enum riskTypes {
  risk = 'risk',
  warn = 'warn',
  unlisted = 'unlisted'
}

export const ruleColors = {
  [riskTypes.risk]: '#EB4B6D',
  [riskTypes.warn]: '#FF9142',
  [riskTypes.unlisted]: '#C1C0D8'
}

export enum riskModalTypes {
  info = 'info',
  confirm = 'confirm'
}

export interface IRiskData {
  risk: {
    ownerChangeBalance?: boolean
    externalCall?: boolean
    hiddenOwner?: boolean
    isProxy?: boolean
    isOpenSource?: boolean
    balanceMutableAuthority?: boolean
    transferHook?: boolean
    maliciousAddress?: boolean
    isHoneypot?: boolean
    buyTax?: boolean
    sellTax?: boolean
    nonTransferable?: boolean
    feeRate?: boolean
  }
  warn: {
    buyTax?: number
    sellTax?: number
    cannotSellAll?: boolean
    slippageModifiable?: boolean
    personalSlippageModifiable?: boolean
    feeRate?: number
    transferFeeUpgradable?: boolean
    transferPausable?: boolean
    tradingCooldown?: boolean
    isBlacklisted?: boolean
    isWhitelisted?: boolean
    isClosable?: boolean
    freezable?: boolean
    transferHookUpgradable?: boolean
  }
}

export interface IRiskRes {
  markType: riskTypes | null
  rules: any[]
}

export default useRiskToken
