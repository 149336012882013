import { getOnrampCountryList, getOnRampQuote } from '@/api/ramp'
import {
  CurrencyItem,
  IQuoteParams,
  PriceRes,
  PriceResponse,
  QuoteRes,
  QuoteSide,
  SupportPayWay
} from '@/api/ramp/type'
import { LoadingIcon } from '@/components/Loading'
import Tip from '@/components/Tip'
import { TButton, TIcon, TNumberInput, Trans } from '@/components/tmd'
import toast from '@/components/tmd/toast/Toast'
import TokenImg from '@/pages/wallet/components/TokenImg'
import configChains, { onRampChain } from '@/proviers/web3Provider/chains'
import { Routers } from '@/router'
import useChainsStore from '@/stores/chainsStore/hooks/useChainsStore'
import useCommonStore from '@/stores/commonStore/hooks/useCommonStore'
import rampStore from '@/stores/rampStore'
import useRampStore from '@/stores/rampStore/hooks/useRampStore'
import useUserInfo from '@/stores/userStore/hooks/useUserStore'
import { classNames } from '@/utils'
import { debounce } from '@/utils/debounce'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Checkbox } from 'antd-mobile'
import BigNumber from 'bignumber.js'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCountryIcon } from '../utils'
import Countdown from './Countdown'
import Order from './Order'
import Power from './Power'
import useAlPolicy from './useAlPolicy'
import useAlService from './useAlService'

export const filterCurrencies = (list: CurrencyItem[]) => {
  const mergedArr = list.reduce((acc: CurrencyItem[], current) => {
    const existing = acc.find((item) => item.currency === current.currency)
    if (existing) {
      existing.payWay?.push(current as CurrencyItem)
    } else {
      acc.push({
        ...current,
        payWay: [current as CurrencyItem]
      })
    }
    return acc
  }, [])
  return mergedArr
}

const BuyView = observer(() => {
  const navigate = useNavigate()
  const { user } = useUserInfo()
  const { chains } = useChainsStore()
  const [buyQoute, setBuyQoute] = useState<QuoteRes>()
  const { selectedToken, buySelectedCurrency, buyAmount } = useRampStore()
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [amountError, setAmountError] = useState<string>('')
  const [inputAmount, setInputAmount] = useState<string>('')

  const setQuote = rampStore.setBuyQuote
  const { mayKeyboardOpen } = useCommonStore()
  const { buyQuote } = useRampStore()

  const network = useMemo(() => {
    const onrampChain = onRampChain[selectedToken.chainId]
    return onrampChain?.chain || ''
  }, [selectedToken, onRampChain])

  useEffect(() => {
    rampStore.setBuyAmount(inputAmount)
    if (!inputAmount) {
      setQuote([{} as PriceRes])
    }
  }, [inputAmount])

  useEffect(() => {
    return () => {
      setInputAmount('')
      setAmountError('')
    }
  }, [])

  const { data } = useQuery({
    queryKey: ['getOnrampCountryList', network, selectedToken.symbol],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!buySelectedCurrency,
    queryFn: async () => {
      return await getOnrampCountryList(1, network, selectedToken.symbol)
    }
  })

  useEffect(() => {
    if (!data) return
    //!warning, check pay way
    const arr = data.filter((item) => {
      const set = item.paymentFiats
        .map((i) => i.code)
        .reduce(
          (ret, key) => {
            ret[key] = true
            return ret
          },
          {} as Record<string, boolean>
        )
      return Object.values(SupportPayWay).some((code) => set[code])
    })
    rampStore.setBuyCurrencyList(arr)
    const cryptoDetail = rampStore.supportCryptoList.find(
      (item) =>
        item.symbol === selectedToken.symbol &&
        item.network === onRampChain[rampStore.selectedToken?.chainId].chain &&
        item.buy
    )
    let currency = buySelectedCurrency
    if (!buySelectedCurrency || !buySelectedCurrency.symbol) {
      currency = arr[0]
    }
    const card = currency.paymentFiats.find(
      (i) => i.code === SupportPayWay.CREDITCARD
    )
    const apple = currency.paymentFiats.find(
      (i) => i.code === SupportPayWay.APPLEPAY
    )

    rampStore.setBuySelectedCurrency({
      ...currency,
      cryptoDetail,
      minBuy: Number(card?.payMin || apple?.payMin || 0),
      maxBuy: Number(card?.payMax || apple?.payMax || 0)
    })
  }, [data, selectedToken.symbol])

  useEffect(() => {
    if (!selectedToken?.formatted) return
    if (!inputAmount) return
    const minBuy = Number(rampStore.buySelectedCurrency?.minBuy)
    const maxBuy = Number(rampStore.buySelectedCurrency?.maxBuy)
    const estAmount = Number(buyAmount)
    if (!buyAmount) {
      setAmountError('')
      return
    } else if (new BigNumber(estAmount).lt(new BigNumber(minBuy))) {
      setAmountError(t('tg_wallet_ramp.trade_error1', { minBuy: minBuy }))
    } else if (new BigNumber(estAmount).gt(new BigNumber(maxBuy))) {
      setAmountError(
        t('tg_wallet_ramp.trade_error2', {
          maxBuy: maxBuy
        })
      )
    } else {
      setAmountError('')
    }
  }, [inputAmount, buyAmount, selectedToken?.formatted, buySelectedCurrency])

  const getBuyQuote = useMutation<PriceResponse, unknown, IQuoteParams>({
    mutationFn: (params: IQuoteParams) =>
      getOnRampQuote(params).then((res) => res as PriceResponse),
    onSuccess: (data: PriceResponse) => {
      if (!data?.data && data?.msg) {
        toast.error(data?.msg)
        return
      }
      if (data?.data?.data) {
        const list = []
        const cardQuote = data?.data?.data?.find(
          (i) => i.code === SupportPayWay.CREDITCARD
        )
        if (cardQuote) {
          list.push(cardQuote)
        }
        const appleQuote = data?.data?.data?.find(
          (i) => i.code === SupportPayWay.APPLEPAY
        )
        if (appleQuote) {
          list.push(appleQuote)
        }
        setQuote(list)
        if (!list.length) {
          toast.error('No support pay way')
        }
      }
    }
  })
  const debounceFun = debounce((amount: string) => {
    const minBuy = Number(rampStore.buySelectedCurrency?.minBuy)
    const maxBuy = Number(rampStore.buySelectedCurrency?.maxBuy)
    if (Number(amount) >= minBuy && Number(amount) <= maxBuy) {
      loadQuote(rampStore.buyAmount)
    }
  }, 1000)

  const loadQuote = async (amount: string) => {
    getBuyQuote.mutate({
      code: 1,
      fiat: rampStore.buySelectedCurrency?.symbol ?? '',
      network: onRampChain[rampStore.selectedToken?.chainId].chain,
      payCode: '1',
      side: QuoteSide.BUY,
      size: amount,
      symbol: selectedToken?.symbol ?? ''
    })
  }

  useEffect(() => {
    buySelectedCurrency?.symbol && loadQuote(rampStore.buyAmount)
  }, [buySelectedCurrency?.symbol])

  const FlagIcon = getCountryIcon(buySelectedCurrency.country)

  const { setOpen: setAlOpen, component: alComponent } = useAlPolicy({
    onSkip: () => {
      setAlOpen(false)
    }
  })

  const { setOpen: setSerOpen, component: serComponent } = useAlService({
    onSkip: () => {
      setSerOpen(false)
    }
  })

  const evmETHFlag = useMemo(() => {
    return selectedToken?.symbol.includes('ETH') && !selectedToken?.address
  }, [selectedToken?.symbol, selectedToken?.address])

  const usdAmount = useMemo(() => {
    if (buyQuote && selectedToken) {
      return selectedToken.price * Number(buyQuote.size)
    }
    return 0
  }, [buyQuote, selectedToken])
  const [bottonShow, setBottomShow] = useState(true)
  useEffect(() => {
    setBottomShow(!mayKeyboardOpen)
  }, [mayKeyboardOpen])

  console.log(
    'disable flag',
    !rampStore.isAlchemySupported,
    !buyQuote?.size,
    isDisabled,
    !isChecked
  )
  return (
    <>
      {alComponent}
      {serComponent}
      {!buySelectedCurrency.symbol ? (
        <div className=" flex h-[300px] flex-col items-center justify-center">
          <LoadingIcon isLoading={true} />
        </div>
      ) : (
        <div className="flex h-full flex-col justify-between pt-2">
          <div className="no-scrollbar flex flex-1 flex-col overflow-y-auto pb-6">
            <div className="flex flex-col">
              <div className="mb-3 text-base font-medium text-t1">
                {t('tg_wallet_ramp.trade_label1')}
              </div>
              <div
                className={classNames(
                  'flex cursor-pointer justify-between rounded-xl px-4 py-[15px] bg-bg3 text-t1'
                )}
              >
                <TNumberInput
                  fontSize={16}
                  value={inputAmount}
                  onChange={(e) => {
                    setInputAmount(e)
                    debounceFun(e)
                  }}
                  placeholder={t('tg_wallet_ramp.trade_label1_placeholder')}
                  style={{
                    '--placeholder-color': 'var(--text-t4)',
                    color: 'var(--text-t1) !important',
                    '--text-align': 'left'
                  }}
                  className={`  text-base font-medium text-t1`}
                  onFocus={() => {
                    if (
                      window.Telegram.WebApp.platform === 'ios' ||
                      window.Telegram.WebApp.platform === 'android'
                    )
                      setBottomShow(false)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setBottomShow(true)
                    }, 100)
                  }}
                />
                <div
                  onClick={() => {
                    navigate(Routers.ramp.currency())
                  }}
                  className="flex items-center gap-2"
                >
                  <div className="flag-icon size-[22px]">
                    <FlagIcon />
                  </div>
                  <span className="text-base font-medium text-t1">
                    {buySelectedCurrency?.symbol}
                  </span>
                  <TIcon name="tg_wallet_pull-down1" className="size-4" />
                </div>
              </div>
              {amountError && (
                <div className="mt-2 text-xs  text-red">{amountError}</div>
              )}
            </div>
            <div className="mt-6 flex flex-col">
              <div className="mb-3 text-base font-medium text-t1">
                {t('tg_wallet_ramp.trade_label2')}
              </div>
              <div className="flex items-center justify-between gap-7 rounded-[12px] bg-bg3 px-4 py-[15px]">
                <div
                  className={classNames(
                    'w-[70%] text-base font-medium text-t1',
                    (!buyQuote?.size || !inputAmount) && 'text-t4'
                  )}
                >
                  {inputAmount && buyQuote?.size ? buyQuote?.size : '0.00'}
                </div>
                <div className="flex items-center gap-2">
                  <TokenImg
                    symbol={selectedToken?.symbol ?? ''}
                    // image={selectedToken?.image}
                    image={
                      evmETHFlag
                        ? configChains.ethereum.icon
                        : selectedToken?.image
                    }
                    chainId={selectedToken?.chainId ?? -1}
                    isNative={selectedToken?.isNative}
                    symbolSize={22}
                    chainSize={12}
                  />
                  <div className="text-base font-medium text-t1">
                    {selectedToken?.symbol}
                  </div>
                </div>
              </div>
            </div>
            {inputAmount && buyQuote?.size && (
              <Countdown
                start={!!buyQuote}
                reloadFun={loadQuote}
                amount={buyAmount}
              />
            )}
            {inputAmount && buyQuote?.size && (
              <Order type={QuoteSide.BUY} data={buyQuote} amount={buyAmount} />
            )}
          </div>
          {bottonShow ? (
            <div className="flex flex-col">
              {!!inputAmount && usdAmount > 300 && (
                <Tip content={t('tg_wallet_ramp.trade_attention_tips')} />
              )}
              <Checkbox
                className="felx h-[28px] items-center text-t1"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                icon={(checked) =>
                  checked ? (
                    <TIcon
                      name="tg_wallet_finalize-facetiousness"
                      className="text-t1"
                      fontSize="16"
                    />
                  ) : (
                    <div className="mt-[2px] flex size-4 items-center justify-center">
                      <div className="size-[12px] rounded-full border border-t1" />
                    </div>
                  )
                }
              >
                <div className=" text-[11px] font-medium leading-[14px] text-t3">
                  <Trans
                    i18nKey={'tg_wallet_ramp.trade_privacy'}
                    components={{
                      highlight: (
                        <span
                          className="text-blue"
                          onClick={() => setSerOpen(true)}
                        ></span>
                      ),
                      highlight1: (
                        <span
                          className="text-blue"
                          onClick={() => setAlOpen(true)}
                        ></span>
                      )
                    }}
                  ></Trans>
                </div>
              </Checkbox>
              <TButton
                size="large"
                disabled={
                  !rampStore.isAlchemySupported ||
                  !buyQuote?.size ||
                  isDisabled ||
                  !isChecked
                }
                onClick={() => {
                  navigate(Routers.ramp.choosePaymentMethod)
                  // setIsDisabled(true)
                  // getAlchemyToken.mutate(user?.email ?? '')
                  // setShowCheckout(true)
                }}
                color="primary"
                className="mt-4 text-base font-medium"
              >
                Proceed · Buy {selectedToken?.symbol || 'ETH'}
              </TButton>
              <Power />
            </div>
          ) : null}
        </div>
      )}
    </>
  )
})

export default BuyView
