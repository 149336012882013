import { TomoChain } from '@/api/type'
import chains from '@/proviers/web3Provider/chains'
import { configChainsInfoAtom } from '@/state'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { getConfigChainsAll } from 'api'
import { useAtom } from 'jotai'

export const convertChainsArrayToObject = (
  chains: TomoChain[]
): Record<number, string> => {
  return chains.reduce(
    (acc, chain) => {
      acc[chain.chainId] = chain.name
      return acc
    },
    {} as Record<number, string>
  )
}

const useChainConfig = () => {
  return useQuery({
    queryKey: ['getConfigChainsAll'],
    placeholderData: keepPreviousData,
    queryFn: getConfigChainsAll
  })
}

export default useChainConfig
