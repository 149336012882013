import React from 'react'
import { useAtom } from 'jotai'
import { posterDataAtom } from '@/state/portal'

const usePosterPortal = () => {
  const [posterData, setPosterData] = useAtom(posterDataAtom)
  
  const createPoster = (data: any) => {
    /** local test */
    // if (posterData) {
    //   setPosterData(null)
    // } else {
    //   return new Promise((resolve) => {
    //     setPosterData({ ...data, resolve })
    //   })
    // }

    return new Promise((resolve) => {
      setPosterData({ ...data, resolve })
    })
  }

  const destroyPoster = () => {
    setPosterData(null)
  }

  return { createPoster, destroyPoster }
}

export default usePosterPortal
