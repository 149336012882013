import { getAppShareMessageId } from '@/api'
import { TToast } from '@/components/tmd'
import { useWebApp } from '@vkruglikov/react-telegram-web-app'
import { useCallback } from 'react'
import useLoginInfo from './useLoginInfo'

const useShareUrl = () => {
  const WebApp = useWebApp()
  const { user } = useLoginInfo()

  const shareURL = useCallback(
    (url: string, text?: string) => {
      WebApp?.openTelegramLink(
        `https://t.me/share/url?` +
          new URLSearchParams({ url, text: text || '' })
            .toString()
            // By default, URL search params encode spaces with "+".
            // We are replacing them with "%20", because plus symbols are working incorrectly
            // in Telegram.
            .replace(/\+/g, '%20')
      )
    },
    [WebApp]
  )

  const shareMessage = async (inviteCode: string) => {
    const messageId = await getAppShareMessageId({
      sender_id: +(user?.tgId || 0),
      share_code: inviteCode + '',
    })
    if (messageId) {
      WebApp?.shareMessage?.(messageId)
    }
  }

  return { shareURL, shareMessage }
}

export default useShareUrl
