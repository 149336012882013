import { StargateClient } from "@cosmjs/stargate";
import { coins } from "@cosmjs/amino";
import { MsgSend } from "cosmjs-types/cosmos/bank/v1beta1/tx";
import { SignMode } from "cosmjs-types/cosmos/tx/signing/v1beta1/signing";
import { AuthInfo, Fee, TxBody } from "cosmjs-types/cosmos/tx/v1beta1/tx";
import { ApiParams, eCosmosChainId } from "@/api/type";
import { sendRawTransactionByCenterApi } from "@/hooks/api/chain";

export interface ItmeType {
  amount: string;
  denom: string;
}
export interface IGetGasFee {
  from: string;
  to: string;
  amount: string;
  denom: string;
}
export interface ITxData {
  fromAddress: string;
  toAddress: string;
  amount: string;
  token: string;
  memo?: string;
}

export const cosmosScanUrl = "https://www.mintscan.io/cosmos/tx/";
export const mockCosmosChainId = 4;
export const gasPrice = 0.005; // 单位：uatom
export const gasLimit = 222000;

// const rpcUrl = 'https://localhost:5173/cosmosRpc'
// const rpcUrl = 'https://cosmos-rpc.quickapi.com:443'
const rpcUrl =
  "https://twilight-orbital-lake.cosmos-mainnet.quiknode.pro/b1b4550971c7a423699ba4b1e4538436d981478a";

export const getCosmosClient = (() => {
  let client: StargateClient | null = null;
  return async () => {
    if (!client) {
      client = await (await import("@cosmjs/stargate")).StargateClient.connect(rpcUrl);
    }
    return client;
  };
})();

export const getCosmosBalance = async (params: { address: string }) => {
  const client = await getCosmosClient();
  const balances = await client.getAllBalances(params.address);
  const res = { balance: "0", formatted: "0" };

  if (!balances?.length) return res;

  const uatom = balances.find((item: ItmeType) => item.denom === "uatom");
  if (!uatom) return res;

  console.log("cosmos.getCosmosBalance", balances);

  return {
    balance: uatom.amount + "",
    formatted: +uatom.amount / 1e6 + "",
  };
};

export const getCosmosEstimateGasFee = async () => {
  return (gasPrice * gasLimit) / 1e6;
};

export const createCosmosSignDoc = async (txData: ITxData) => {
  const { fromAddress, toAddress, amount, token, memo } = txData;
  const client = await getCosmosClient();
  const account = await client.getAccount(fromAddress);

  if (!account) {
    throw new Error("Cosmos account does not exist");
  }

  const accountNumber = account.accountNumber;
  const sequence = account.sequence;

  const proto = [
    {
      typeUrl: "/cosmos.bank.v1beta1.MsgSend",
      value: MsgSend.encode({
        fromAddress,
        toAddress,
        amount: [
          {
            denom: `u${token}`,
            amount,
          },
        ],
      }).finish(),
    },
  ];

  const tx = TxBody.encode(
    TxBody.fromPartial({
      messages: proto,
      memo: memo || "Sent",
    }),
  ).finish();

  const signDoc = {
    bodyBytes: tx,
    authInfoBytes: AuthInfo.encode({
      signerInfos: [
        {
          modeInfo: {
            single: {
              mode: SignMode.SIGN_MODE_DIRECT,
            },
            multi: undefined,
          },
          sequence: BigInt(sequence),
        },
      ],
      fee: Fee.fromPartial({
        amount: coins(Number(gasPrice * gasLimit), "uatom"),
        gasLimit: BigInt(gasLimit),
      }),
    }).finish(),
    chainId: eCosmosChainId.cosmoshub,
    accountNumber: Number(BigInt(accountNumber)),
  };

  return signDoc;
};

export const pushCosmosTx = async ({
  txStr,
  apiParams,
}: {
  txStr: string;
  apiParams?: ApiParams;
}) => {
  if (apiParams) {
    const hash = await sendRawTransactionByCenterApi({
      apiParams,
      callData: txStr,
      tx: "",
    });
    if (hash) return hash;
  }

  const res = await sendCosmosTransaction(txStr);

  return res.transactionHash;
};

export const sendCosmosTransaction = async (txStr: string) => {
  const client = await getCosmosClient();
  return await client.broadcastTx(Buffer.from(txStr, "base64"));
};

export const getCosmosTransaction = async (hash: string) => {
  const client = await getCosmosClient();
  const txStatus = await client.getTx(hash);
  console.log("cosmos.txStatus", txStatus);
  return txStatus;
};
