import SkeletonAIFeed from "./Skeleton/AIFeed";
import SkeletonMarket from "./Skeleton/Market";
import SkeletonNormal from "./Skeleton/Normal";
import SkeletonSwap from "./Skeleton/Swap";
import SkeletonTask from "./Skeleton/Task";
import SkeletonWallet from "./Skeleton/Wallet";

export const PageLoading = () => {
  const { pathname } = window.location;

  if (pathname.startsWith("/market/token")) {
    return null;
  }
  if (pathname.startsWith("/recovery")) {
    return null;
  }

  switch (pathname) {
    case "/market":
      return <SkeletonMarket favorite />;
    case "/task":
      return <SkeletonTask />;
    case "/swap":
      return <SkeletonSwap />;
    case "/aiFeed":
    case "/aiFeed/home/favorite":
    case "/aiFeed/home/radar":
      return <SkeletonAIFeed />;
    case "/wallet":
    case "/":
      return <SkeletonWallet />;
    default:
      return <SkeletonNormal />;
  }
};
