import { errorContents } from "@/config/const";
import { getPassKey, sleep } from "@/utils";
import { getSdkHash } from "@/utils/sdk";
import { sentryInterceptors } from "@/utils/sentry/axiosInterceptors";
import axios from "axios";
import {
  BalanceResult,
  CenterSubmitParams,
  CenterSubmitResult,
  ChainGasChainInfo,
  ChainGasParams,
  ChainGasResult,
  TomoTxStatus,
} from "./type";

// VITE_CHAIN_API
const timeout = 20000;

const chainApi = axios.create({
  baseURL: import.meta.env.VITE_CHAIN_API + "/wallet",
  // baseURL: import.meta.env.VITE_CHAIN_API,
  timeout,
});
const chainTestApi = axios.create({
  baseURL: import.meta.env.VITE_CHAIN_TEST_API + "/wallet",
  // baseURL: import.meta.env.VITE_CHAIN_API,
  timeout,
});

[chainApi, chainTestApi].forEach((apiItem) => {
  apiItem.interceptors.request.use(
    (config) => {
      const passKey = getPassKey();
      if (
        passKey &&
        (config.baseURL === import.meta.env.VITE_TOMO_API || config.baseURL === import.meta.env.VITE_TOMO_API_SDK)
      ) {
        config.headers.mfa = passKey;
      }
      let token;
      const userState = window.localStorage.getItem("userState");
      if (userState) {
        token = JSON.parse(userState).token;
      }
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (config.baseURL === import.meta.env.VITE_TOMO_API_SDK) {
        config.headers["tomo-session-id"] = getSdkHash();
      }
      return config;
    },
    (error) => {
      if (error?.response?.status === 401) {
        return Promise.reject(error);
      }
    },
  );

  sentryInterceptors(apiItem);
});

export const getChainGasInfo = async ({
  chainId,
  callData,
  params,
  addressList,
}: ChainGasParams): Promise<ChainGasResult | null> => {
  try {
    const res = await chainApi.post("/v1/queryGasInfo", {
      chainIndex: chainId,
      callData,
      gasLimitParam: {
        ...params,
        value: "0x" + BigInt(params.value).toString(16),
      },
      addressList,
    });

    return res.data.data;
  } catch (error) {
    console.warn("v1/wallet/queryGasInfo", error);
    return null;
  }
};

export const getChainInfos = async (): Promise<ChainGasChainInfo[]> => {
  const res = await chainApi.post("/v1/common/queryChainInfo", {});
  return res.data.data;
};

// v1/wallet/order/submit
export const sendTransaction = async (params: Partial<CenterSubmitParams>): Promise<string> => {
  const res = await chainApi.post("/v1/order/submit", {
    ...params,
  });

  return res.data.data;
};

export const getTransactionHash = async (params: { orderId: string }): Promise<CenterSubmitResult> => {
  const res = await chainApi.post(`/v1/order/${params.orderId}`);
  return res.data.data;
};

//

export const getBalances = async (params: {
  chainIndex: string;
  walletAddress: string;
  tokenAddressList: string[];
}): Promise<BalanceResult> => {
  const res = await chainApi.post(`v1/batchQueryTokenBalance`, {
    ...params,
  });
  return res.data.data;
};

export const getMevDetail = async (params: { chainIndex: string[] }): Promise<ChainGasChainInfo[]> => {
  const res = await chainApi.post(`v1/common/queryChainMev`, params.chainIndex);
  return res.data.data;
};

// 轮询接口直到有数据返回
export const pollForMethods = async <T, R>({
  params,
  callback,
  check,
  failCheck,
  supportCheck,
  interval = 1000,
  maxAttempts = 60 * 2,
  toastBack,
  transactionEnd,
  init,
}: {
  params: T;
  callback: (params: T) => Promise<R>;
  check: keyof R;
  failCheck: keyof R;
  supportCheck: keyof R;
  interval?: number; // 轮询间隔时间，默认1秒
  // maxAttempts: number = 180 // 最大轮询次数，默认180次 三分钟超时,
  maxAttempts?: number; // 最大轮询次数，默认30次 30秒超时,
  toastBack?: (res: R) => void;
  init?: (res?: R) => void;
  transactionEnd?: (res: R) => void;
}): Promise<R> => {
  let attempt = 0;

  const getStatusResult = async () => {
    let statusAttempt = 0;

    while (statusAttempt < maxAttempts) {
      const res = await callback(params);
      if (res) {
        const statusToastArr = [TomoTxStatus.TX_SUCCESS, TomoTxStatus.TX_FAILED];
        if (statusToastArr.includes(res[supportCheck])) {
          toastBack && toastBack(res);
          transactionEnd && transactionEnd(res);
          return res;
        }
      }
      statusAttempt++;
      await sleep(interval);
    }
  };

  while (attempt < maxAttempts) {
    // try {
    const res = await callback(params);
    if (res) {
      if (res[failCheck]) {
        throw new Error(res[failCheck] ? String(res[failCheck]) : errorContents.transactionError);
      }
      if (res[check]) {
        init && init(res);
        toastBack && getStatusResult();
        return res;
      }
    }
    attempt++;
    await sleep(interval);
    // } catch (error) {
    //   console.warn(
    //     `Attempt ${attempt + 1} failed. Retrying in ${interval} ms...`,
    //     error
    //   )
    // }
  }

  throw new Error("Max attempts reached. No transaction hash received.");
};
