import axios from 'axios'

import { ErrorLevel } from '../const'
import { format } from '../sentryCaptureException'
import isError from './isError'
import { CaptureContext } from './type'

interface ErrorHandlerParams {
  level: ErrorLevel
  reason: string
  error: any
  context: CaptureContext
}

export const getRequestErrorInfo = (
  error: any
): { url: any; method: any; status: any } | null => {
  const { request, config, response = {} } = error || {}
  if (!request && !config) {
    return null
  }

  const { status, headers } = response

  return {
    url: config.url,
    method: config.method,
    status
  }
}

/**
 * 对错误消息进行处理、聚合
 * @param level
 * @param label
 * @param describe
 * @param error
 * @param tags
 */
export const errorHandler = (
  defaultLevel: ErrorLevel,
  label: string,
  describe: string,
  err: Error = new Error(''),
  context: CaptureContext = {}
): ErrorHandlerParams => {
  let error: any = err
  const messageFromError = error.message as string
  const defaultTags = context.tags || {}
  const originExtra = context.extra || {}

  let level: ErrorLevel = defaultLevel // 错误等级
  let reason = messageFromError // 错误详情，括号内内容
  const extra = {
    _inner: {
      level,
      label,
      describe,
      messageFromError,
      stack: ''
    },
    ...format(originExtra)
  } // 错误描述 key-value 对象

  const requestErrorInfo = getRequestErrorInfo(err)

  if (!isError(err)) {
    error = new Error(JSON.stringify(err))
  }

  if (axios.isCancel(error)) {
    reason = 'Request Cancel'
    level = ErrorLevel.info
  }
  // // 进入页面后，很多接口都可能触发4xx错误，他们都是未登录这一种情况导致，属于正常现象，降级 info
  // else if (
  //   error.response?.status === 401 ||
  //   /pre fetch status is 401/.test(messageFromError)
  // ) {
  //   reason = '401 Not Login'
  //   level = ErrorLevel.info
  // }
  // 网络请求错误
  else if (requestErrorInfo) {
    error.response = null
    const { status, url, method } = requestErrorInfo
    // 外面是啥level就是啥level，不做调整
    // level = /^4\d\d$/.test(`${status}`) ? 'error' : 'warn';
    reason = `network err ${method} ${status}`
    ;(extra._inner as Record<string, unknown>).url = url
    const _tempE = new Error('Network Error')
    extra._inner.stack = _tempE.stack || ''
  }

  const tags = {
    business: 'Unknown',
    isCustomReported: true, // 自定义上报
    ...format(defaultTags)
  }

  return {
    level,
    reason,
    error,
    context: {
      extra,
      tags
    }
  }
}
