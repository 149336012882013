import { moonpayApi } from "..";
import { IMoonPayLimitRes, IMoonPayBuyQuoteRes } from "./type";
import { MoonPayPaymentMethods } from "@/pages/ramp/MoonPay/constans";
import { supportMoonPayAssets } from "@/constants/moonpay";
import axios from "axios";
import { sentryInterceptors } from "@/utils/sentry/axiosInterceptors";
import { getSdkHash } from "@/utils/sdk";
import { getPassKey } from "@/utils";

export const apiKey = import.meta.env.VITE_MOONPAY_API_KEY;

export async function getCurrencyCodeLimit(currencyCode: string, paymentMethod: MoonPayPaymentMethods) {
  const res = await moonpayApi.get(`/v3/currencies/${currencyCode}/limits`, {
    params: {
      apiKey: apiKey,
      baseCurrencyCode: "usd",
      paymentMethod,
    },
  });
  const data = res.data as IMoonPayLimitRes;
  return data;
}

export async function getCurrencyBuyQuote(
  currencyCode: string,
  baseCurrencyAmount: number,
  paymentMethod: MoonPayPaymentMethods,
  walletAddress: string,
) {
  const res = await moonpayApi.get(`/v3/currencies/${currencyCode}/buy_quote`, {
    params: {
      apiKey: apiKey,
      baseCurrencyCode: "usd",
      baseCurrencyAmount,
      paymentMethod,
      walletAddress,
      areFeesIncluded: true,
      fixed: true,
      quoteType: "principal",
    },
  });
  const data = res.data as IMoonPayBuyQuoteRes;
  return data;
}

export const apiInstance = axios.create({
  baseURL: import.meta.env.VITE_CHAIN_API + "/onramp",
  timeout: 30000,
});
[apiInstance].forEach((apiItem) => {
  apiItem.interceptors.request.use(
    (config) => {
      const passKey = getPassKey();
      if (
        passKey &&
        (config.baseURL === import.meta.env.VITE_TOMO_API || config.baseURL === import.meta.env.VITE_TOMO_API_SDK)
      ) {
        config.headers.mfa = passKey;
      }
      let token;
      const userState = window.localStorage.getItem("userState");
      if (userState) {
        token = JSON.parse(userState).token;
      }
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (config.baseURL === import.meta.env.VITE_TOMO_API_SDK) {
        config.headers["tomo-session-id"] = getSdkHash();
      }
      return config;
    },
    (error) => {
      if (error?.response?.status === 401) {
        return Promise.reject(error);
      }
    },
  );

  sentryInterceptors(apiItem);
});

apiInstance.interceptors.request.use((request) => {
  return request;
});

export async function getMoonpaySupportCodes() {
  try {
    const res = await apiInstance.get("/onramp/currencies/moonpay/supported/list");
    return res?.data?.data || [];
  } catch (error) {
    return supportMoonPayAssets;
  }
}

export async function getMoonpayUrlSignature(url: string) {
  try {
    const res = await apiInstance.get("/onramp/signed/url/directly/moonpay", {
      params: {
        encodedUrl: url,
      },
    });
    return res?.data?.data || [];
  } catch (error) {
    return "";
  }
}
